<template>
  <div
    :id="`m_module_${id}`"
    data-tour="tour_report_m_module"
    :class="editor ? 'm-report--editor' : 'm-report--preview'"
    @click="select"
  >
    <div v-if="editor" class="m-report__actions">
      <m-icon
        id="m_report_edit"
        :tooltip="t('general.buttons.edit')"
        icon="edit"
        variant="secondary"
        size="small"
        hover="weight"
        @click="select"
      />
      <m-icon
        id="m_report_remove"
        :tooltip="t('general.buttons.remove')"
        icon="delete"
        variant="secondary"
        size="small"
        hover="weight"
        @click="remove"
      />
    </div>
    <template v-if="clusters.length > 0">
      <div class="m-report__heading">
        <h2 class="type--small">
          {{ view?.name }}
        </h2>
        <h6 class="type--small">
          <template v-if="computedDateRestriction.isRelative">
            {{ t(`general.time.${computedDateRestriction.timeFrame}`) }}
          </template>
          <template v-else>
            {{ computedDateRestriction.start.toLocaleString() }} -
            {{ computedDateRestriction.end.toLocaleString() }}
          </template>
        </h6>
      </div>
      <div class="m-report__cluster m-articles__wrapper">
        <div
          v-for="(cluster, idx) in clusters"
          :key="idx"
          class="m-article m-article--details"
        >
          <div class="mb-2 m-article__container">
            <div class="m-article__wrapper">
              <!-- :class="getImage(cluster) ? 'm-cluster--image' : 'm-cluster--noimage'" -->
              <div class="m-article__image">
                <m-image :src="getImage(cluster)" :alt="getImageAlt(cluster)" />
              </div>
              <div class="m-article__content">
                <div class="m-article__heading">
                  <h4 class="m-article__title">
                    {{ cluster?.cluster?.title }}
                  </h4>
                  <div class="m-article__bottom">
                    <h6 class="type--small">
                      {{
                        t("views.reports.modules.cluster_details", {
                          articles: cluster?.result?.numDocuments,
                          languages: getLanguageList(cluster),
                        })
                      }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-cluster__content">
              <div
                v-if="cluster?.summary?.highlights?.length"
                class="m-cluster__summary"
              >
                <m-icon
                  id="m_filter_ai_indo"
                  icon="info"
                  size="xsmall"
                  :tooltip="summaryDisclaimer"
                />
                <p
                  v-for="(entry, idx) in cluster?.summary?.highlights"
                  :key="idx"
                  class="type--small mb-2"
                >
                  {{ entry.highlight }}
                </p>
              </div>
              <div v-else class="mt-3 m-cluster__summary">
                <div v-for="i in 3" :key="i" class="mb-2">
                  <m-type-skeleton type="p" width="100%" size="small" />
                  <m-type-skeleton type="p" width="100%" size="small" />
                  <m-type-skeleton type="p" width="66%" size="small" />
                </div>
              </div>
              <m-button
                v-if="!editor"
                id="m_module_cluster"
                :label="t('views.reports.modules.cluster_viewMore')"
                type="text"
                variant="primary"
                size="small"
                class="mt-2"
                @click="gotoClusterPage(cluster.cluster.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else-if="!isLoading">
      {{ t("views.reports.modules.cluster_noData") }}
    </div>
    <m-loading v-if="isLoading" size="small" overlay blured />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useApi } from "@api/api";
import useTranslator from "@hooks/useTranslator";
import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";
import MButton from "@components/MButton.vue";
import MLoading from "@components/MLoading.vue";
import MTypeSkeleton from "@components/skeletons/MTypeSkeleton.vue";

import { isEqual } from "lodash-es";
import { useReportsStore } from "@root/store/modules/reports";
import { useViewsStore } from "@root/store/modules/views";
import { useFiltersStore } from "@root/store/modules/filters";

const props = defineProps({
  id: { type: [String, Number] },
  previewViewId: String,
  settings: {
    type: Object,
  },
});

const emit = defineEmits([
  "select-element",
  "remove-element",
  "update-module-data",
]);

const { t } = useI18n();
const { api } = useApi();
const reportsStore = useReportsStore();
const filtersStore = useFiltersStore();
const viewsStore = useViewsStore();
const route = useRoute();
const router = useRouter();
const { localizeLanguageCode } = useTranslator();

const editor = computed(() => route.name.toLowerCase().includes("builder"));

const viewId = computed(
  () => props.settings?.customSettings?.viewId ?? props.previewViewId
);
const view = computed(() => {
  return viewsStore?.getViewById(viewId.value);
});

/** @type {import("vue").ComputedRef<import("@root/types.api.local").MonitioAPI.DateRestriction>} */
const computedDateRestriction = computed(
  () =>
    props.settings.customSettings?.dateRestriction ??
    filtersStore.dateRestriction
);

const isLoading = ref(true);
const clusterData = ref([]); //Populated in getTopClusters() -> getClusterData()
/** Use this computed variable to pre process the clusters as needed for the UI */
const clusters = computed(() => {
  // send emit event to update module data on the outside
  emit("update-module-data", clusterData.value);
  // sometimes clusters come empty
  return clusterData.value.filter((f) => f.result.documents.length != 0);
});

const requestingSummary = ref(false);
const getClusterSummary = async (articles, translateTo = "en") => {
  requestingSummary.value = true;
  const { data } = await api.search.summarizeDocuments(translateTo, articles);

  if (!data) return [];

  /** @type {import("@root/types.api.local").MonitioAPI.SummaryHighlightDTO[]} */
  const highlights = data.highlights;

  if (!highlights) return { highlights: [] };
  const originalHighlights = [];
  const translatedHighlights = [];

  highlights.forEach((highlight) => {
    const translatedResult = {
      highlight: highlight.translated,
      language: translateTo,
    };
    const originaldResult = {
      highlight: highlight.original,
      language: highlight.originalLanguage,
    };

    originalHighlights.push(originaldResult);
    if (translatedResult.highlight) translatedHighlights.push(translatedResult);
  });

  requestingSummary.value = false;

  return {
    highlights:
      translatedHighlights.length > 0
        ? translatedHighlights
        : originalHighlights,
  };
};

const summaryDisclaimer = computed(() => {
  return { content: t("disclaimer.ai_summary_cluster"), position: "right" };
});

const getClusters = async (id, dr) => {
  isLoading.value = true;
  const response = await api.search.topClusters(
    id,
    dr,
    filtersStore.aggregator,
    null,
    props.settings.numberOfClusters
  ); //start by seding view id to the backend today
  if (response.status != 200) return;
  if (!response.data.clusters) return;

  if (response.data.clusters.length > 0) {
    getClusterData(response.data.clusters, id, dr);
  } else {
    clusterData.value = [];
    isLoading.value = false;
  }
};

const getClusterData = (clusters, viewId, dr) => {
  if (clusters?.length > 0) {
    const requests = [];
    /** Setup the api Calls for each cluster here in this loop */
    for (let i = 0; i < props.settings?.numberOfClusters; i++) {
      const cluster = clusters[i];
      const apiCall = api.search.cluster(
        viewId,
        cluster?.id,
        dr,
        filtersStore.aggregator,
        filtersStore.sortBy,
        0,
        props.settings?.numberOfNewsPerCluster - 1
      );

      requests.push(apiCall);
    }

    Promise.all(requests).then(async (data) => {
      // Filter out the unecessary cluster information that comes from the backend
      const info = data.map((x) => x.data);
      clusterData.value = info;
      isLoading.value = false;
      clusterData.value.forEach(async (fe) => {
        if (fe) fe.summary = await getClusterSummary(fe.result.documents);
      });
    });
  }
};

const getImage = (cluster) => {
  return cluster?.cluster?.image_url;
};

const getImageAlt = (cluster) => {
  return cluster?.cluster?.title;
};

const getLanguageList = (cluster) => {
  return cluster.properties
    ?.filter((x) => x.type == "sourceLanguage")
    ?.map((x) => localizeLanguageCode(x.key))
    ?.join(", ");
};

const select = () => {
  emit("select-element", props.id);
};

const remove = () => {
  emit("remove-element", props.id);
};

onMounted(() => {
  getClusters(viewId.value, computedDateRestriction.value);
});

watch(
  () => [props.settings, computedDateRestriction.value, viewId.value],
  ([newSettings, newDR, newViewId], [oldSettings, oldDR, oldViewId]) => {
    if (
      isEqual(newDR, oldDR) &&
      oldViewId == newViewId &&
      newSettings.numberOfClusters == oldSettings.numberOfClusters &&
      newSettings.numberOfNewsPerCluster ==
        oldSettings.numberOfNewsPerCluster &&
      isEqual(
        newSettings.customSettings ?? {},
        oldSettings.customSettings ?? {}
      )
    )
      return;
    getClusters(newViewId, newDR);
  }
);

const gotoClusterPage = (clusterId) => {
  router.push({
    name: "headlines-cluster",
    params: {
      viewId: viewId.value,
      clusterId: clusterId,
    },
    meta: { from: "report" },
    query: route.query.q ? { q: route.query.q } : {},
  });
};
</script>

<style scoped lang="scss">
.m-report {
  &__heading {
    margin-bottom: $spacing-2;
    align-self: flex-start;
    text-align: left;
  }

  &--editor {
    .m-article:hover {
      padding: $spacing-3 !important;
      cursor: default;

      * {
        cursor: default;
      }

      .m-article__container {
        padding: unset !important;
        background-color: unset !important;
        box-shadow: unset !important;
      }
    }
  }

  &__cluster {
    width: 100%;

    .m-cluster {
      &__summary {
        width: 100%;
        @include flex(flex-start, flex-start, column);

        div.mb-2 {
          width: 100%;
        }

        .m-icon {
          align-self: flex-end;
        }
      }

      &__content {
        @include flex(flex-start, flex-start, column);

        .m-button {
          align-self: center;
        }
      }
    }
  }
}
</style>
