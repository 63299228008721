<template>
  <div
    data-test="m_modal_automate_report"
    class="m-modal__body m-automate-report"
  >
    <m-selector
      id="m_automate_report_frequency_type"
      :outsideLabel="t('modals.automateReport.frequency')"
      :selected="frequencyType"
      :options="frequencyTypeOpts"
      :placeholder="t('modals.automateReport.frequency_placeholder')"
      class="mb-3"
      @update:selected="changeFrequencyType"
    />
    <div
      v-if="frequencyType"
      class="m-automate-report__frequency mb-7"
      :class="`m-automate-report__frequency--${frequencyType}`"
    >
      <ul class="m-frequency__list">
        <li
          v-for="(day, i) in frequencyOpts"
          :key="i"
          :id="`m_automate_report_frequency_${i}`"
          :data-tooltip-content="day.label"
          data-tooltip-position="dynamic"
          class="m-list__day m-clickable"
          :class="{
            'm-list__day--selected': frequency.includes(day.value),
          }"
          @click="changeFrequency(day)"
        >
          <span class="h5">
            {{ frequencyType == "weekly" ? day.label.slice(0, 3) : day.label }}
          </span>
        </li>
      </ul>
    </div>
    <m-input
      id="m_automate_report_time"
      v-model="time"
      :label="t('modals.automateReport.time')"
      type="time"
      class="mb-7"
    />
    <div class="m-automate-report__dateRestriction mb-7">
      <m-selector
        id="automate_report__date"
        :outsideLabel="t('modals.automateReport.dateRestriction')"
        type="date"
        :dateSpecs="dateSpecs"
        :selected="filtersStore.dateRestriction"
        @update:selected="
          (v) => filtersStore.updateDateRestriction(v, route, router)
        "
      />
    </div>
    <div class="m-automate-report__recipients mb-7">
      <label class="mb-1">
        {{ t("modals.automateReport.recipients_add") }}
      </label>
      <m-search
        id="m_automate_report_recipients"
        v-model:query="query"
        :options="availableRecipientsList"
        :placeholder="t('modals.automateReport.recipients_placeholder')"
        :validation="validation"
        freeSearch
        floating
        type="autocomplete"
        @select="addRecipient"
      />
    </div>
    <div>
      <div class="m-automate-report__heading">
        <label class="mb-1">
          {{ t("modals.automateReport.recipients") }}
        </label>
        <m-button
          v-if="recipientsList.length"
          id="clear_all"
          :label="t('modals.automateReport.recipients_clear')"
          type="text"
          variant="secondary"
          size="xsmall"
          @click="clearAll"
        />
      </div>
      <div
        class="m-automate-report__list"
        :class="{ 'm-automate-report__list--center': !recipientsList.length }"
      >
        <h6 v-if="!recipientsList.length" class="type--small type--empty">
          {{ t("modals.automateReport.recipients_empty") }}
        </h6>
        <div
          v-for="(recipient, i) in recipientsList"
          :key="i"
          class="m-list__element h5 type--small"
          :class="{
            'm-clickable': recipient.subscriptionActive,
            'm-clickable--disabled': !recipient.subscriptionActive,
          }"
          @mouseenter="hover = recipient.id"
          @mouseleave="hover = undefined"
        >
          <m-user-badge
            v-if="recipient.type == 'user'"
            :user="recipient"
            type="badge"
          />
          <m-team-badge
            v-else-if="recipient.type == 'team'"
            :team="recipient"
            type="badge"
          />
          <span v-else>{{ recipient.email }}</span>
          <m-icon
            id="remove_email"
            :icon="hover == recipient.id ? 'close' : 'none'"
            variant="terciary"
            :disabled="recipient.subscriptionActive === false"
            @click="remove(i)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="m-modal__footer">
    <template v-if="report.recurrent">
      <m-button
        id="m_modal_unautomate"
        :label="t('general.buttons.unautomate')"
        type="contained"
        variant="error"
        class="mt-3 mr-auto"
        @click="unAutomate"
      />
    </template>
    <m-button
      id="m_modal_cancel"
      :label="t('general.buttons.close')"
      type="text"
      variant="terciary"
      class="mt-3"
      @click="slotProps.cancel"
    />
    <m-button
      id="m_modal_continue"
      :label="
        report.recurrent
          ? t('general.buttons.save')
          : t('general.buttons.automate')
      "
      type="contained"
      variant="primary"
      class="mt-3 ml-6"
      :disabled="disabled"
      @click="automate"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useReportsStore } from "@root/store/modules/reports";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import structuredClone from "@root/utils/structuredClone";

import MSelector from "@components/MSelector.vue";
import MButton from "@components/MButton.vue";
import MIcon from "@components/MIcon.vue";
import MInput from "@components/MInput.vue";
import MSearch from "@components/MSearch.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MTeamBadge from "@components/MTeamBadge.vue";
import TimeFrame from "@root/utils/enums/timeFrames";
import { validateEmail } from "@root/utils/validateEmail";
import { useFiltersStore } from "@root/store/modules/filters";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const alertsStore = useAlertsStore();
const filtersStore = useFiltersStore();
const reportsStore = useReportsStore();
const workspacesStore = useWorkspacesStore();
const userStore = useUserStore();

/** @type {Readonly<{slotProps: {reportId: string}}>} */
const props = defineProps({
  slotProps: Object,
});

const hover = ref(undefined);
const frequencyType = ref(props.slotProps.recurrencyInfo?.recurrence);
const frequency = ref(props.slotProps.recurrencyInfo?.frequence);
const time = ref(props.slotProps.recurrencyInfo?.deliveryTime);
const recipientsList = ref(props.slotProps.recurrencyInfo.recipients || []);
const query = ref("");

const dateSpecs = ref({ mode: "range", timeframes: true, columns: 1 });

onMounted(() => {
  // fix recipients list
  recipientsList.value = recipientsList.value.map((m) => {
    console.log(m);
    if (m.type == "team") {
      const team = workspacesStore.getTeamById(m.id);
      team.type = "team";
      return team;
    } else if (m.type == "workspaceUser") {
      const user = structuredClone(workspacesStore.getUserById(m.id));
      user.type = "user";
      if (m.subscriptionActive === false) {
        user.state = t("modals.automateReport.recipients_unsubscribed");
      }

      return user;
    } else return m;
  });
});

onUnmounted(() => {
  frequencyType.value = props.slotProps.recurrencyInfo?.recurrence;
  frequency.value = props.slotProps.recurrencyInfo?.frequence ?? [];
  time.value = props.slotProps.recurrencyInfo?.deliveryTime;
  recipientsList.value = props.slotProps.recurrencyInfo.recipients || [];
  query.value = "";
});

const report = computed(() => reportsStore.getById(props.slotProps.reportId));

const frequencyTypeOpts = computed(() => {
  const opts = ["weekly", "monthly"];
  return opts.map((m) => ({
    value: m,
    label: t(`modals.automateReport.frequency_${m}`),
  }));
});

const frequencyOpts = computed(() => {
  if (frequencyType.value) {
    // sunday: 0
    // monday: 1
    // tuesday: 2
    // wednesday: 3
    // thursday: 4
    // friday: 5
    // saturday: 6

    const weekly = frequencyType.value == "weekly";
    const monthly = frequencyType.value == "monthly";

    let length;
    if (weekly) length = 7;
    else if (monthly) length = 31;

    return Array.from({ length: length }, (_, index) =>
      weekly ? index : index + 1
    ).map((m) => ({
      value: m,
      label: weekly ? t(`modals.automateReport.weekly_${m}`) : m,
    }));
  } else return [];
});

const changeFrequencyType = (val) => {
  clearFrequency();
  frequencyType.value = val.value;
};

const changeFrequency = (val) => {
  const idx = frequency.value?.findIndex((f) => f == val.value);
  if (idx != -1) {
    frequency.value.splice(idx, 1);
  } else {
    frequency.value.push(val.value);
  }
};

const clearFrequency = () => {
  frequency.value = [];
};

const availableTeamsList = computed(() => {
  const teams =
    userStore.details?.teams
      ?.filter((x) => x.active)
      .map((m) => ({
        ...m,
        label: m.name,
        value: m.id,
        type: "team",
      })) || [];

  recipientsList.value?.forEach((fe) => {
    const idx = teams.findIndex((f) => f.id == fe.id);
    if (idx != -1) teams.splice(idx, 1);
  });

  return teams;
});

const availableUsersList = computed(() => {
  const users =
    workspacesStore?.currentWorkspaceConfig?.workspaceUsers
      .filter((x) => x.active)
      .map((m) => ({
        ...m,
        value: m.id,
        label: `${m.accountDetails.firstName} ${m.accountDetails.lastName}`,
        type: "user",
      })) || [];
  recipientsList.value?.forEach((fe) => {
    const idx = users.findIndex((f) => f.id == fe.id);
    if (idx != -1) users.splice(idx, 1);
  });

  return users;
});

const availableRecipientsList = computed(() => {
  return availableTeamsList.value
    .concat(availableUsersList.value)
    ?.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
});

const validation = (val) => {
  if (val.email && !validateEmail(val.email)) {
    return {
      type: "error",
      label: t("general.errors.invalidEmail"),
    };
  } else if (
    val.email &&
    recipientsList.value?.find((f) => f.email == val.email)
  ) {
    return {
      type: "error",
      label: t("general.errors.emailExists"),
    };
  } else {
    return { type: null, label: null };
  }
};

const addRecipient = (val) => {
  if (val.type == "team" || val.type == "user") {
    recipientsList.value.push(val);
  } else if (validateEmail(val.email)) {
    recipientsList.value.push({
      type: "externalUser",
      email: val.email,
      id: null,
    });
    query.value = "";
  }
};

const remove = (idx) => {
  recipientsList.value.splice(idx, 1);
};

const clearAll = () => {
  recipientsList.value = [];
};

const disabled = computed(() => {
  return (
    !frequencyType.value ||
    !frequency.value?.length ||
    !time.value ||
    !recipientsList.value?.length
  );
});

watch(
  () => disabled.value,
  (val) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.slotProps.disabled = val;
  },
  { immediate: true }
);

const getType = (val) => {
  if (val == "user") return "workspaceUser";
  else if (val == "team") return "team";
  else return "externalUser";
};

const automate = async () => {
  const settings = {
    recurrent: true,
    frequence: frequency.value,
    recurrence: frequencyType.value,
    // Fix time in order to add seconds back
    deliveryTime: `${time.value}:00`,
    recipients: recipientsList.value.map((m) => ({
      type: getType(m.type),
      name: m.name ?? m.email,
      email: m.email,
      id: m.id,
    })),
    preferredLanguage: report.value.preferredLanguage,
    dateRestriction: filtersStore.dateRestriction,
    viewId: report.value.viewId,
  };

  try {
    await reportsStore.automateReport(settings, props.slotProps.reportId);
    alertsStore.add({
      type: "toast",
      variant: "success",
      message: t("general.alerts.toast.automatedReport"),
    });
  } catch (error) {
    alertsStore.add({
      type: "toast",
      variant: "error",
      message: t("general.alerts.toast.automatedReportError"),
    });
  }

  props.slotProps.close();
};

const unAutomate = () => {
  const settings = {
    recurrent: false,
    frequence: frequency.value,
    recurrence: frequencyType.value,
    // Fix time in order to add seconds back
    deliveryTime: `${time.value}:00`,
    recipients: recipientsList.value.map((m) => ({
      type: getType(m.type),
      name: m.name ?? m.email,
      email: m.email,
      id: m.id,
    })),
    preferredLanguage: report.value.preferredLanguage,
    dateRestriction: filtersStore.dateRestriction,
    viewId: report.value.viewId,
  };
  const clone = structuredClone(report.value);
  clone.recurrencyInfo = settings;
  clone.recurrent = false;
  reportsStore.updateReport(clone);
  alertsStore.add({
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.unAutomatedReport"),
  });

  props.slotProps.close();
};
</script>

<style scoped lang="scss">
.m-automate-report {
  @include flex(flex-start, stretch, column);

  &__frequency {
    width: 100%;
    @include flex(space-between, center, row);
    gap: $spacing-2;

    .m-frequency__list {
      width: 100%;
      gap: $spacing-2;
    }

    .m-list__day {
      border: 1px solid color($sec-200);
      @include round-corners($spacing-1);
      @include flex(center, center, row);
      cursor: pointer;

      span {
        color: color($text-medium);
        cursor: pointer;
      }

      &--selected {
        background-color: color($pri-action);

        span {
          color: color($white);
        }
      }

      &:hover:not(.m-list__day--selected) {
        border: 1px solid color($pri-action, 0.1);

        span {
          color: color($pri-action);
        }
      }
    }

    &--weekly {
      .m-frequency__list {
        @include flex(space-between, center, row);
      }

      .m-list__day {
        width: calc(100% / 7);
      }
    }

    &--monthly {
      .m-frequency__list {
        @include flex(flex-start, center, row);
        flex-wrap: wrap;

        .m-list__day {
          min-width: $spacing-8;
        }
      }
    }
  }

  &__heading {
    margin-bottom: $spacing-1;
    @include flex(space-between, center, row);
  }

  &__list {
    height: 20vh;
    border: 1px solid color($pri-action-inactive, 0.3);
    @include round-corners($spacing-1);
    overflow-y: auto;
    @include elevate-inset;

    &--center {
      @include flex(center, center, column);
    }

    .m-list__element {
      padding: $spacing-2 $spacing-3;
      margin: $spacing-1;
      @include flex(space-between, center, row);
      gap: $spacing-1;
    }
  }
}
</style>
