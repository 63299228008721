<template>
  <div
    v-if="dynamicSearch"
    class="m-filter__search"
    data-tour="tour_explore_search_2"
  >
    <div
      :id="`m_filter_${id}_box`"
      ref="filterRef"
      tabindex="0"
      :aria-controls="`m_filter__box_dropdown_${id}_box_dropdown`"
      :aria-expanded="openDropdown"
      aria-haspopup="listbox"
      role="input"
      class="m-filter__box"
      :class="[
        `m-filter__box--${size}`,
        {
          'pb-1': selectedFilters.length == 0,
          '--a11y': appStore.a11y,
        },
      ]"
      @click="toggleDropdown"
      @keyup.up="openDropdown = true"
      @keyup.down.exact="openDropdown = true"
      @keyup.alt.down="openDropdown = true"
      @keyup.home="openDropdown = true"
      @keyup.end="openDropdown = true"
      @keyup.space="openDropdown = true"
      @keyup.enter="openDropdown = true"
      @keyup.esc="closeDropdown"
    >
      <m-icon icon="search" status="active" class="mr-1" />
      <div class="m-filter__content">
        <!--  Normal filters -->
        <div
          v-for="(facet, i) in selectedFilters"
          :key="i"
          :class="facetClass(facet)"
        >
          <div
            class="d-flex-start-center"
            @mouseenter="hoverFacet = facet.value"
            @mouseleave="hoverFacet = undefined"
          >
            <m-icon
              v-if="hoverFacet == facet.value"
              :id="`close_${i}`"
              icon="close"
              size="small"
              class="m-filter__icon-remove mr-1 c-pointer"
              @click="removeFilterGroup(i)"
            />
            <m-facet-tag
              v-if="facet.value && facet.value != 'customQuery'"
              :facet="facet.value ?? facet.label"
              :label="getFilterLabel(facet)"
              class="mr-1"
              size="small"
            />
          </div>
          <div
            v-for="(value, idx) in facet.query"
            :key="idx"
            class="m-filter__queries"
            :class="{
              'd-none': value.operator && idx == facet.query.length - 1,
            }"
          >
            <h6 class="type--small m-filter__operator">
              {{ value.operator }}
            </h6>
            <h6
              tabindex="0"
              role="button"
              class="m-clickable type--small"
              :class="{ 'type--error': value.negated }"
              @mouseenter="hoverFacetQuery = value.label"
              @mouseleave="hoverFacetQuery = undefined"
              @dblclick="
                (evt) =>
                  handleQueryItemDblClick(evt, facet.value, value, i, idx)
              "
              @keydown.enter="toggleNegated(facet.value, i, idx)"
            >
              <span v-if="value.negated" class="ml-1 type--error"> - </span>
              <m-input
                v-if="value.editing === true"
                id="value_edit_input"
                v-model="value.label"
                @resolve="
                  () => {
                    value.value = value.label;
                    delete value.editing;
                    updateFilters([{ facets: selectedFilters }], true);
                  }
                "
              />
              <template v-else>
                {{ value.label }}
              </template>
              <m-icon
                :id="`close_${idx}`"
                icon="close"
                size="small"
                class="m-filter__icon-remove"
                @click="removeFilter(i, idx)"
              />
            </h6>
          </div>
          <!-- <m-icon
              :id="`edit_${i}`"
              icon="edit"
              class="m-filter__icon-edit ml-1"
              @click="editFilterGroup(i)"
            /> -->
        </div>
        <!-- End of normal filters -->

        <!--  Contextual filters -->
        <template
          v-if="
            showContextual &&
            !isEmpty(filtersStore.queryObject.contextualFilters)
          "
        >
          <div
            v-for="(facet, i) in filtersStore.queryObject.contextualFilters"
            :key="i"
            :class="facetClass(facet)"
            class="contextual-filter"
          >
            <div
              class="d-flex-start-center"
              @mouseenter="hoverFacet = facet.value"
              @mouseleave="hoverFacet = undefined"
            >
              <m-icon
                v-if="hoverFacet == facet.value"
                :id="`close_${i}`"
                icon="close"
                size="small"
                class="m-filter__icon-remove mr-1 c-pointer"
                @click="removeFilterGroup(i, true)"
              />
              <m-facet-tag
                v-if="facet.value && facet.value != 'customQuery'"
                :facet="facet.label ?? facet.value"
                class="mr-1"
                size="small"
              />
            </div>
            <div
              v-for="(value, idx) in facet.query"
              :key="idx"
              class="m-filter__queries"
              :class="{
                'd-none': value.operator && idx == facet.query.length - 1,
              }"
            >
              <h6 class="type--small m-filter__operator">
                {{ value.operator }}
              </h6>
              <h6
                tabindex="0"
                role="button"
                class="m-clickable type--small"
                :class="{ 'type--error': value.negated }"
                @mouseenter="hoverFacetQuery = value.label"
                @mouseleave="hoverFacetQuery = undefined"
                @click="toggleNegated(facet.value, i, idx)"
                @keydown.enter="toggleNegated(facet.value, i, idx)"
              >
                <span v-if="value.negated" class="ml-1 type--error"> - </span>
                {{ value.label }}
                <m-icon
                  :id="`close_${idx}`"
                  icon="close"
                  size="small"
                  class="m-filter__icon-remove"
                  @click="removeFilter(i, idx)"
                />
              </h6>
            </div>
            <!-- <m-icon
                :id="`edit_${i}`"
                icon="edit"
                class="m-filter__icon-edit ml-1"
                @click="editFilterGroup(i)"
              /> -->
          </div>
        </template>
        <!-- End of Contextual filters -->

        <input
          :id="`m_filter_${id}_input`"
          ref="inputRef"
          v-model="inputQuery"
          :placeholder="setInputPlaceholder"
          class="type--small"
          @keydown="inputKeydown"
          autocomplete="off"
          :data-autofocus="autoFocus"
          @keydown.esc="closeDropdown"
          @keydown.enter="addFilter"
          v-focus
        />
      </div>
      <m-loading v-if="isSearching" size="xsmall" class="mr-1" />
      <m-icon
        v-if="
          selectedFilters?.length != 0 ||
          filtersStore.queryObject.centerNode ||
          filtersStore.queryObject.contextualFilters
        "
        id="m_filter_remove_all"
        icon="clear"
        variant="secondary"
        class="m-filter__icon-clear"
        @click="removeAllFilters"
      />
    </div>
    <m-icon
      :icon="filtersStore.queryObject?.filters ? 'view-filter' : 'none'"
      :tooltip="filtersStore.queryObject?.filters ? createViewTooltip : ''"
      hover="elevate"
      variant="secondary"
      class="ml-3 mr-3"
      @click="createView(filtersStore.queryObject)"
    />
    <m-dropdown
      :id="`m_filter__box_dropdown_${id}_box_dropdown`"
      :labelledBy="`m_filter_${id}`"
      v-model:visible="openDropdown"
      v-model:focused="focusedFilter"
      :options="autoCompleteResults"
      v-slot="slotProps"
      :keyup="keyup"
      :keydown="keydown"
      :search="false"
      fullwidth
      class="m-filter__dropdown"
      :class="{ 'm-filter__dropdown--negate': hover.negate }"
      @update:selected="formatFilter"
      @mouseleave="closeDropdown"
      @mouseenter="keepDropdownOpen"
    >
      <div
        v-if="slotProps.option.filter == 'customQuery'"
        class="m-filter__query"
      >
        {{
          t("components.filterAdvanced.searchForQuery", {
            query: slotProps.option.label,
          })
        }}
      </div>
      <div v-else class="m-filter__label">
        <p>
          <m-facet-tag :facet="slotProps.option.filterUiKey" class="mr-2" />
          {{ slotProps.option.label }}
        </p>
        <h6
          v-if="
            !isTokenIncluded(lastSearchQuery, slotProps.option.label) &&
            slotProps.option.source
          "
          class="type--empty type--xsmall"
        >
          <span class="mr-1">
            {{ localizeLanguageCode(slotProps.option.source?.lang) }}:
          </span>
          {{ slotProps.option.source?.value }}
        </h6>
      </div>
      <m-button
        v-if="slotProps.option.filter != 'customQuery'"
        v-show="focusedFilter == slotProps.option.value"
        id="negate"
        type="contained"
        variant="error"
        size="xsmall"
        :label="$t('components.filterPane.negateFilter')"
        class="ml-5 m-filter__negate"
        @mouseenter="hover.negate = true"
        @mouseleave="hover.negate = false"
        @click="negateFilter(slotProps.option)"
      />
    </m-dropdown>
  </div>
  <div
    v-if="filterPane"
    class="m-filter__pane"
    data-tour="tour_explore_filter_pane_3"
    :class="{
      'm-filter__pane--open': !pinnedPane && showFilterPane,
      'm-filter__pane--pinned': pinnedPane,
      'm-filter__pane--unpinned': !pinnedPane,
    }"
  >
    <div
      :id="`m_filter_${id}_pane`"
      class="m-toolbox mr-0"
      :class="[
        pinnedPane || (!pinnedPane && showFilterPane)
          ? 'm-toolbox--open'
          : 'm-toolbox--closed',
        { 'm-toolbox--pinned': pinnedPane },
      ]"
    >
      <div class="m-toolbox__heading">
        <div>
          <m-icon
            v-if="!editingPane"
            :id="`m_filter_${pinnedPane}`"
            :icon="pinState"
            variant="secondary"
            :tooltip="pinFilterTooltip"
            hover="highlight"
            class="mr-2"
            @mouseenter="togglePinState"
            @mouseleave="togglePinState"
            @click="togglePinnedPane"
          />

          <h4 :class="{ 'pl-1': editingPane }">
            {{ t("components.filterPane.filters") }}
          </h4>
        </div>
        <div>
          <m-icon
            v-if="!editingPane"
            id="m_filter_ai_info"
            icon="info"
            size="small"
            :tooltip="aiDisclaimerTooltip"
            class="mr-1 py-1"
          />
          <m-button
            v-if="editingPane"
            id="m_filter_pane_save"
            :label="t('components.filterPane.save')"
            :loading="isSavingPaneChanges"
            :disabled="isSavingPaneChanges"
            type="contained"
            variant="primary"
            size="xsmall"
            @click="savePaneChanges"
          />
          <m-options
            v-if="!editingPane && filterPaneOptions.length"
            id="m_filter_pane_options"
            :options="filterPaneOptions"
            :position="['left', 'bottom']"
            floating
            class="py-1 m-clickable"
            @select="filterPaneSelectOpt"
          />
        </div>
      </div>
      <div class="m-divider"></div>
      <draggable
        v-if="editingPane"
        v-model="filterGroups"
        group="filterGroups"
        @start="drag = true"
        @end="drag = false"
        item-key="id"
        class="pr-0 m-filter__pane--editor m-toolbox__modules"
      >
        <template #item="{ element }">
          <div
            class="m-filter-group m-toolbox__module"
            :class="
              element.selected
                ? 'm-filter-group--enabled'
                : 'm-filter-group--disabled'
            "
          >
            <div class="mb-3 m-filter-group__header">
              <h5
                class="type--small"
                :class="{ 'type--empty': !element.selected }"
              >
                {{
                  te(`general.facets.${props.label}`)
                    ? t(`general.facets.${element.uiKey}`)
                    : element.uiKey
                }}
              </h5>
              <m-icon
                :icon="element.selected ? 'line' : 'add'"
                variant="secondary"
                size="small"
                :tooltip="
                  t(
                    `components.filterPane.${
                      element.selected ? 'disable' : 'enable'
                    }Group`
                  )
                "
                class="mr-2 m-filter__icon-status"
                @click="element.selected = !element.selected"
              />
            </div>
            <div class="m-divider"></div>
          </div>
        </template>
      </draggable>
      <div
        v-else-if="cleanedFilterGroups?.length > 0"
        id="m_filter_pane_content"
        class="m-toolbox__modules"
      >
        <m-filter-group
          v-for="(group, i) in cleanedFilterGroups"
          :key="group.id"
          :id="`m_filter_group_${i}`"
          :group="group"
          :search="searchFacetsString"
          class="m-toolbox__module"
          @update:group="(g, entry) => groupChanged(g, entry, i)"
          @more-entries="moreEntries"
        />
      </div>
      <div
        v-if="
          isLoadingFilterPane ||
          isUpdatingFilterPanel ||
          cleanedFilterGroups?.length == 0
        "
        class="m-toolbox__modules"
        :class="{ 'p-absolute': isUpdatingFilterPanel }"
      >
        <m-loading
          v-if="isLoadingFilterPane || isUpdatingFilterPanel"
          size="small"
          overlay
        />
        <template v-else-if="cleanedFilterGroups?.length == 0">
          <div class="m-filter-pane__empty">
            <h4 class="type--small type--empty">
              {{ t("components.filterPane.noSearchResults") }}
            </h4>
            <h6 class="type--small type--empty">
              {{ t("components.filterPane.tryChanging") }}
            </h6>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { cloneDeep, debounce, isEmpty } from "lodash-es";
import { useRouter, useRoute } from "vue-router";
import draggable from "vuedraggable";
import structuredClone from "@utils/structuredClone";
import * as ASCIIFolder from "fold-to-ascii";

import MDropdown from "@components/MDropdown.vue";
import MFilterGroup from "@components/filter/MFilterGroup.vue";
import MFacetTag from "@components/MFacetTag.vue";
import MButton from "@components/MButton.vue";
import MIcon from "@components/MIcon.vue";
import useDropdown from "@hooks/useDropdown";
import MLoading from "@components/MLoading.vue";
import MInput from "@components/MInput.vue";
import MOptions from "@components/MOptions.vue";
import useCreateViewModal from "@components/modals/MCreateView/useCreateViewModal";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useFiltersStore, ViewFiltersUtils } from "@root/store/modules/filters";
import useTranslator from "@root/hooks/useTranslator";
const { localizeLanguageCode } = useTranslator();

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  type: {
    type: String,
  },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "small"].includes(size)) {
        console.error(
          `Invalid prop "size": expected string with value "default", "small" or "xsmall" and got "${size}". \n\n Go to https:/ for instructions on how to use the MFilter component.`
        );
      }
      return true;
    },
  },
  /** @description These are the filters */
  modelValue: {
    type: Array,
  },
  placeholder: {
    type: String,
  },
  scoped: {
    type: Boolean,
    required: false,
    default: true,
  },
  autoFocus: {
    type: Boolean,
    default: true,
  },
  showContextual: {
    type: Boolean,
    default: true,
  },
  dynamicSearch: { type: Boolean, default: true },
  filterPane: { type: Boolean, default: true },
});

const emit = defineEmits([
  "update:modelValue",
  "add-query",
  "toggle-operator",
  "updated",
]);

const router = useRouter();
const route = useRoute();
const { t, te } = useI18n();
const { api } = useApi();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const appStore = useAppStore();
const filtersStore = useFiltersStore();
const workspacesStore = useWorkspacesStore();
const { open } = useCreateViewModal();

const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);
const view = computed(() => viewsStore.getViewById(viewId.value));
const { openDropdown, keepDropdownOpen, closeDropdown } = useDropdown();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const workspaceId = ref(workspaceConfig.value.id);

/** @type {import("vue").ComputedRef<import("@root/types.api.local").MonitioAPI.SearchPropertyTypeDTO2[]} */
const propertyTypeSettings = computed(
  () => userStore.config?.propertyTypeSettings?.facets?.[workspaceId.value]
);

const filterRef = ref(null);
const searchFacetsString = ref("");

const refinedFilterGroups = ref(null);
const focusedFilter = ref(null);
/** @type {import("vue").Ref<HTMLElement>}*/
const inputRef = ref(null);

const facetClass = (facet) => {
  if (facet.value == "customQuery") {
    let className = "m-filter__custom-query";
    if (hoverFacetQuery.value == facet.value) {
      className += " m-filter__custom-query--hover pr-0";
    }
    return className;
  } else {
    let className = "m-filter__tag";
    if (hoverFacet.value == facet.value) {
      className += " m-filter__tag--hover pl-1";
    }
    return className;
  }
};

const pinnedPane = computed(() => userStore.pinnedPane);

const togglePinnedPane = () => {
  if (
    (pinnedPane.value && pinState.value == "pinned") ||
    (!pinnedPane.value && pinState.value == "pin")
  ) {
    return;
  }
  if (pinnedPane.value) pinState.value = "pin";
  else pinState.value = "pinned";

  togglePinState();
  userStore.setPinnedPane(!pinnedPane.value);
};

const pinState = ref("none");

onMounted(() => {
  if (pinnedPane.value) pinState.value = "pinned";
  else pinState.value = "pin";
});

const togglePinState = (evt) => {
  if (
    (pinnedPane.value && pinState.value == "pinned") ||
    (!pinnedPane.value && pinState.value == "pin")
  ) {
    if (evt?.type == "mouseleave") return;
  }
  if (pinState.value == "pinned") pinState.value = "pin";
  else pinState.value = "pinned";
};

const hover = ref({
  negate: false,
});

const showFilterPane = computed({
  get() {
    return !userStore.account.filterPaneMinimized;
  },
  set(val) {
    const accountConfig = structuredClone(userStore.account);
    accountConfig.filterPaneMinimized = !val;
    userStore.updateUserDetails(null, accountConfig);
  },
});

const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  keyup.value = evt;
};

const keyDownMovement = (evt) => {
  keydown.value = evt;
};

onMounted(() => {
  if (filterRef.value) {
    filterRef.value.addEventListener("keyup", keyUpMovement);
    filterRef.value.addEventListener("keydown", keyDownMovement);
  }
});

onBeforeUnmount(() => {
  if (filterRef.value) {
    filterRef.value.removeEventListener("keyup", keyUpMovement);
    filterRef.value.removeEventListener("keydown", keyDownMovement);
  }
});

const createView = (queryObject) => {
  const qObj = cloneDeep(queryObject ?? {});
  qObj.filter ??= [];
  let filters;
  // console.log(view.value);
  // console.log(view.value?.details?.filters?.length);

  if (view.value?.details?.filters?.length) {
    filters = view.value?.details?.filters.concat(qObj.filters);
  } else filters = qObj.filters;

  if (qObj.contextualFilters) {
    filters = [{ facets: qObj.contextualFilters }].concat(filters);
  }

  viewsStore.setFilters(filters);

  open(props.cluster ? "fromStory" : "fromFilter");
};

const editingPane = ref(false);
const filterGroups = ref(null);
onMounted(async () => {
  filterGroups.value = propertyTypeSettings.value
    ?.filter((x) => x.active)
    .sort((a, b) => a.rank - b.rank);
});

const isSavingPaneChanges = ref(false);
const savePaneChanges = async () => {
  isSavingPaneChanges.value = true;
  const config = structuredClone(userStore.config);
  filterGroups.value = filterGroups.value?.map((type) => ({
    ...type,
    rank: filterGroups.value.findIndex((x) => x.uiKey == type.uiKey),
    sourceLevel: "workspaceUser",
  }));
  config.propertyTypeSettings.facets[workspaceId.value] = structuredClone(
    filterGroups.value
  );

  try {
    await userStore.updateUserPropertyTypeConfig(config.propertyTypeSettings);
    //filterGroups.value = reOrdered;
    updateFilterPanel();
  } catch (error) {
    console.debug("Unable to change filter group order");
  }
  isSavingPaneChanges.value = false;
  editingPane.value = false;
};

const paneState = ref("");
onMounted(() => {
  if (!pinnedPane.value && showFilterPane.value) paneState.value = "collapse";
  else paneState.value = "expand";
});

const filterPaneOptions = computed(() => {
  const arr = [];
  if (!pinnedPane.value) {
    arr.push({
      value: paneState.value,
      label: t(`components.filterPane.${paneState.value}`),
    });
  }

  arr.push({
    value: "changeOrder",
    label: t("components.filterPane.changeFilterOrder"),
  });

  return arr;
});

const filterPaneSelectOpt = (val) => {
  switch (val) {
    case "expand":
      showFilterPane.value = true;
      paneState.value = "collapse";
      break;
    case "collapse":
      showFilterPane.value = false;
      paneState.value = "expand";
      break;
    case "changeOrder":
      //showFilterPane.value = true;
      editingPane.value = true;
      break;
    default:
      break;
  }
};

//#region Section with the autocomplete state and functions
const inputQuery = ref("");
const lastSearchQuery = ref("");

const autoCompleteResults = ref([]);
const isSearching = ref(false);
const hoverFacet = ref(false);
const hoverFacetQuery = ref(false);

/** @type {Set<AbortController>} */
const autoCompleteControllers = new Set();
const getAutocomplete = debounce(async function (val) {
  if (val == "") {
    isSearching.value = false;
    return;
  }
  isSearching.value = true;

  /** @description An array with all the porperty types to send in the request, beucase we want them all */
  let data;
  try {
    const controller = new AbortController();
    autoCompleteControllers.add(controller);
    const response = await api.search.autocomplete(
      viewId.value,
      val,
      allPropertyTypes.value.join().replaceAll("_qid", ""), //We dont want to search per QIDS so we need to remove them only here
      controller
    );
    data = response.data;
  } catch (error) {
    isSearching.value = false;
    console.debug("Request canceled");
  }

  if (!data?.entries) {
    isSearching.value = false;
    autoCompleteResults.value = [];
    return;
  }

  const i18nLanguage = userStore.i18nLanguage?.split("-")[0] || "en";
  const userTranslateFrom = userStore.account?.translateFrom ?? [];

  const results =
    data?.entries?.map((entry) => {
      let description = "";
      if (entry.descriptions && Object.values(entry.descriptions).length > 0) {
        description =
          entry.descriptions?.[i18nLanguage] ||
          entry.descriptions?.en ||
          entry.descriptions?.xx ||
          Object.values(entry.descriptions)?.[0];
      }

      /** @description The label of the entity. It might be in the user's language or not */
      const label =
        entry.labels[
          Object.keys(entry.labels).find((x) => x.includes(i18nLanguage))
        ] ??
        entry.labels[i18nLanguage] ??
        entry.labels.en ??
        entry.labels.xx ??
        Object.values(entry.labels)[0];

      const matchingEntries = Object.entries(entry.labels).filter((x) =>
        isTokenIncluded(val, x[1])
      );

      /** @description Get a label where the string the user searched for is guarateeed to be */
      const source =
        matchingEntries?.find((x) => x[0] == i18nLanguage) ??
        matchingEntries?.find((x) => !userTranslateFrom.includes(x[0])) ??
        matchingEntries?.find((x) => x[0].includes("en")) ??
        matchingEntries?.find((x) => x[0] == "xx") ??
        matchingEntries?.[0];

      return {
        value: entry.key,
        filter: entry.type,
        filterUiKey: entry.typeUiKey ?? entry.type,
        namespace: entry.namespace,
        source: source ? { lang: source[0], value: source[1] } : null,
        description,
        label: label,
      };
    }) ?? [];

  results.sort((a, b) => {
    if (b.source?.lang == i18nLanguage && a.source?.lang != i18nLanguage)
      return 1;
    if (isTokenIncluded(val, b.label) && !isTokenIncluded(val, a.label))
      return 1;

    if (
      !userTranslateFrom?.includes(b.source?.lang) &&
      userTranslateFrom?.includes(a.source?.lang)
    )
      return 1;
    else return 0;
  });

  if (inputQuery.value) {
    results.unshift({
      value: inputQuery.value,
      label: inputQuery.value,
      filter: "customQuery",
    });
  }

  lastSearchQuery.value = val;
  autoCompleteResults.value = results;

  if (isSearching.value) {
    openDropdown.value = true;
    isSearching.value = false;
  }
}, 600);

watch(
  () => inputQuery.value,
  (val) => {
    closeDropdown();
    if (isSearching.value) {
      autoCompleteControllers.forEach((x) => x.abort());
    }
    isSearching.value = true;
    getAutocomplete(val);
  }
);

/** @description sorts the filter GROUPS accoring to the users choice or defaults */
const sortFilters = (val) => {
  if (filterGroups.value) {
    const list = filterGroups.value
      .filter((x) => x.selected && x.active)
      .map((m) => ({
        key: m.searchKey,
        rank: m.rank,
        label: m.uiKey,
        sourceLevel: m.sourceLevel,
      }));

    return val
      .filter((f) => list.find((x) => x.key == f.id || x.label == f.id))
      .sort(
        (a, b) =>
          list.find((x) => x.key == a.id || x.label == a.id).rank -
          list.find((x) => x.key == b.id || x.label == b.id).rank
      );
  } else return val;
};

//#region Global State management
/**
 * 🚨 If the scoped property is false this component will grab/save
 *  state to the browser url throught the useViewFilters hook when everytime
 * an emit is to happen
 */

/**  @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]} filters */
const updateFilters = async (filters, immediate = false) => {
  //console.log("avorting pending filters requests");
  if (immediate) {
    /** If this if sending to many API calls do some sort of control flow here */
    updateFilterPanelNow(filters);
    if (!props.scoped) {
      // Component is not scoped so the user wants it to control the url state
      const query = { ...filtersStore.queryObject };
      query.filters = filters;

      if (!query.filters || query.filters?.length == 0) delete query.filters;
      filtersStore.updateQueryObject(query, route, router);
    }
    return;
  } else {
    /** If this if sending to many API calls do some sort of control flow here */
    if (!props.scoped) {
      // Component is not scoped so the user wants it to control the url state
      // After this function runs the watch will execute the necesary API calls to get new counts
      debounceUpdateViewFilters(filters, route, router);
    } else updateFilterPanel(filters);
    return;
  }
};

const debounceUpdateViewFilters = debounce(
  (filters, route, router) =>
    filtersStore.updateViewFilters(filters, route, router),
  window._env_.VUE_APP_DEFAULT_FILTER_DEBOUNCE
);

const isUpdatingFilterPanel = ref(false);
const updateFilterPanel = debounce((filters) => {
  console.log("updateFilterPanel");
  updateFilterPanelNow(filters);
}, window._env_.VUE_APP_DEFAULT_FILTER_DEBOUNCE);

const updateFilterPanelNow = async (filters) => {
  console.log("updateFilterPanelNow");

  //cancel token here
  isUpdatingFilterPanel.value = true;
  try {
    const data = await getRefinedFilters(
      viewId.value,
      selectedFilters.value,
      filtersStore.queryObject?.contextualFilters
    );

    refinedFilterGroups.value = [];
    refinedFilterGroups.value = data;
    isUpdatingFilterPanel.value = false;
    emit("update:modelValue", filters);
  } catch (error) {
    console.error(error);
    isUpdatingFilterPanel.value = false;
    throw error;
  }
};
//#endregion

/**
 * @description Filters and outputs the filtergroups with the selcted filters only
 * @type {import("vue").Ref<import("@root/types.api.local").MonitioAPI.FrontendFiltersFacet[]>}
 */
const selectedFilters = ref([]);

const selectFilter = async (value) => {
  if (!value) return;
  const newFilters = await ViewFiltersUtils.selectFilter(
    selectedFilters.value,
    value
  );
  inputQuery.value = "";
  autoCompleteResults.value = [];
  closeDropdown();

  selectedFilters.value = newFilters ?? [];
  await updateFilters([{ facets: selectedFilters.value }]);
  emit("updated");
};

/**
 *
 * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersFacet} value
 */
const getFilterLabel = (value) => {
  const label = propertyTypeSettings.value.find(
    (x) => x.uiKey == value.value || x.searchKey == value.value
  )?.uiKey;

  if (t(`general.facets.${label}`).includes(".")) {
    return label;
  }
  return t(`general.facets.${label}`);
};

const handleQueryItemDblClick = (
  evt,
  facetValue,
  value,
  groupIdx,
  entryIdx
) => {
  if (facetValue == "customQuery") {
    value.editing = true;
  } else toggleNegated(facetValue, groupIdx, entryIdx);
};

const negateFilter = (entry) => {
  selectFilter({
    ...entry,
    selected: true,
    negated: true,
  });
};

const toggleNegated = (value, groupIdx, entryIdx) => {
  if (value == "customQuery") return;
  selectedFilters.value = ViewFiltersUtils.toggleNegated(
    selectedFilters.value,
    value,
    groupIdx,
    entryIdx
  );
  updateFilters([{ facets: selectedFilters.value }]);
};

const removeFilter = (groupIdx, idx) => {
  selectedFilters.value = ViewFiltersUtils.removeFilter(
    selectedFilters.value,
    groupIdx,
    idx
  );
  updateFilters([{ facets: selectedFilters.value }]);
};

const removeFilterGroup = (groupIdx, isContextualFilters = false) => {
  if (isContextualFilters) {
    const query = { ...filtersStore.queryObject }; //Avoid structuredClone() here! It removes needed functions!
    delete query.contextualFilters;
    filtersStore.updateQueryObject(query, route, router);
  } else {
    selectedFilters.value = ViewFiltersUtils.removeFilterGroup(
      selectedFilters.value,
      groupIdx
    );
    updateFilters([{ facets: selectedFilters.value }]);
  }
};

const removeAllFilters = () => {
  selectedFilters.value = ViewFiltersUtils.removeAllFilters(
    selectedFilters.value
  );
  filtersStore.clearQueryObjectProps(
    ["filters", "contextualFilters", "centerNode"],
    route,
    router
  );
  //updateFilters([{ facets: selectedFilters.value }], true);
};
//#endregion

const setInputPlaceholder = computed(() => {
  if (selectedFilters.value.length == 0) {
    return props.placeholder ?? t("components.search.placeholder_search");
  } else return "";
});

/**
 * @description Read all the filters and map them so its matches a more normalized model
 * @type {import("vue").ComputedRef<import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[];>}
 */
const cleanedFilterGroups = computed(() => {
  // Here we clean up the backend data and also translate accordingly
  if (!refinedFilterGroups.value) return [];
  return refinedFilterGroups.value.map((group) => {
    return {
      id: group.id,
      label: group.label ?? group.id,
      hasMore: group.hasMore,
      values: Object.keys(group.filters).map((key) => ({
        value: group.filters[key].id,
        label: group.filters[key].label || group.filters[key].id,
        selected: group.filters[key].selected,
        negated: group.filters[key].negated,
        count: group.filters[key].count,
        view: group.filters[key].view,
        wasExtrapolated: group.filters[key].wasExtrapolated,
      })),
    };
  });
});

defineExpose({
  cleanedFilterGroups,
});

const allPropertyTypes = computed(() => {
  return propertyTypeSettings.value
    ?.filter((x) => x.active)
    .map((x) => x.searchKey);
});

const isLoadingFilterPane = ref(true);
onMounted(async () => {
  if (!props.scoped && filtersStore.queryObject?.filters) {
    selectedFilters.value = filtersStore.queryObject?.filters[0].facets ?? [];
  }

  try {
    refinedFilterGroups.value = await getRefinedFilters(
      viewId.value,
      selectedFilters.value,
      filtersStore.queryObject.contextualFilters
    );
  } catch (error) {
    console.error(error);
  }
});

watch(
  () => filtersStore.queryObject,
  debounce((queryObj) => {
    isUpdatingFilterPanel.value = true;
    if (!props.scoped) {
      if (queryObj.filters) selectedFilters.value = queryObj.filters[0].facets;
      else selectedFilters.value = [];
    }
    updateFilterPanel();
  }, window._env_.VUE_APP_DEFAULT_FILTER_DEBOUNCE)
);

/** @type {Set<AbortController>} */
const filtersAbortControllers = new Set();
/**
 *
 * @param {string} viewId
 * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup} currentFilters
 * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersFacet[]} contextualFilters
 */
const getRefinedFilters = async (viewId, currentFilters, contextualFilters) => {
  // Just in case a request iis still onGoing, cancel it
  filtersAbortControllers.forEach((x) => x.abort());
  //#region Kinda of a cache layer. If the filters didnt change just deliver the cached values
  const params = JSON.stringify({
    viewId,
    filters: currentFilters,
    contextualFilters: contextualFilters,
    dateRestriction: filtersStore.dateRestriction,
    userLanguage: userStore.i18nLanguage,
  });
  if (filtersStore.latestApiCallParams == params && filtersStore.latestResult) {
    console.debug("Delivering cached filters");
    isLoadingFilterPane.value = false;
    return sortFilters(filtersStore.latestResult);
  }
  //#endregion
  /** @type {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]} */
  const filters = [{ facets: currentFilters }];
  if (!isEmpty(contextualFilters)) filters.push({ facets: contextualFilters });

  const controller = new AbortController();
  filtersAbortControllers.add(controller);

  try {
    /** @type {import("axios").AxiosResponse<import("@root/types.api.local").MonitioAPI.FilterNavigator>} */
    const response = await api.search.refineProperties(
      viewId,
      filters,
      filtersStore.dateRestriction,
      undefined,
      undefined,
      controller
    );
    filtersAbortControllers.delete(controller);

    if (response.status != 200) {
      isLoadingFilterPane.value = false;
      if (response.message === "canceled") {
        throw new Error("Request was cancelled");
      }
      return [];
    }

    /** Process this keyed object into a proper array for easier handling in the future */

    /** @type {import("@root/types.api.local").MonitioAPI.FilterGroup[]} */
    let groups =
      Object.keys(response.data.groups ?? {}).map(
        (x) => response.data.groups?.[x]
      ) || [];

    /** Mark the correct facets as selected acording to the query on the URL */
    if (currentFilters?.length > 0) {
      groups.forEach((group) => {
        const facet = currentFilters.find((facet) => group.id == facet.value);
        if (facet) {
          facet.query?.forEach((item) => {
            if (item.value) {
              //If .value is undefined its because this entry is an operator
              for (const key in group.filters) {
                if (Object.hasOwnProperty.call(group.filters, key)) {
                  const filter = group.filters[key];
                  if (
                    filter.id.toLowerCase() === item.value.toLocaleLowerCase()
                  ) {
                    filter.selected = true;
                    filter.negated = item.negated ?? false;
                  }
                }
              }
            }
          });
        }
      });
    }

    // Update the cache layer
    filtersStore.updateLatestApiCallParams(params);
    filtersStore.updateLatestResult(structuredClone(groups));

    if (viewId) groups = sortFilters(groups);

    isLoadingFilterPane.value = false;
    return groups;
  } catch (error) {
    isLoadingFilterPane.value = false;
    throw error;
  }
};

//#region This is code to take care of the changing filters and updating the URL itself
const groupChanged = async (group, entry) => {
  selectFilter({
    ...entry,
    filter: group.id,
    label: entry.label,
    pane: true,
  });

  /** Need to keep refinedFilterGroups up to date in this case,
   * because the MFilterGroup compnent is using this OLD data format */

  //let facetGroup = createFacetGroup(refinedFilterGroups.value);
  //console.log(facetGroup);
  //updateFilters([facetGroup]);
};

const moreEntries = (group) => {
  const groupIdx = refinedFilterGroups.value?.findIndex(
    (x) => x.id == group.id
  );
  if (groupIdx == -1) return;

  for (const filter in group.filters) {
    if (group.filters[filter].count === 0) {
      delete group.filters[filter];
    }
  }
  refinedFilterGroups.value[groupIdx] = group;
};

//#endregion

const inputKeydown = (val) => {
  switch (val.keyCode) {
    case 8: //BACKSPACE
      console.debug("Unable to remove filter because selectedFilters is empty");
      if (inputQuery.value.length == 0 && selectedFilters?.value?.length > 0) {
        removeFilterGroup(selectedFilters.value.length - 1);
      }
      break;
  }
};

const formatFilter = (val) => {
  console.log("formatFilter");
  if (!val) return;
  if (val.namespace == "wikidata") {
    // This is a bit of a compatibility layer o check if the filters either have QIDS supported or not.
    const groupInFilters = cleanedFilterGroups.value.find((x) =>
      x.label.includes(val.filter)
    );
    // Found the group, now check if its its a QID group or not, if it is we need to change the filterType from x to x_qid
    if (groupInFilters?.label.includes("_qid")) val.filter += "_qid";
    else val.value = val.label;
  }
  selectFilter(val);
  inputRef.value?.focus();
};

const addFilter = () => {
  console.log("adding filter");
  if (focusedFilter.value) {
    formatFilter(
      autoCompleteResults.value.find((f) => f.value == focusedFilter.value)
    );
    closeDropdown();
  } else if (inputQuery.value.length > 0) {
    const input = inputQuery.value;
    inputQuery.value = "";
    formatFilter({
      value: input,
      label: input,
      filter: "customQuery",
    });
    closeDropdown();
  }
};

/**
 * @description Function that receives a token (string) and some other string (phrase) folds/normalizes them
 * and checks if the token is included in the phrase somewhere
 */
const isTokenIncluded = (token, phrase) => {
  const foldedToken = ASCIIFolder.default.foldReplacing(token).toLowerCase();
  const foldedPhrase = ASCIIFolder.default.foldReplacing(phrase).toLowerCase();
  return foldedPhrase.includes(foldedToken);
};

const createViewTooltip = computed(() => {
  return {
    content: t("navigation.header.views_createView"),
    position: "bottom-left",
  };
});

const pinFilterTooltip = computed(() => {
  return {
    content: t(`components.filterPane.${pinState.value}`),
    position: "bottom-right",
  };
});

const aiDisclaimerTooltip = computed(() => {
  return {
    content: t("disclaimer.ai_entities"),
    position: "bottom-left",
  };
});
</script>

<style scoped lang="scss">
.m-filter {
  &__search {
    width: 100%;
    position: relative;
    align-self: flex-start;
    @include flex(flex-start, center, row);
  }

  &__box {
    width: 100%;
    min-height: 40px;
    padding: $spacing-1 $spacing-2 math.div($spacing-1, 2);
    border: 1px solid color($pri-light);
    @include round-corners($spacing-1);
    position: relative;
    @include flex(flex-start, center, row);

    color: color($black);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    vertical-align: middle;

    background-color: color($sec-50);

    :deep(.m-icon) {
      transform: translateY(math.div($spacing-1, -4));
    }

    input {
      width: $spacing-1;
      height: inherit;
      margin-left: $spacing-1;
      flex-grow: 1;
      font-size: 1rem;
      background-color: transparent;

      &::placeholder {
        color: color($pri-action-inactive);
        font-size: 95%;
        font-weight: 400;
        letter-spacing: 0.015em;
        line-height: 1.5rem;
      }
    }
  }

  &__content {
    @include flex(flex-start, center, row);
    flex-wrap: wrap;
    flex-grow: 1;
    cursor: text;

    div {
      @include flex(flex-start, center, row);
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding: math.div($spacing-1, 2) math.div($spacing-1, 2)
      math.div($spacing-1, 2) $spacing-1;
    margin-left: math.div($spacing-1, 2);
    margin-bottom: math.div($spacing-1, 2);
    border: 1px solid color($sec-300);
    @include round-corners($spacing-1);
    @include flex(flex-start, center, row);
    align-items: stretch;
    background-color: color($sec-100);
    cursor: default;

    * {
      cursor: default;
    }

    h6 {
      @include flex(flex-start, center, row);
      white-space: nowrap;
      cursor: pointer;
    }

    &--hover {
      border: 1px solid color($pri-action-inactive, 0.6);
      background-color: color($pri-action-inactive, 0.1);
    }

    &.contextual-filter {
      opacity: 0.5;
      .m-filter__queries {
        pointer-events: none;
      }
    }
  }

  &__queries {
    @include flex(center, center, row);
    align-items: stretch;

    * {
      cursor: default;
    }

    .m-filter__icon-remove {
      display: none;
    }

    h6.m-clickable {
      margin: $spacing-0 $spacing-2 $spacing-0 $spacing-1;
      padding-left: $spacing-1;
      @include flex(flex-start, center, row);

      &:hover {
        margin-right: $spacing-1;
        border: 1px solid color($pri-action-inactive, 0.4);

        .m-filter__icon-remove {
          margin-left: math.div($spacing-1, 2);
          display: flex;
        }
      }
    }

    .m-queries__divider {
      color: color($sec-400);
    }
  }

  &__operator {
    width: 100%;
    text-align: center;
    justify-content: center !important;
  }

  &__selectors {
    margin-top: $spacing-4;
    @include flex(center, center, row);

    .m-filter__facet {
      padding: $spacing-1 $spacing-1 $spacing-1 $spacing-2;
      margin: $spacing-0 $spacing-2;
      border: 1px solid color($pri-light);
      @include round-corners($spacing-1);

      @include flex(flex-start, center, row);

      color: color($black);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      vertical-align: middle;
      white-space: nowrap;

      @include opacity-inactive;
      cursor: pointer;

      * {
        cursor: pointer;
      }

      :deep(.m-icon) {
        margin-left: $spacing-3;
      }
    }
  }

  &__entry {
    width: 100%;
    padding: $spacing-3 $spacing-4 $spacing-7;
    border: 1px solid color($pri-light);
    @include round-corners($spacing-1);
    position: relative;

    &--add {
      min-height: 40px;
      margin-top: $spacing-1;
      border: 1px dashed color($pri-action-inactive, 0.6);
      @include round-corners($spacing-1);
      @include flex(center, center, row);
      background: none;
      @include opacity-inactive;
      cursor: pointer;

      * {
        cursor: pointer;
      }

      h6 {
        color: color($pri-action-inactive);
      }

      &:hover {
        border: 1px solid color($pri-action-light, 0.2);
        background-color: color($pri-action-light, 0.2);
        @include opacity-hover;

        h6 {
          color: color($pri-action);
        }
      }

      &:focus,
      &:active {
        border: 1px solid color($pri-action-light, 0.2);
        background-color: color($pri-action-light, 0.2);
        @include opacity-active;

        h6 {
          color: color($pri-action);
        }
      }
    }
  }

  &__query {
    padding: 2px $spacing-0; // $spacing-1 / 2
  }

  &__facet {
    padding: $spacing-2;
    border: 1px solid color($pri-light);
    @include round-corners($spacing-1);
    @include flex(space-between, center, row);
    background-color: color($sec-50);

    :deep(.m-search) {
      min-width: $column;
    }
  }

  &__dropdown {
    // transform: translateY(-($spacing-5));

    :deep(.m-dropdown__option) {
      @include flex(space-between, center, row);
    }

    :deep(.m-dropdown__option .m-option__label) {
      @include flex(space-between, center, row);
      gap: $spacing-1;

      h6 {
        transform: translateY(-($spacing-1));
      }
    }

    :deep(.m-dropdown__option:hover .m-filter__label),
    :deep(.m-dropdown__option:hover .m-filter__label *) {
      color: color($pri-action);
    }

    &--negate {
      :deep(.m-dropdown__option:hover) {
        background-color: color($error-light, 0.15);
      }

      :deep(.m-dropdown__option:hover .m-filter__label),
      :deep(.m-dropdown__option:hover .m-filter__label *) {
        color: color($error-dark);
      }
    }
    :deep(h6) {
      @include flex(flex-start, center, row);
    }
  }

  &__negate {
    padding: math.div($spacing-1, 2) $spacing-1;
  }

  &__pane {
    min-width: $toolbox-width;
    padding-right: $spacing-2;
    @include flex(flex-start, flex-start, row);
    position: fixed;
    top: 57px;
    right: $spacing-1;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    z-index: $z-navigation;

    &--pinned {
      transform: translateX(0);
    }

    &--editor {
      .m-filter-group {
        &.sortable-ghost {
          background-color: color($pri-action-light, 0.3);
          @include opacity-disabled;
        }

        &--enabled {
          cursor: pointer;

          * {
            cursor: pointer;
          }

          &:nth-child(2n) .m-filter-group__header {
            transform-origin: 50% 10%;
            animation: 0.4s wobble ease-in-out infinite;
          }

          &:nth-child(2n - 1) .m-filter-group__header {
            transform-origin: 30% 5%;
            animation: 0.4s jiggle ease-in-out infinite;
          }
        }

        @keyframes wobble {
          0% {
            transform: rotate(-0.5deg);
          }

          50% {
            transform: rotate(0.5deg);
          }
        }

        @keyframes jiggle {
          0% {
            transform: rotate(0.5deg);
          }

          50% {
            transform: rotate(-0.5deg);
          }
        }

        &--disabled {
          background-color: color($sec-100, 0.5);

          h5 {
            @include opacity-disabled;
          }
        }

        .m-toolbox--open {
          max-height: calc(100vh - $header-height - $spacing-3);
        }
      }
    }

    .m-filter-group {
      width: 100%;
      position: relative;
      @include flex(center, flex-start, column);

      :deep(.m-filter-group__header) {
        width: 100%;
        padding: $spacing-0 $spacing-1 $spacing-0 $spacing-3;
        margin-bottom: $spacing-2;
        @include flex(space-between, center, row);
      }
    }

    .m-filter__icon--toggle-state {
      display: none;
    }

    &:hover .m-filter__icon--toggle-state {
      display: flex;
    }

    .m-filter__icon-status {
      @include round-corners;
      background-color: color($pri-action-light, 0.2);
    }
  }

  &__mark {
    min-height: $header-height;
    padding: $spacing-2 $spacing-3 $spacing-2 $spacing-2;
    @include round-left-corners($spacing-2);
    border: 1px solid color($pri-light);
    @include flex(center, center, row);
    background-color: color($pri-action-light, 0.3);
    @include elevate-navigation;
    @include opacity-inactive;
    transform: translateX($spacing-1);
    cursor: pointer;

    &:hover {
      background-color: color($pri-action-light, 0.4);
      @include opacity-hover;
    }

    &:focus,
    &:active {
      background-color: color($pri-action-light, 0.4);
      @include elevate-button--raised;
      @include opacity-active;
    }
  }
}

.m-toolbox {
  @include round-corners($spacing-2);
  position: relative;
  transform: unset;
  overflow: hidden;
  box-shadow: unset;

  &--open {
    max-height: calc(100vh - $header-height - $spacing-6);
    background-color: color($white, 0.65);
    backdrop-filter: saturate(2) blur($spacing-4);
    @include elevate-card;
    transition: max-height 0.5s ease-in-out;
  }

  &--closed {
    max-height: $spacing-9;
    @include round-corners($spacing-1);
    background-color: color($white);
    @include elevate-card;
    transition: max-height 0.5s ease-in-out;
  }

  &--pinned {
    max-height: calc(100vh - 64px);
    @include elevate-navigation;
  }

  &__heading {
    height: $spacing-9;
    padding: $spacing-0 $spacing-1;
    @include flex(space-between, center, row);

    > div {
      @include flex(flex-start, center, row);
    }
  }

  &__modules {
    &.p-absolute {
      width: 100%;
      top: 41px;
      left: 50%;
      transform: translateX(-50%);
    }

    :deep(.m-loading) {
      background-color: color($white, 0.6);
      backdrop-filter: blur(math.div($spacing-1, 2));
    }
  }

  .m-filter-pane__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

@include mq(desktopXS) {
  .m-filter {
    &__pane .m-toolbox {
      &--closed {
        max-height: $spacing-8;
      }

      &__heading {
        height: $spacing-8;
      }
    }
  }
}

@include mq(desktopS) {
  .m-filter {
    &__pane--unpinned {
      .m-toolbox--open {
        max-height: calc(100vh - $header-height - $spacing-3);
      }
    }
  }
}

@include mq(desktopM) {
  .m-filter {
    &__pane--unpinned {
      .m-toolbox--open {
        max-height: calc(100vh - $header-height - $spacing-3);
      }
    }
  }
}
</style>
