<template>
  <div class="m-article-page-side">
    <div class="m-article-page-side__actions mb-4">
      <m-button
        id="m_article_read"
        type="text"
        variant="primary"
        :loading="article.isRead == undefined"
        size="default"
        :leadingIcon="readStatus"
        :tooltip="fromSharedViewTooltip"
        :disabled="fromSharedView"
        class="m-article-side__read"
        @click="toggleRead"
      />
      <m-button
        id="m_article_save"
        type="text"
        variant="primary"
        :loading="article.isSaved == undefined"
        size="default"
        :leadingIcon="saveStatus"
        :tooltip="bookmarkArticleTooltip"
        :disabled="fromSharedView"
        class="m-article-side__save"
        @click="
          article.isSaved
            ? unSaveArticle(article, emit)
            : saveArticle(article, emit)
        "
      />
      <m-button
        id="m_article_tag"
        type="text"
        variant="primary"
        size="default"
        leadingIcon="tag"
        :tooltip="{
          position: 'dynamic',
          content: t('article.side_button_tag'),
        }"
        :disabled="fromSharedView"
        ref="tagButtonRef"
        class="m-article-side__tag"
        v-click-outside="closeDropdown"
        @click="() => (openDropdown = true)"
      />
      <m-dropdown
        id="m_stag"
        labelledBy="`m_tag_label`"
        :visible="openDropdown"
        :options="tagOptions"
        size="small"
        :parent="tagButtonRef?.htmlElement"
        :search="false"
        :floating="true"
        @update:selected="selectTagOption"
      >
      </m-dropdown>
    </div>
    <m-button
      v-if="canTranslate"
      id="m_article_toggle_lang"
      :label="translateLabel"
      leadingIcon="translate"
      type="text"
      variant="primary"
      size="small"
      class="mb-7 m-article-side__translate"
      @click="toggleSelectedLang(availableLangs.find((x) => x != selectedLang))"
    />
    <div v-if="article.metadata?.resources?.length">
      <h4 class="mb-1 type--small">{{ t("article.side_resources") }}</h4>
      <div class="m-article-page-side__entities">
        <ul class="m-entities__wrapper">
          <a :href="resource.url" target="_blank" class="ref">
            <li
              v-for="(resource, i) in article.metadata.resources"
              :key="i"
              :id="`m_article_page_side_entity_${i}`"
              class="m-article-page-side__entity m-clickable"
            >
              Resource nº{{ i + 1 }} (pdf)
            </li>
          </a>
        </ul>
      </div>
    </div>
    <div
      v-if="
        iptcs.length &&
        documentProperties.some((f) => f.searchKey == 'IPTC' && f.selected)
      "
    >
      <h4 class="mb-1 type--small">{{ t("article.side_topics") }}</h4>
      <div class="m-article-page-side__iptcs">
        <m-iptcs class="ma-2 m-iptcs__wrapper" :iptcs="iptcs" />
      </div>
    </div>

    <div
      v-if="
        entityWikidata?.length &&
        documentProperties.some((x) => x.searchKey.includes('_qid'))
      "
    >
      <h4 class="mb-1 type--small">{{ t("article.side_entities") }}</h4>

      <div class="m-article-page-side__entities">
        <ul class="m-entities__wrapper">
          <li
            v-for="(entity, i) in entityWikidata"
            :key="i"
            :id="`m_article_page_side_entity_${i}`"
            class="m-article-page-side__entity"
            :class="{
              'm-clickable':
                articlesIsVisible && article.documentLang == selectedLang,
            }"
            :data-tooltip-content="findInTextLabel(entity)"
            data-tooltip-position="dynamic"
            @click="findInText(entity)"
          >
            <m-filter-badge :id="`badge_${i}`" :filter="entity" />
            <m-icon
              v-if="articlesIsVisible && article.documentLang == selectedLang"
              icon="find-in-text"
              status="active"
              :tooltip="entityFindInTextTooltip(entity)"
              @click="findInText(entity)"
            />
            <m-icon
              v-if="articlesIsVisible && article.documentLang == selectedLang"
              icon="search"
              status="active"
              :tooltip="entitySearchTooltip(entity)"
              @click="searchInMonitio(entity)"
            />
          </li>
        </ul>
        <!-- <m-button
        id="m_article_see_entities"
        :label="t('article.side_button_seeAllEntities')"
        type="text"
        variant="primary"
        size="xsmall"
      /> -->
      </div>
    </div>
    <div v-for="(scope, key) in mainTextDetectedTypedScopes" :key="key">
      <!-- Filter out entities ass they are a special thing and have their own place already in entityWikidata -->
      <template v-if="key != 'entities'">
        <h4 class="mb-1 type--small">
          {{ t(`general.facets.${key}`) }}
        </h4>
        <div class="m-article-page-side__typed-scopes">
          <ul class="m-typed-scopes__wrapper">
            <li
              v-for="(entity, i) in scope"
              :key="i"
              :id="`m_article_page_side_typed_scopes_${i}`"
              class="m-article-page-side__entity"
              :class="{ 'm-clickable': article.documentLang == selectedLang }"
              :data-tooltip-content="findInTextLabel(entity)"
              data-tooltip-position="dynamic"
              @click="findInText(entity)"
            >
              <m-filter-badge :id="`badge_${i}`" :filter="entity" />
              <m-icon
                v-if="article.documentLang == selectedLang"
                icon="find-in-text"
                status="active"
                :tooltip="entityFindInTextTooltip(entity)"
                @click="findInText(entity)"
              />
            </li>
          </ul>
        </div>
      </template>
    </div>
    <div v-for="scope in sourceItemtypedScopes" :key="scope.key">
      <h4 class="mb-1 type--small">
        {{
          te(`general.facets.${scope.key}`)
            ? t(`general.facets.${scope.key}`)
            : scope.key
        }}
      </h4>
      <div class="m-article-page-side__typed-scopes">
        <ul class="m-typed-scopes__wrapper">
          <li v-for="value in scope.values" :key="value">
            <h5 class="my-1 ml-1 type--small">
              {{ value }}
            </h5>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="tabs.length != 0" :class="`m-article-page-side__${activeTab}`">
      <div class="m-article-page-side__tabs">
        <div
          v-for="(tab, i) in tabs"
          :key="i"
          class="m-tabs__tab m-clickable"
          :class="{
            'm-tabs__tab--active': activeTab == tab.value,
          }"
          @click="changeTab(tab.value)"
        >
          <component
            :is="activeTab == tab.value ? 'h5' : 'h6'"
            class="type--small"
          >
            {{ tab.label }}
          </component>
        </div>
      </div>
      <div :class="`m-article-page-side__${activeTab}-list`">
        <div v-if="sideArticles" :class="`m-${activeTab}__wrapper`">
          <m-button
            v-if="cluster?.totalNumDocuments && activeTab == 'cluster'"
            id="m_article_goto_cluster"
            :label="
              t('article.goToCluster', {
                numOfArticles: cluster?.totalNumDocuments,
              })
            "
            type="text"
            variant="primary"
            size="xsmall"
            @click="gotoClusterPage"
          />
          <div class="m-articles__wrapper" :class="`m-${activeTab}__content`">
            <m-article
              v-for="(sideArticle, i) in sideArticles"
              :key="i"
              :article="sideArticle"
              :actions="[]"
            />
          </div>
        </div>
        <m-loading
          v-else-if="loadingDuplicates || loadingCluster"
          size="xsmall"
          overlay
          class="py-7"
        />
      </div>
    </div>
    <m-find v-if="showFind" :value="findValue" @close="showFind = false" />
  </div>
</template>

<script setup>
import { computed, ref, unref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";

import { useRoute, useRouter } from "vue-router";
import { useApi } from "@api/api";
import { getIPTCCode } from "@utils/utils";
import TimeFrame from "@utils/enums/timeFrames";
import useTranslator from "@hooks/useTranslator";
import useArticle from "@hooks/useArticle";
import MButton from "@components/MButton.vue";
import MDropdown from "@components/MDropdown.vue";
import MFind from "@components/MFind.vue";
import MFilterBadge from "@components/MFilterBadge.vue";
import MIcon from "@components/MIcon.vue";
import useDropdown from "@hooks/useDropdown";
import MIptcs from "@components/MIptcs.vue";
import MArticle from "@components/MArticle.vue";
import MLoading from "@components/MLoading.vue";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useViewsStore } from "@root/store/modules/views";
import { useClustersStore } from "@root/store/modules/clusters";
import useCreateSmartTagModal from "@components/modals/MCreateSmartTag/useCreateSmartTagModal";
import { isQID } from "@root/utils/isQID";
import {
  navigateWithQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";

const props = defineProps({
  article: { type: Object, required: true },
  entityWikidata: { type: Array, required: true },
  selectedLang: { type: String, required: false },
  availableLangs: { type: Array, required: false },
  mainTextDetectedTypedScopes: { type: Object, required: false },
});

const emit = defineEmits([
  "read-article",
  "unread-article",
  "toggle-selected-lang",
  "update:article",
  "open-article",
]);

const { t, te } = useI18n();
const workspacesStore = useWorkspacesStore();
const clustersStore = useClustersStore();
const filtersStore = useFiltersStore();
const alertsStore = useAlertsStore();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();

const { api } = useApi();
const { localizeLanguageCode } = useTranslator();
const { openDropdown, keepDropdownOpen, closeDropdown } = useDropdown();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const workspaceId = ref(workspaceConfig.value.id);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);
const userLocale = computed(() => userStore.i18nLanguage);
const userLang = computed(() => userLocale.value.split("-")[0]);
const view = computed(() => viewsStore.getViewById(viewId.value));
const { saveArticle, unSaveArticle } = useArticle(viewId);
const { open: openCreateTag, close: closeCreateTag } = useCreateSmartTagModal();

const articlesIsVisible = computed(() => {
  const vis = props.article?.metadata?.compliance?.visibility;
  return vis != "expired" && vis != "restricted" && vis != "embargoed";
});

const fromSharedView = computed(() => {
  return view.value.isShared && !view.value.isBaseView;
});

const fromSharedViewTooltip = computed(() => {
  if (fromSharedView.value) {
    return { content: t("article.sharedViewError"), position: "dynamic" };
  } else {
    if (readStatus.value) {
      return { content: t("article.side_button_unread"), position: "dynamic" };
    } else {
      return { content: t("article.side_button_read"), position: "dynamic" };
    }
  }
});

const bookmarkArticleTooltip = computed(() => {
  if (fromSharedView.value)
    return { content: t("article.sharedViewError"), position: "dynamic" };
  if (props.article.isSaved)
    return {
      content: t("article.side_button_saved"),
      position: "dynamic",
    };
  else
    return {
      content: t("article.side_button_saved-filled"),
      position: "dynamic",
    };
});

const canTranslate = computed(() => {
  return props.availableLangs?.length > 1;
});

const translateLabel = computed(() => {
  const lang = props.availableLangs.filter((x) => x != props.selectedLang)[0];
  if (props.article.documentLang == props.selectedLang) {
    return t("article.side_button_translate", {
      lang: localizeLanguageCode(lang),
    });
  } else {
    return t("article.side_button_original", {
      lang: localizeLanguageCode(lang),
    });
  }
});

/** @description Document properties accoring to the user preferences/defaults from backend-organization-workspace */
const documentProperties = computed(
  () =>
    userStore.config.propertyTypeSettings.document[workspacesStore.id]
      ?.filter((x) => x.active && x.selected)
      ?.sort((a, b) => a.rank - b.rank) ?? []
);

const sourceItemtypedScopes = computed(() => {
  const propertyTypeSettingsKeys =
    userStore.config?.propertyTypeSettings?.facets?.[workspaceId.value]
      ?.filter((x) => x.active)
      .map((x) => x.searchKey);

  const excludeFilter = window._env_.SCOPES_BLACKLIST;

  if (!props.article.metadata?.sourceItemsourceItem) return [];
  else {
    return (
      Object.keys(props.article.metadata?.sourceItemTypedScopes)
        .filter((key) => !excludeFilter.includes(key.toLowerCase()))
        /*       .filter((key) =>
        propertyTypeSettingsKeys.some((s) =>
          s.toLowerCase().includes(key.toLowerCase())
        )
      ) */
        .map((key) => {
          return {
            key:
              propertyTypeSettingsKeys.find((x) =>
                x.split("/").at(-1).toLowerCase().includes(key.toLowerCase())
              ) ?? key,
            values: props.article.metadata?.sourceItemTypedScopes[key],
          };
        })
    );
  }
});

onMounted(() => {
  getDuplicates();
  if (props.article.metadata?.crossClusterId) getClusterData();
});

const readStatus = computed(() => (props.article.isRead ? "unread" : "read"));
const saveStatus = computed(() =>
  props.article.isSaved ? "saved-filled" : "saved"
);

// (Object.keys(props.article).length === 0 || props.entityWikidata.length === 0)

const toggleRead = () => {
  if (props.article.isRead) {
    emit("unread-article");
  } else {
    emit("read-article");
  }
};

const toggleSelectedLang = (lang) => {
  emit("toggle-selected-lang", lang);
};

//const isEmptyTags = Object.keys(tags).length === 0;
const iptcs = computed(() => {
  const iptcLabels = props.article.translations["en"]?.topics;
  const IPTCtranslations = props.article.translations[
    userLang.value
  ]?.topics?.reduce((acc, crr, idx) => {
    acc[iptcLabels[idx]] = crr;
    return acc;
  }, {});

  const iptcs = [];

  if (iptcLabels) {
    const iptcCodes = iptcLabels
      .map((enIptcLabel) => {
        return {
          label: IPTCtranslations[enIptcLabel] ?? enIptcLabel,
          sourceLabel: enIptcLabel,
          code: getIPTCCode(enIptcLabel),
        };
      })
      .sort((a, b) => {
        return a.code.level > b.code.level;
      });

    iptcCodes.forEach((fe) => {
      switch (fe.code?.level) {
        case 0:
          iptcs.push({
            level: fe.code.l,
            level0: fe.code.l0,
            label: fe.label,
            sourceLabel: fe.sourceLabel,
            children: [],
          });
          break;
        case 1:
          iptcs
            .find((f) => f.level0 == fe.code.l0)
            ?.children?.push({
              level: fe.code.l,
              level0: fe.code.l0,
              level1: fe.code.l1,
              label: fe.label,
              sourceLabel: fe.sourceLabel,
              children: [],
            });
          break;
        case 2:
          iptcs
            .find((f) => f.level0 == fe.code.l0)
            ?.children?.find((f) => f.level1 == fe.code.l1)
            ?.children?.push({
              level: fe.code.l,
              level0: fe.code.l0,
              level1: fe.code.l1,
              level2: fe.code.l2,
              label: fe.label,
              sourceLabel: fe.sourceLabel,
            });
          break;
        default:
          break;
      }
    });
  }
  /** Filter out the properties that dont contain any tags */
  return iptcs;
});

const tagButtonRef = ref(null);
const tagOptions = computed(() => {
  const hasTagTypes = !!userStore.smartTagTypes?.length;
  const hasTags = !!userStore.smartTagTypes?.flatMap((x) => x.smartTags)
    ?.length;

  let tagTypesOpts = [{ value: "new-tag-type", label: "New Category" }];
  if (hasTagTypes) {
    tagTypesOpts = userStore.smartTagTypes
      .map((x) => ({ value: x.id, label: x.name }))
      ?.concat({ value: "divider" })
      .concat(tagTypesOpts);
  }

  return [
    {
      value: "create-tag",
      label: "Create smart tag",
      options: tagTypesOpts,
    },
    {
      value: "add-to-tag",
      label: "Add to smart tag",
      disabled: !hasTags,
      options: [
        ...userStore.smartTagTypes
          .flatMap((x) => x.smartTags)
          .map((x) => ({ value: x.id, label: x.name })),
      ],
    },
  ];
});

const selectTagOption = async (...val) => {
  switch (val[0].value) {
    case "create-tag":
      switch (val[1].value) {
        case "new-tag-type":
          openCreateTag(props.article);
          break;
        default:
          openCreateTag(props.article, val[1]);
          break;
      }
      break;
    case "add-to-tag":
      try {
        const result = await api.tags.addDocumenttoTag(
          props.article,
          true,
          val[1].value
        );
        if (result.status !== 200) throw new Error();
        alertsStore.add({
          type: "toast",
          variant: "success",
          message: `Document was successfully added yo smart tag: ${val[1].label}`,
        });
      } catch (error) {
        alertsStore.add({
          type: "toast",
          variant: "error",
          message:
            "Somethng went wrong while adding document to the smart tag. Please try again later.",
        });
      }
      //openAddToTag(props.article, val[1].value);
      break;
  }
  setTimeout(() => {
    closeDropdown();
  }, 1);
};

const loadingDuplicates = ref(true);
const duplicates = ref(null);
const getDuplicates = async () => {
  loadingSideArticles.value = true;
  const nearDuplicateId = props.article.metadata?.nearDuplicateId;
  if (!nearDuplicateId) {
    loadingDuplicates.value = false;
    return;
  }
  const data = await api.search.getDuplicates(
    viewId.value,
    props.article.id,
    nearDuplicateId
  );

  if (!data.data?.result?.documents?.length) {
    loadingDuplicates.value = false;
    return;
  }

  duplicates.value = data.data.result.documents;
  loadingDuplicates.value = false;
};

const clusterId = computed(() => props.article.metadata?.crossClusterId);
const cluster = ref(null);
const loadingCluster = ref(null);
const getClusterData = async () => {
  if (!clusterId.value) return;
  loadingSideArticles.value = true;
  const clusterDateRestriction = {
    isRelative: true,
    timeFrame: TimeFrame.All.value,
  };

  const response = await api.search.cluster(
    unref(viewId),
    unref(clusterId),
    clusterDateRestriction,
    filtersStore.aggregator,
    filtersStore.sortBy,
    0,
    19
  );

  if (response.status != 200) {
    loadingCluster.value = false;
    return;
  }

  cluster.value = {
    data: response.data.cluster,
    documents: response.data.result?.documents,
    totalNumDocuments: response.data.result?.numDocuments ?? 0,
    feeds: response.data.properties?.filter(
      (x) => x.type == "sourceItemFeedNames"
    ),
  };
  loadingCluster.value = false;
};

const gotoClusterPage = () => {
  /** @type {import("@root/types").Monitio.URLQueryObject} */
  const query = { ...filtersStore.queryObject };
  query.contextualFilters = [
    {
      value: "crossClusterId",
      query: [
        {
          label: clustersStore.getClusterNameById(clusterId.value),
          value: clusterId.value,
          propertyType: "crossClusterId",
        },
      ],
    },
  ];
  query.dateRestriction = {
    isRelative: true,
    timeFrame: TimeFrame.All.value,
  };
  navigateWithQueryObject(
    route,
    router,
    query,
    "tiles-cluster",

    {
      viewId: viewId.value,
      clusterId: clusterId.value,
    },
    { from: "article" }
  );
};

const showFind = ref();
const findValue = ref();
const findInTextLabel = (entity) => {
  if (
    articlesIsVisible.value &&
    props.article.documentLang == props.selectedLang
  ) {
    return t("article.side_entity_findInText", {
      find: entity.label ?? entity.form ?? entity.baseForm,
    });
  } else return null;
};
const findInText = (entity) => {
  if (
    articlesIsVisible.value &&
    props.article.documentLang == props.selectedLang
  ) {
    emit("open-article");
    findValue.value = entity;
    showFind.value = true;
  }
};
const searchInMonitio = (entity) => {
  const type = entity.isNIL
    ? "customQuery"
    : isQID(entity.id)
    ? entity.type + "_qid"
    : entity.type;
  const label = entity.label;
  const value = entity.isNIL ? entity.label : entity.id ?? entity.label;
  /** @type {import("@root/types").Monitio.URLQueryObject]} */
  const queryObj = {
    filters: [
      {
        facets: [
          {
            value: type,
            query: [
              {
                label: label,
                value: value,
                propertyType: type,
                negated: false,
              },
            ],
          },
        ],
      },
    ],
  };
  navigateWithQueryObject(route, router, queryObj, "articles", {
    viewId: viewId.value,
  });
};
const loadingSideArticles = ref(false);
const activeTab = ref("");
const tabs = computed(() => {
  const arr = [];
  if (duplicates.value?.length > 0) arr.push("duplicates");
  if (cluster.value?.totalNumDocuments > 1) arr.push("cluster");
  return arr?.map((m) => ({ label: t(`article.side_${m}`), value: m }));
});

watch(
  () => tabs.value,
  (val) => {
    if (activeTab.value == "" && val?.length == 1) {
      activeTab.value = val[0].value;
    }
  },
  { immediate: true }
);

// watch(
//   () => [activeTab.value, tabs.value],
//   ([val, tabs]) => {
//     // If there's no duplicates automaticly toggle the cluster tab
//     if (
//       tabs?.length == 1 &&
//       tabs?.find((x) => x.value == "cluster") &&
//       activeTab.value != "cluster"
//     ) {
//       activeTab.value = "cluster";
//     }
//     if (val == "cluster" && clusterId.value) getClusterData();
//   },
//   { immediate: true }
// );

const sideArticles = computed(() => {
  if (activeTab.value == "duplicates")
    return duplicates.value.filter((x) => x.id != props.article.id);
  if (activeTab.value == "cluster") return cluster.value?.documents;
  return null;
});

const changeTab = (tab) => {
  activeTab.value = tab;
};

const entityFindInTextTooltip = (entity) => {
  return {
    content: t("article.side_entity_findInText", {
      find: entity.label ?? entity.form ?? entity.baseForm,
    }),
    position: "dynamic",
  };
};

const entitySearchTooltip = (entity) => {
  return {
    content: t("article.side_entity_search", {
      find: entity.label ?? entity.form ?? entity.baseForm,
    }),
    position: "dynamic",
  };
};
</script>

<style scoped lang="scss">
.m-article-page-side {
  &__actions {
    @include flex(center, center, row);
    gap: $spacing-3;
  }

  .m-article-side {
    &__read,
    &__save,
    &__tag {
      width: auto;
      padding: 7px 8px;
    }

    &__translate {
      width: 100%;
    }
  }

  .m-articles__wrapper {
    @include flex(stretch, flex-start, column);
    gap: $spacing-3;

    .m-article {
      width: 100%;

      &:hover :deep(.m-article__container) {
        position: relative;
      }
    }
  }
}

// .m-article-side__read {
//   &.m-button--contained--primary {
//     border: 1px solid color($red);
//     background-color: color($red);
//   }
// }
// .m-article-side__save {
//   &.m-button--contained--primary {
//     border: 1px solid color($gold);
//     background-color: color($gold);

//     :deep(.m-icon--active *),
//     :deep(h5) {
//       color: color($black);
//       fill: color($black);
//     }
//   }
// }
</style>
