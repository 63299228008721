"use strict";
import axios from "axios";
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  QueueItem,
  ICancel,
  ApiModules,
  IApiModule,
  IApiQueue,
} from "./types";

const CancelToken = axios.CancelToken;
const DEFAULTNAME = "default";

export class Cancel implements ICancel {
  message: string;
  request: Request | string;

  constructor(request: Request | string, message: string) {
    this.message = message;
    this.request = request;
  }
}

function removeRequest(
  hpm: Map<number, any>,
  lpm: Map<number, any>,
  queue: any[],
  { _queueid }: { _queueid: number }
) {
  if (hpm.delete(_queueid) || lpm.delete(_queueid)) return;

  const idx = queue.findIndex((f) => f.id === _queueid);
  if (idx >= 0) {
    queue.splice(idx, 1);
    return;
  }

  console.warn(
    `Request with queueid ${_queueid} is not in queue! That should not happen`
  );
}

function createAxiosRequest(this: ApiQueue, queueItem: QueueItem) {
  const {
    call,
    args,
    id,
    notify,
    res,
    rej,
    source: { token },
  } = queueItem;
  const {
    _queue,
    _hpm,
    _lpm,
    _axios,
    _notifyCB,
    _notifyCount,
    _cancelSet,
    _queuesize,
    _ispaused,
  } = this;

  // eslint-disable-next-line prefer-spread
  const request = call.apply(null, args);
  request._queueid = id;

  if (!request.cancelToken) {
    // If a cancelToken is already been set it is the caller
    // responsability to cancel the request
    request.cancelToken = token;
    _cancelSet.add(queueItem);
  }

  _axios
    .request(request)
    .then(
      function (response: AxiosResponse) {
        removeRequest(_hpm, _lpm, _queue, request);
        res(response);
      },
      function (error: Error) {
        removeRequest(_hpm, _lpm, _queue, request);
        if (axios.isCancel(error)) rej(new Cancel(request, error.message));
        else rej(error);
      }
    )
    .finally(() => {
      _cancelSet.delete(queueItem);
      if (_notifyCount.has(notify)) {
        const notifyCount = _notifyCount.get(notify) - 1;
        if (notifyCount === 0) {
          _notifyCount.delete(notify);
          if (_notifyCB.has(notify))
            _notifyCB.get(notify)?.forEach((f) => f(false));
          if (_notifyCB.has("$all"))
            _notifyCB.get("$all")?.forEach((f) => f(false, notify));
        } else {
          _notifyCount.set(notify, notifyCount);
        }
      } else {
        console.warn("No entry on notify. This should not happen.");
      }

      if (
        !_ispaused &&
        _hpm.size + _lpm.size < _queuesize &&
        _queue.length > 0
      ) {
        const newRequest = _queue.shift();
        if (newRequest) {
          _lpm.set(newRequest.id, newRequest);
          createAxiosRequest.call(this, newRequest);
        }
      }
    });
}

function queueRequest(
  this: ApiQueue,
  prio: number,
  notify: string,
  modulename: string,
  name: string,
  call: any
) {
  const {
    _queue,
    _hpm,
    _lpm,
    _getqueueid,
    _notifyCB,
    _notifyCount,
    _queuesize,
    _ispaused,
  } = this;
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const _this = this;
  const fn = function (
    ...args: [viewId: string, mode: string, params: object]
  ) {
    const source = CancelToken.source();
    const promise = new Promise((res, rej) => {
      const queueItem: QueueItem = {
        call,
        args,
        prio,
        notify,
        modulename,
        name,
        id: _getqueueid(),
        res,
        rej,
        source,
      };

      const notifyCount = (_notifyCount.get(notify) || 0) + 1;
      _notifyCount.set(notify, notifyCount);

      if (_notifyCount.get(notify) === 1) {
        if (_notifyCB.has(notify))
          _notifyCB.get(notify)?.forEach((f) => f(true));
        if (_notifyCB.has("$all"))
          _notifyCB.get("$all")?.forEach((f) => f(true, notify));
      }

      if (prio === 0) _hpm.set(queueItem.id, queueItem);
      else {
        // insert into list ordered by priority
        const idx = _queue.findIndex((f) => f.prio > prio);
        if (idx < 0) _queue.push(queueItem);
        else _queue.splice(idx, 0, queueItem);
      }

      // if the request has priority 0 o if there are no other
      // requests the call is made imediatly
      if (!_ispaused) {
        if (prio == 0) {
          createAxiosRequest.call(_this, queueItem);
        } else if (_hpm.size + _lpm.size < _queuesize) {
          const item = _queue.shift();
          if (item) {
            _lpm.set(item.id, item);
            createAxiosRequest.call(_this, item);
          }
        }
      }
    });

    Object.defineProperty(promise, "cancel", {
      enumerable: false,
      get: () => source.cancel,
    });

    return promise;
  };

  Object.defineProperty(fn, "config", {
    enumerable: false,
    get: function () {
      return call;
    },
  });

  return fn;
}

function cancelRequests(
  set: Set<QueueItem>,
  queue: QueueItem[],
  message: string
) {
  set.forEach((item) => {
    item.source.cancel(message);
  });

  queue.forEach((item) => {
    item.rej(new Cancel("none", message));
  });
}

function cancelByProperty(
  this: ApiQueue,
  prop:
    | "args"
    | "call"
    | "id"
    | "modulename"
    | "name"
    | "notify"
    | "prio"
    | "source",
  propvalue: string,
  message: string
) {
  const set = new Set(
    [...this._cancelSet.values()].filter((f) => f[prop] === propvalue)
  );
  const temp = this._queue;
  this._queue = [];
  const queue: QueueItem[] = [];
  temp.forEach((f) => {
    if (f[prop] === propvalue) queue.push(f);
    else this._queue.push(f);
  });
  cancelRequests(set, queue, message);
}

/*
 * Transforms axios instance methods to a request config object
 * This way we can add custom config properties to keep track
 * of notify and priority queue
 */
const axiosHandler = {
  get: function (target: object, prop: PropertyKey) {
    switch (prop) {
      case "get":
      case "delete":
      case "head":
      case "options":
        return (url: any, cfg: any) => ({ url, method: prop, ...cfg });
      case "post":
      case "put":
      case "path":
        return (url: any, data: any, cfg: any) => ({
          url,
          method: prop,
          data,
          ...cfg,
        });
      default:
        return Reflect.get(target, prop);
    }
  },
};

/*
 * Attach prio and notify to the api request
 * this way we can postpone the request and use the queue
 */
const moduleHandler = {
  get: function (
    params: {
      _instance: ApiQueue;
      _prio: number;
      _notify: string;
      _modulename: string;
      _name: string;
    },
    fn_name: string
  ) {
    // eslint-disable-next-line prefer-rest-params
    const call = Reflect.get(params, fn_name);
    if (typeof call === "function") {
      return queueRequest.call(
        params._instance,
        params._prio,
        params._notify,
        params._modulename,
        params._name,
        call
      );
    } else return call;
  },
};

class ApiQueue implements IApiQueue {
  _queuesize: number;
  _axios: AxiosInstance;
  _getqueueid: () => number;
  _getrequestid: () => number;
  _hpm: Map<any, any>;
  _lpm: Map<any, any>;
  _queue: QueueItem[];
  _modules: ApiModules;
  _cancelSet: Set<QueueItem>;
  _notifyCB: Map<string, Array<(...args: any) => any>>;
  _notifyCount: Map<any, any>;
  _axiosProxy: any;
  _ispaused: boolean;

  get queueSize() {
    return this._queuesize;
  }

  set queueSize(val: number) {
    if (!Number.isInteger(val)) {
      console.warn(`queueSize value is not an integer ${val}`);
      return;
    }

    if (val < 0) {
      console.warn(`queueSize value less than zero ${val}`);
      return;
    }
    this._queuesize = val;
  }

  get interceptors() {
    return this._axios.interceptors;
  }

  get defaults() {
    return this._axios.defaults;
  }

  constructor(config: AxiosRequestConfig<any>) {
    if (!config) console.warn("API config not set");
    let queueid = 0;
    this._queuesize = 5;
    this._getqueueid = () => queueid++;

    let requestid = 0;
    this._getrequestid = () => requestid++;

    this._hpm = new Map(); // just to keep track if there are high priority requests running
    this._lpm = new Map(); // current low priority requests
    this._queue = [];
    this._modules = {};
    this._cancelSet = new Set(); // so that we can cancel all requests

    this._notifyCB = new Map();
    this._notifyCount = new Map();

    this._axios = axios.create(config);

    this._axiosProxy = new Proxy(this._axios, axiosHandler);

    this._ispaused = false;
  }

  $on(notify: string, cb: (...args: any) => any) {
    if (!this._notifyCB.has(notify)) this._notifyCB.set(notify, []);
    const list = this._notifyCB.get(notify);
    if (list?.indexOf(cb) && list?.indexOf(cb) < 0) list?.push(cb);

    // WHY???? - will comment
    //if (notify !== "$all") this.$on("$all", cb);
  }

  $off(notify: string, cb: (...args: any) => any) {
    if (this._notifyCB.has(notify)) {
      const list = this._notifyCB.get(notify);
      const idx = list?.indexOf(cb) ? list?.indexOf(cb) : -1;
      if (idx >= 0) list?.splice(idx, 1);

      if (list?.length == 0) this._notifyCB.delete(notify);

      // WHY???? - will comment
      //if (notify !== "$all") this.$off("$all", cb);
    }
  }

  registModule(name: keyof ApiModules, module: (arg: AxiosInstance) => any) {
    const m = module(this._axiosProxy);
    this._modules[name] = m;
  }

  request(_prio: number, _notify: string, _name = DEFAULTNAME): ApiModules {
    function cancel(this: ApiQueue, message: string) {
      return this.cancelNamed(_name, message);
    }

    const modules: { [key: PropertyKey]: any } = {
      $on: this.$on.bind(this),
      $off: this.$off.bind(this),
      cancel: cancel.bind(this),
      cancelAll: this.cancelAll.bind(this),
      cancelNamed: this.cancelNamed.bind(this),
      cancelModule: this.cancelModule.bind(this),
    };

    Object.defineProperty(modules, "_rid", {
      enumerable: false,
      value: this._getrequestid(),
      writable: false,
    });

    Object.keys(this._modules).forEach((m) => {
      const module = {
        ...this._modules[m],
        _prio,
        _notify,
        _instance: this,
        _modulename: m,
        _name,
      };
      modules[m] = new Proxy(module, moduleHandler);
    });

    return modules;
  }

  pause() {
    this._ispaused = true;
  }

  resume() {
    // requires testing
    const { _hpm, _lpm, _queue, _queuesize } = this;
    this._ispaused = false;
    _hpm.forEach((r) => {
      createAxiosRequest.call(this, r);
    });

    if (_hpm.size + _lpm.size < _queuesize && _queue.length > 0) {
      const newRequest = _queue.shift();
      _lpm.set(newRequest?.id, newRequest);
      if (newRequest) createAxiosRequest.call(this, newRequest);
    }
  }

  cancelAll(message: string) {
    const queue = this._queue;
    this._queue = [];
    cancelRequests(this._cancelSet, queue, message);
  }

  cancelNamed(name: string, message: string) {
    cancelByProperty.call(this, "name", name, message);
  }
  cancelModule(modulename: string, message: string) {
    cancelByProperty.call(this, "modulename", modulename, message);
  }
}

export const create = (config: AxiosRequestConfig) => new ApiQueue(config);

export function anonymousDecorator(fn: any) {
  fn._anonymous = true;
  return fn;
}
