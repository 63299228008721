<template>
  <div class="m-ent-rel">
    <div class="m-ent-rel__background">
      <div
        v-for="(item, i) in granularCorporaFrequency"
        :key="item.date"
        :class="{
          colored: diffInDateResctrition && i + 1 > diffInDateResctrition,
        }"
      ></div>
    </div>
    <div ref="rowContainer" class="m-ent-rel__timeline">
      <div class="m-ent-rel__mask"></div>
      <div
        ref="columnContainer"
        class="m-ent-rel__dates"
        :class="{ unique: granularCorporaFrequency.length == 1 }"
      >
        <h6 class="type--small" v-for="date in trimmedDates" :key="date.date">
          {{
            DateTime.fromISO(date.date, { zone: "utc" })
              .toJSDate()
              .toLocaleDateString(userLocale, {
                ...DateTime.DATE_SHORT,
                timeZone: userTimezone,
              })
          }}
        </h6>
      </div>
    </div>
    <div ref="box">
      <div
        v-for="(entity, idx) in trendingEntities"
        :key="idx"
        class="m-ent-rel__row"
      >
        <h5
          :id="`m_ent_rel_${entity.key}`"
          data-tooltip-clickable="true"
          :data-tooltip-template="getTooltipTemplate(entity)"
          :data-tooltip-content="JSON.stringify(getTooltipContent(entity))"
          data-tooltip-position="right"
          class="m-ent-rel__key"
          @mouseenter="hoveringOnElementId = entity.key"
          @mouseleave="
            hoveringOnElementId =
              hoveringOnElementId == entity.key ? null : hoveringOnElementId
          "
          @mouseover="getData(entity)"
          @click="gotoArticles(entity)"
        >
          {{ getTranslatedEntityLabel(entity) }}
        </h5>

        <div class="m-ent-rel__values">
          <m-ent-rel-results-row
            :entity="entity"
            :index="idx"
            :n-rows="trendingEntities.length"
            :granularCorporaFrequency="granularCorporaFrequency"
            :labelColWidth="labelColWidth"
            :corporaCount="corporaCount"
            :selectionCount="selectionCount"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import { useRouter, useRoute } from "vue-router";
import { checkWiki } from "@utils/utils";
import MEntRelResultsRow from "@components/MEntRelResultsRow.vue";
import { DateTime } from "luxon";
import { useApi } from "@api/api";
import DateTimeUtils from "@root/utils/dateTime";
import { map } from "@root/utils/map";
import { useTooltip } from "@tooltips/useTooltip";
import { delay } from "lodash-es";
import { useUserStore } from "@root/store/modules/user";
import { checkForWikiImage } from "@utils/utils";
import { watch } from "vue";
import {
  navigateWithQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const props = defineProps({
  trendingEntities: {
    type: Array,
    required: true,
  },
  granularCorporaFrequency: {
    type: Array,
    required: true,
  },
  parentScrollableElement: {
    type: Object,
    required: false,
  },
  corporaCount: {
    type: Number,
    required: true,
  },
  selectionCount: {
    type: Number,
    required: true,
  },
});

const { t } = useI18n();
const userStore = useUserStore();
const filtersStore = useFiltersStore();
const router = useRouter();
const route = useRoute();
const { api } = useApi();
const { updateTooltipContent } = useTooltip();
const workspacesStore = useWorkspacesStore();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const viewId = computed(() => route.params.viewId);
const userTimezone = ref(userStore.timeZone);
const userLocale = computed(() => userStore.i18nLanguage);
const userLang = computed(() => userLocale.value.split("-")[0]);

const canAccessAIExplanations = computed(
  () => userStore.details.featureAccess?.["showAIExplanations"] ?? false
);

const labelColWidth = computed(
  () => 100 / props.granularCorporaFrequency.length
);
const box = ref(null);
const diffInDateResctrition = ref(0);
onMounted(() => {
  const datesDiff = checkDateRestrictionDateDiff() + 1;
  if (datesDiff < 7) diffInDateResctrition.value = 7 - datesDiff;
});

const wikidata = ref({});

/** Build the necessary query and redirect the user to the articles page */
const gotoArticles = (entity) => {
  /** @type {Monitio.URLQueryObject} */
  const filters = {
    filters: [
      {
        facets: [
          {
            value: entity.type,
            query: [
              {
                label: entity.label ?? entity.key,
                value: entity.key,
              },
            ],
          },
        ],
      },
    ],
    dateRestriction: filtersStore.dateRestriction,
  };
  navigateWithQueryObject(route, router, filters, "articles", {
    viewId: viewId.value,
  });
};

/**
 * @description Function to get wiki details about the current opic in the row
 * Note that there should be some language functionality, atm there's not
 * @param {import("@root/types.api.local").MonitioAPI.SearchResponseProperties} entity
 */
const getWikiData = async (entity) => {
  if (wikidata.value[entity.key]) return;
  const params = {
    action: "query",
    prop: "extracts",
    exintro: "",
    explaintext: "",
    format: "json",
    titles: entity.label ?? entity.key,
    redirects: 1,
    origin: "*",
  };

  await new Promise((r) => setTimeout(r, 500)); //wait 500 wich is the time for the tooltip to get rendered. We do this beucase this wikidata call is faster to return than 500 usually
  const response = await checkWiki(
    userLang.value ?? "en",
    entity.label ?? entity.key,
    "en",
    params
  );

  const imgResponse = await checkForWikiImage(
    userLang.value ?? "en",
    entity.label ?? entity.key,
    entity.label ?? entity.key,
    userLang.value ?? "en"
  );

  if (!response?.data?.query?.pages) return;
  /** One request per row, means only one entry in the response */
  const data = Object.values(response?.data?.query?.pages)[0];
  data.image = imgResponse.image;
  data.page = imgResponse.page;

  wikidata.value[entity.key] = data;
  delay(
    updateTooltipContent,
    300,
    document.getElementById(`m_ent_rel_${entity.key}`)
  );
};

const getTranslatedEntityLabel = (entity) => {
  const label = entity.label ?? entity.key;
  /*   switch (entity.type) {
    case "sourceLanguage":
      return localizeLanguageCode(label);
    case "/customMetadata/sourceItemTypedScopes/$ISO_3166_alpha-2_country_code":
    case "/customMetadata/sourceItemTypedScopes/$alpha-2_continent_code":
    case "feedCountry":
      return localizeCountryCode(label);
    default:
      return label;
  } */

  return label;
};

const getTooltipContent = (entity) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  const tooltip = {
    label: entity.label ?? entity.key,
    type: t(`general.facets.${entity.type}`),
    dir: arabicRegex.test(entity.key) ? "rtl" : null,
    wikipedia: "",
    assistant: undefined,
    openChat: t("chat.open"),
    openWikipedia: t("views.trending-entities.openWikipedia"),
    wikipediaLink: wikidata.value[entity.key]?.page,
    image: wikidata.value[entity.key]?.image || "./monitio_thumbnail.png",
  };

  if (wikidata.value[entity.key] == undefined) {
    tooltip.wikipedia = "";
  } else if (wikidata.value[entity.key]?.extract) {
    if (wikidata.value[entity.key]?.extract.length < 250) {
      tooltip.wikipedia = wikidata.value[entity.key]?.extract;
    } else {
      tooltip.wikipedia = wikidata.value[entity.key]?.extract.substr(0, 250);
      const idx = tooltip.wikipedia.lastIndexOf(" ");
      tooltip.wikipedia = `${wikidata.value[entity.key]?.extract.substr(
        0,
        idx + 1
      )} <a data-is-tooltip="true" href="${
        tooltip.wikipediaLink
      }" target="_blank" title="${
        tooltip.openWikipedia
      }" class="type--500 type--small">[...]</a>`;
    }
  } else tooltip.wikipedia = t("views.entity-network.unableToGetExtract");

  tooltip.assistant = assistantExplanation.value[entity.key] ?? undefined;
  return tooltip;
};

const getTooltipTemplate = (entity) => {
  const content = getTooltipContent(entity);

  if (canAccessAIExplanations.value) {
    if (content.assistant) return "wikiAssistant";
    else if (content.wikipediaLink && content.image) {
      return "wikiAssistantLoading";
    } else return "wikiLoadingAssistantLoading";
  } else {
    if (content.wikipediaLink && content.image) {
      return "wikipedia";
    } else {
      return "wikipediaLoading";
    }
  }
};

const checkDateRestrictionDateDiff = () => {
  const getLuxonGranularity = (g) => {
    switch (g) {
      case "day":
        return "days";
      case "week":
        return "weeks";
      case "month":
        return "months";
      case "year":
        return "years";
      default:
        return "days";
    }
  };
  //The minimum number of collumns is 7, else that chart will be kinda bad and doesn't work
  /** @type {import("@root/types.api.local").MonitioAPI.DateRestriction} */
  const dr =
    filtersStore.queryObject?.dateRestriction ?? filtersStore.dateRestriction;
  const absoluteDate = dr.isRelative
    ? DateTimeUtils.getTimeFrame(
        dr.timeFrame,
        userStore.timeZone,
        workspaceConfig.value.documentDateRange
      )
    : dr;

  const gran = getLuxonGranularity(filtersStore.granularity);
  const diff = absoluteDate.end?.diff(absoluteDate.start, gran).toObject();

  return Math.floor(diff?.[gran] ?? 0);
};

const trimmedDates = computed(() => {
  if (props.granularCorporaFrequency.length > 7) {
    const result = [];
    for (let i = 0; i < 7; i++) {
      const mappedVal = map(i, 0, 7, 0, props.granularCorporaFrequency.length);
      const index = Math.floor(mappedVal);
      result.push(props.granularCorporaFrequency[index]);
    }
    return result;
  }
  return props.granularCorporaFrequency;
});

/** @type {string!null} */
let hoveringOnElementId = null; // eslint-disable-line
/** @type {import("vue").Ref<{[key:string]: string}>} */
const assistantExplanation = ref({});
const getAssistantExplanation = async (entity) => {
  if (!canAccessAIExplanations.value) return;
  if (assistantExplanation.value[entity.key] !== undefined) return;

  /** @type {import("@root/types.api.local").MonitioAPI.ExplainTrendingDTO} */
  const params = {
    viewId: viewId.value,
    dateRestriction: filtersStore.dateRestriction,
    filters: filtersStore.queryObject?.filters,
    entityKey: entity.key,
    entityLabel: entity.label,
    entitySearchPropertyType: entity.type,
  };
  delay(async () => {
    if (hoveringOnElementId != entity.key) return;
    assistantExplanation.value[entity.key] = "";
    try {
      const { data } = await api.search.getTrendingExplanation(params);
      if (!data)
        assistantExplanation.value[entity.key] = t(
          "views.trending-entities.unableToGetEnliExplain"
        );
      else {
        const { response, sources } = data;
        assistantExplanation.value[entity.key] = response;
      }
    } catch (error) {
      assistantExplanation.value[entity.key] = t(
        "views.trending-entities.unableToGetEnliExplain"
      );
    }
    delay(
      updateTooltipContent,
      300,
      document.getElementById(`m_ent_rel_${entity.key}`)
    );
  }, 500);
};

const getData = (entity) => {
  getWikiData(entity);
  getAssistantExplanation(entity);
};
</script>

<style scoped lang="scss">
.m-ent-rel {
  position: relative;

  &__background {
    @include flex(flex-start, center, row);
    position: absolute;
    z-index: -1;
    pointer-events: none;
    width: calc(100% - 8px);
    height: 100%;
    padding-left: 195px;

    div {
      width: 100%;
      height: 100%;
      margin: 0 1px;

      &.colored {
        background-color: rgb(243, 244, 245);
        border-left: 1px dashed rgb(205, 213, 221);
        border-right: 1px dashed rgb(205, 213, 221);
      }
    }
  }

  &__timeline {
    @include flex(flex-start, center, row);

    .m-ent-rel {
      &__mask {
        width: 180px;
        margin-right: $spacing-2;
        @include ellipsis;
      }

      &__dates {
        @include flex(space-between, center, row);
        flex-grow: 1;
        padding: 0 8px;

        &.unique {
          justify-content: space-around;
        }

        h6 {
          flex-grow: 1;
          text-align: center;
        }
      }
    }
  }

  &__row {
    height: 32px;
    padding: $spacing-1 $spacing-2;
    margin: $spacing-1 $spacing-0;
    @include flex(flex-start, center, row);
    box-sizing: content-box;

    * {
      height: inherit;
    }

    &:hover {
      @include round-corners($spacing-1);
      background-color: color($pri-action-light, 0.2);
    }

    > span {
      @include flex(flex-start, center, row);
    }

    .m-ent-rel {
      &__key {
        width: 180px;
        height: 24px;
        margin-right: $spacing-2;
        @include ellipsis;

        &:hover {
          color: color($pri-action);
          cursor: pointer;
        }
      }

      &__values {
        @include flex(flex-start, center, row);
        flex-grow: 1;
        cursor: pointer;
      }
    }
  }
}
.m-popover__click :deep(.type--700) {
  white-space: normal;
}
</style>
