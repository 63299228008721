<template>
  <div
    :id="`m_article_${snakeCase(article.id)}`"
    ref="articleRef"
    tabindex="0"
    role="button"
    :data-tooltip-content="articleTitle.title"
    data-tooltip-position="dynamic"
    class="m-article"
    :class="[
      `m-article--${setLayout}`,
      {
        'm-article--saved': setLayout == 'list' && article.isSaved,
        'm-article--read': article.isRead,
        'm-article--unread': !article.isRead,
      },
    ]"
    :style="articleStyle"
    @mouseenter="hover.expanded = true"
    @mouseleave="hover.expanded = false"
    @click="goToArticle(article)"
    @keydown.enter="goToArticle(article)"
  >
    <div class="m-article__container">
      <div class="m-article__wrapper">
        <div
          v-if="setLayout != 'list' && setLayout != 'preview'"
          class="m-article__image"
          :class="{ 'm-article__image--tweet': tweet }"
        >
          <m-image :src="image" :fallback="tweet ? x_background : undefined" />
          <div class="m-article__tags">
            <p
              v-if="articleTitle.lang != articleLang"
              :id="`m_article_${snakeCase(article.id)}_tag`"
              :data-tooltip-content="translatedTooltip"
              data-tooltip-position="dynamic"
              class="h5 type--xsmall m-article__tag"
            >
              {{ articleLang }}
            </p>
            <m-icon
              v-if="getTooltip('visibility')"
              :icon="articleVisibility"
              variant="white"
              status="active"
              :tooltip="getTooltip('visibility')"
              :title="getTooltip('visibility').content"
              class="m-article__tag"
            />
          </div>
        </div>
        <div class="m-article__content">
          <div class="m-article__heading">
            <m-feed-link
              v-if="setLayout == 'list'"
              ref="feedLinkRef"
              :feed="feed"
              :size="setLayout == 'card' ? 'small' : 'default'"
              :dir="langDirection(selectedLang)"
            />
            <component
              :id="`m_article_title_${article.id}`"
              :is="article?.isRead ? 'h5' : 'h4'"
              class="m-article__title"
              :dir="langDirection(selectedLang)"
            >
              {{ articleTitle.title }}
            </component>
            <m-feed-link
              v-if="setLayout == 'card'"
              ref="feedLinkRef"
              :feed="feed"
              :size="setLayout == 'card' ? 'small' : 'default'"
              :dir="langDirection(selectedLang)"
            />
          </div>
          <div class="m-article__bottom">
            <m-duplicates
              v-if="setLayout == 'list'"
              :article="article"
              :lang="selectedLang"
            />
            <div v-if="setLayout == 'list'" class="mx-2 m-article__tags">
              <p
                v-if="articleTitle.lang != articleLang"
                :id="`m_article_${snakeCase(article.id)}_tag`"
                :data-tooltip-content="translatedTooltip"
                data-tooltip-position="dynamic"
                class="h5 type--xsmall m-article__tag"
              >
                {{ articleLang }}
              </p>
              <m-icon
                v-if="getTooltip('visibility')"
                :icon="visibilityIcon"
                variant="white"
                status="active"
                size="small"
                :tooltip="getTooltip('visibility')"
                class="m-article__tag"
              />
            </div>
            <div
              v-if="setLayout == 'details' || setLayout == 'preview'"
              class="m-article__feed"
            >
              <m-feed-link :feed="feed" size="small" class="mr-2" />
              <div v-if="setLayout != 'preview'" class="m-article__feed-info">
                <div v-for="(info, i) in feedInfo" :key="i">
                  <h6 class="type--xsmall">{{ info }} sd</h6>
                  <div
                    v-if="feedInfo.length > 1 && i != feedInfo.length - 1"
                    class="mx-2 m-divider"
                  ></div>
                </div>
              </div>
            </div>
            <m-article-search
              v-if="snippet && setLayout == 'details' && articleSnippet"
              class="pt-2 pb-1"
              :search="articleSnippet"
              :language="articleLang"
            />
            <h6
              class="m-article__date"
              :class="setLayout == 'list' ? 'mr-3 type--small' : 'type--xsmall'"
            >
              {{ date }}
            </h6>
            <p
              v-if="
                setLayout == 'preview' &&
                article.metadata?.compliance?.visibility == 'open'
              "
              class="type--reading"
            >
              <m-text-formatter
                v-if="text"
                :text="text"
                :format="textFormat"
                :langDirection="langDirection(selectedLang)"
                :canEditEntities="false"
                :showTranslation="false"
                :article="article"
                :selectedLang="selectedLang"
              />
            </p>
            <div class="m-article__actions" @click.stop>
              <m-duplicates
                v-if="setLayout != 'list'"
                :article="article"
                :lang="selectedLang"
              />
              <m-icon
                v-if="actions.includes('read')"
                :id="readIconId"
                :icon="readStatus"
                variant="secondary"
                size="small"
                :tooltip="getTooltip('read')"
                :title="getTooltip('read').content"
                :disabled="disableActions"
                @mouseenter="toggleReadIcon"
                @mouseleave="toggleReadIcon"
                @click="changeReadStatus"
              />
              <m-icon
                v-if="actions.includes('save')"
                :id="saveIconId"
                :icon="saveStatus"
                variant="secondary"
                size="small"
                class="m-article__icon-save"
                :class="[`m-article__icon-save--${saveStatus}`]"
                :tooltip="getTooltip('save')"
                :title="getTooltip('save').content"
                :disabled="disableActions"
                @mouseenter="toggleSaveIcon"
                @mouseleave="toggleSaveIcon"
                @click="toggleSave"
              />
              <template v-if="actions.includes('smart-tagging')">
                <m-icon
                  :icon="tagStatus === true ? 'thumbs-up-filled' : 'thumbs-up'"
                  variant="secondary"
                  size="small"
                  class="m-article__icon-thumbs-up"
                  tooltip="Upvote"
                  title="Upvote title"
                  :disabled="disableActions"
                  @click="emit('upvote')"
                />
                <m-icon
                  :icon="
                    tagStatus === false ? 'thumbs-down-filled' : 'thumbs-down'
                  "
                  variant="secondary"
                  size="small"
                  class="m-article__icon-thumbs-down"
                  tooltip="Downvote"
                  title="Downvote title"
                  :disabled="disableActions"
                  @click="emit('downvote')"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- 
      // Commented because it's not stable
      <div
        v-if="
          hasTeaser &&
          articleVisibility != 'restricted' &&
          teaser &&
          hover.expanded
        "
        class="mt-2"
      >
        <p
          class="m-article__teaser h6 type--xsmall"
          :class="{ 'type--empty': !teaser }"
        >
          {{ teaser }}
        </p>
        <p class="h6 m-article__estimated-time">
          {{ readingTime }}
        </p>
      </div> -->
      <m-article-search
        v-if="snippet && setLayout != 'details' && articleSnippet"
        :search="articleSnippet"
        :language="selectedLang"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, unref, watch, computed, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import x_background from "@assets/images/x_background.png";
import useDateFormat from "@hooks/useDateFormat";
import useTranslator from "@hooks/useTranslator";
import useArticle from "@hooks/useArticle";
import MIcon from "@components/MIcon.vue";
import MTextFormatter from "@components/MTextFormatter.vue";
import MImage from "@components/MImage.vue";
import MFeedLink from "@components/MFeedLink.vue";
import MDuplicates from "@components/MDuplicates.vue";
import MArticleSearch from "@components/MArticleSearch.vue";
import { transformFormatting } from "@utils/textFormatterUtils";
import { snakeCase } from "lodash-es";
import { eventBus } from "@root/utils/eventBus";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useNavigationStore } from "@root/store/modules/navigation";

const props = defineProps({
  article: { type: Object, required: true },
  actions: { type: Array, default: (r) => ["read", "save"] },
  disableActions: { type: Boolean, default: false },
  hasTeaser: { type: Boolean, default: false },
  canOpen: { type: Boolean, default: true },
  snippet: { type: Boolean, default: false },
  layout: { type: String },
  tagStatus: { type: Boolean, default: () => null },
});

const emit = defineEmits([
  "change-read-status",
  "changed-bookmarked-status",
  "update:article",
  "upvote",
  "downvote",
]);

const { t } = useI18n();
const workspacesStore = useWorkspacesStore();
const navigationStore = useNavigationStore();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const { api } = useApi();
const route = useRoute();
const { push, currentRoute, resolve } = useRouter();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const { dateFormat } = useDateFormat();
const { localizeCountryCode, localizeLanguageCode } = useTranslator();

const articleRef = ref(null);
const articleStyle = ref("");
const feedLinkRef = ref(null);

const checkForArabicLanguage = (txt) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(txt) ? "rtl" : null;
};

const viewId = computed(() => route.params.viewId);
const { saveArticle, unSaveArticle } = useArticle(viewId);
const hover = ref({
  save: false,
  read: false,
  expanded: false,
});

const setLayout = computed(() => {
  if (props.layout) return props.layout;
  else if (
    [
      "home",
      "summary",
      "report",
      "tiles",
      "tiles-cluster",
      "headlines",
      "headlines-cluster",
      "manageFilters",
      "createManageFilters",
      "article",
    ].includes(currentRoute.value.name)
  ) {
    return "card";
  } else return viewsStore.layout;
});

watch(
  () => setLayout.value,
  (val, oldVal) => {
    if (val != oldVal) {
      articleStyle.value = "";

      nextTick(() => {
        if (setLayout.value != "card") {
          // articleStyle.value = `height: ${
          //   articleRef.value.getBoundingClientRect().height
          // }px`;
        }
      });
    }
  },
  { immediate: true }
);

const userLang = computed(() => userStore.i18nLanguage.split("-")[0]);
const articleLang = computed(() => props.article.documentLang);
const articleTranscriptionLang = computed(
  () => props.article?.transcriptionLang
);

const selectedLang = computed(() => {
  if (
    userStore.account?.translateFrom?.includes(
      articleLang.value?.split("-")[0]
    ) &&
    props.article?.translations?.[userLang.value]?.title
  ) {
    return userLang.value;
  } else return articleLang.value;
});

/** @description stores an array with all avaiable languages in wich there is main text */
const availableLangs = computed(() =>
  Object.keys(props.article?.translations).filter(
    (key) => props.article?.translations[key].mainText
  )
);

const articleTitle = computed(() => {
  const translations = props.article?.translations ?? {};
  if (translations[selectedLang.value]?.title) {
    return {
      title: translations[selectedLang.value]?.title,
      lang: selectedLang.value,
    };
  } else {
    return {
      title: t("article.main_title_untitled"),
      lang: articleLang.value,
    };
  }
});

watch(
  () => articleTitle.value,
  (oldTitle, newTitle) => {
    if (oldTitle.title != newTitle.title) {
      navigationStore.setArticleTitle(newTitle.title);
    }
  }
);

const articleVisibility = computed(
  () => props.article?.metadata?.compliance?.visibility
);

const articleBilling = computed(() => {
  const billingType = props.article?.metadata?.compliance?.billingType;
  return billingType?.split("_")[0];
});

const visibilityIcon = computed(() => {
  if (articleVisibility.value) return articleVisibility.value;
  else if (articleBilling.value) return articleBilling.value;
  else return "";
});

const translatedTooltip = computed(() => {
  if (articleTitle.value.lang != articleLang.value) {
    const language =
      localizeLanguageCode(articleLang.value).charAt(0).toUpperCase() +
      localizeLanguageCode(articleLang.value).slice(1);
    return t("article.main_translated", { language });
  } else return null;
});

const articleURL = computed(
  () =>
    props.article?.metadata?.cannonicalUrl ??
    props.article?.metadata?.sourceItemUrlList?.[0]
);

const articleSnippet = computed(() => {
  if (viewsStore.showSnippet) return props.article?.metadata?.highlight;
  else return null;
});

const teaser = computed(
  () =>
    props.article.translations[selectedLang.value].mainText?.substring(0, 150) +
    "..."
);

const readingTime = computed(() =>
  t(`articles.readingTime`, { minutes: props.article.metadata.readingTime })
);

const tweet = computed(() =>
  props.article.metadata?.sourceItemUrlList?.some(
    (x) => x.includes("twitter.com") || x.includes("://x.com")
  )
);
const youtube = computed(() =>
  props.article.metadata?.sourceItemUrlList?.some(
    (x) => x.includes("youtube.com") || x.includes("youtu.be")
  )
);

const image = computed(() => {
  if (props.article.thumbnail) {
    return props.article.thumbnail;
  } else if (tweet.value) {
    return x_background;
  } else return "";
});

const feed = computed(() => {
  const feed = {};
  if (tweet.value) {
    feed.handle =
      props.article.metadata?.socialMediaInformation?.twitter?.Handle;
  }
  feed.name = props.article?.metadata?.sourceItemFeeds?.[0]?.name;
  feed.url = articleURL.value;

  return feed;
});

const feedInfo = computed(() => {
  const platform = props.article.metadata?.sourceItemTypedScopes?.platform?.[0];

  const feedCountry = localizeCountryCode(
    props.article.metadata?.sourceItemTypedScopes?.feedCountry?.[0] ?? ""
  );

  const district = localizeCountryCode(
    props.article.metadata?.sourceItemTypedScopes?.district?.[0] ?? ""
  );

  const info = [];

  if (feedCountry) info.push(feedCountry);
  if (district) info.push(district);
  if (platform) info.push(platform);

  return info;
});

const articleAuthor = computed(() => props.article.articleAuthor);

const date = computed(() => {
  return dateFormat(props.article?.date, "dynamic");
});

const isHoveringSaveIcon = ref(false);
const saveStatus = computed(() => {
  if (props.article?.isSaved) {
    if (isHoveringSaveIcon.value) return "saved";
    return "saved-filled";
  } else {
    if (isHoveringSaveIcon.value) return "saved-filled";
    return "saved";
  }
});

const readStatus = computed(() => {
  if (props.article?.isRead) return "read";
  else return "unread";
});

eventBus.on("read-all", () => {
  emit("change-read-status", true);
});

const toggleSaveIcon = (evt) => {
  if (evt.type == "mouseleave") {
    isHoveringSaveIcon.value = false;
  } else {
    isHoveringSaveIcon.value = true;
  }
};

const saveIconId = computed(() => {
  const id = snakeCase(props.article?.id);
  const status = snakeCase(saveStatus.value);
  return `${id}_${status}`;
});

const toggleReadIcon = (evt) => {
  const read = props.article?.isRead;
  if (
    (read && readStatus.value == "read") ||
    (!read && readStatus.value == "unread")
  ) {
    if (evt.type == "mouseleave") return;
  }
  if (readStatus.value == "read") readStatus.value = "unread";
  else readStatus.value = "read";
};

const readIconId = computed(() => {
  const id = snakeCase(props.article?.id);
  const status = snakeCase(readStatus.value);
  return `${id}_${status}`;
});

const changeReadStatus = () => {
  emit("change-read-status", !props.article?.isRead);
};

const goToArticle = (article) => {
  if (props.canOpen) {
    if (route.name == "createManageFilters") {
      /** If we are previwing articles in the vreate view page we need to open the article in a new tab */
      const routeData = resolve({
        name: "article",
        params: {
          ...route.params,
          viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
          articleId: article.id,
          breadcrumb: articleTitle.value, //This suddently doesnt work. Now on article load, populates this title (see MArticle view)
        },
        query: route.query,
      });
      window.open(routeData.href, "_blank");
    } else {
      push({
        name: "article",
        params: {
          viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
          articleId: article.id,
          breadcrumb: articleTitle.value, //This suddently doesnt work. Now on article load, populates this title (see MArticle view)
        },
        query: route.query,
      });
    }
  }
};

const toggleSave = () => {
  if (props.article.isSaved) {
    emit("changed-bookmarked-status", false);
    unSaveArticle(props.article, emit);
  } else {
    emit("changed-bookmarked-status", true);
    saveArticle(props.article, emit);
  }
};

const langDirection = (lang) => {
  return (
    workspacesStore.languages.find((x) => x.isoCode == lang.split("-")[0])
      ?.direction ?? "lrt"
  );
};

const getTooltip = (type) => {
  const tooltip = { content: "", position: "dynamic" };

  if (type == "visibility") {
    if (articleBilling.value == "premium") {
      tooltip.content = t(`article.main_${articleBilling.value}`);
    } else if (
      articleVisibility.value == "expired" ||
      articleVisibility.value == "restricted" ||
      articleVisibility.value == "embargoed"
    ) {
      tooltip.content = t(`article.main_${articleVisibility.value}`);
    } else return null;
  } else {
    if (props.disableActions) {
      tooltip.content = t("article.sharedViewError");
    } else if (type == "save") {
      tooltip.content = t(`article.side_button_${saveStatus.value}`);
    } else if (type == "read") {
      tooltip.content = t(
        `article.side_button_${props.article?.isRead ? "unread" : "read"}`
      );
    }
  }

  return tooltip;
};

const articleTranslations = computed(() => props.article.translations);
/** @description Has the text corresponsing to the selected language, or english, or if not avaiable the native article language*/
const text = computed(() => {
  const transcriptionText =
    articleTranslations.value?.[articleTranscriptionLang.value]
      ?.transcription ??
    articleTranslations.value?.[selectedLang.value]?.transcription;

  if (transcriptionText) return transcriptionText;

  const selectedLangText = articleTranslations.value[selectedLang.value];
  if (selectedLangText?.mainText) return selectedLangText.mainText;
  return "";
});
const videoTranscription = computed(() => {
  if (!articleTranscriptionLang.value) return null;
  let lang = selectedLang.value;
  let text = articleTranslations.value?.[selectedLang.value]?.transcription;
  if (!articleTranslations.value?.[selectedLang.value]?.transcription) {
    lang = articleTranscriptionLang.value;
    text =
      articleTranslations.value?.[articleTranscriptionLang.value]
        ?.transcription;
  }

  return {
    lang: lang,
    value: text,
  };
});

const transcriptionTextFormat = computed(() => {
  const videoTransSelectedLang = videoTranscription.value?.lang;
  if (!videoTransSelectedLang) return [];

  const transcriptionSegments = (
    props.article?.translations?.[videoTransSelectedLang]?.formatting
      ?.transcription ?? []
  ).map((x) => {
    x.text = x.text.replaceAll("\n", " ");
    return x;
  });

  let count = 0;

  /** Gets the oder the segment should be relative to other formattings that might exist, mainly entitiy mentions */
  const segmentsByBlockId = transcriptionSegments.reduce((p, c, idx) => {
    const existingSegment = p.find((x) => x.id == c.id);
    if (existingSegment) {
      existingSegment.text += " " + c.text;
      existingSegment.end = existingSegment.start + existingSegment.text.length;
      existingSegment.endTime += " " + c.end;
    } else {
      const start = videoTranscription.value.value.indexOf(c.text);
      const end = start + c.text.length;
      p.push({
        id: c.id,
        text: c.text,
        start: start,
        startTime: c.start,
        endTime: c.end,
        end: end,
        ord: idx,
        tag: "transcription-segment",
      });
    }
    count += c.text.length;
    return p;
  }, []);
  return segmentsByBlockId;
});

const format = computed(() => {
  if (!articleTranslations.value?.[selectedLang.value]?.formatting?.mainText)
    return [];
  return transformFormatting(
    articleTranslations.value[selectedLang.value].formatting.mainText,
    props.article
  );
});

const textFormat = computed(() => {
  if (transcriptionTextFormat.value) {
    return [...(transcriptionTextFormat.value ?? [])];
  }
  // Find the text format corresponding to the text language. If there's none return empty
  return [...(format.value ?? [])];
});
</script>
