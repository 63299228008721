<template>
  <div ref="containerRef" class="in-ma_0 m-clustering"></div>
</template>

<script setup>
import * as d3 from "d3";
import {
  checkImage,
  addClass,
  removeClass,
  resizeTextLite,
} from "../utils/utils";
import { useI18n } from "vue-i18n";

import { useRoute, useRouter } from "vue-router";
import TimeFrame from "@utils/enums/timeFrames";
import { onUnmounted, onMounted, watch, computed, ref, nextTick } from "vue";
import useCreateViewModal from "@components/modals/MCreateView/useCreateViewModal";
import { useViewsStore } from "@root/store/modules/views";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import structuredClone from "@root/utils/structuredClone";
import { useClustersStore } from "@root/store/modules/clusters";
import {
  navigateWithQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";

const { t } = useI18n();
const viewsStore = useViewsStore();
const filtersStore = useFiltersStore();
const workspacesStore = useWorkspacesStore();
const clustersStore = useClustersStore();
const route = useRoute();
const router = useRouter();
const { open: openCreateViewModal } = useCreateViewModal();

let changeImageClustersEvent;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let d3container;
const treemap = d3
  .treemap()
  .size([100, 100])
  .tile(d3.treemapBinary)
  //.tile(d3.treemapSquarify.ratio(1))
  .round(true)
  .paddingInner(0);

const props = defineProps({
  clusters: { type: Object, default: () => null },
  initialDate: {
    type: Object,
    default: () => {
      return { crg: TimeFrame.Today };
    },
  },
});

const containerRef = ref(null);
let resizeEventListener;
onMounted(() => {
  d3container = d3.select(containerRef.value);
  resizeEventListener = window.addEventListener("resize", resize);
});
onUnmounted(() => {
  window.removeEventListener("resize", resizeEventListener);
});

watch(
  () => props.clusters,
  (val) => {
    d3.select(containerRef.value).selectAll("*").remove();
    nextTick(() => {
      if (val) {
        const root = d3
          .hierarchy(val)
          .sum(function (d) {
            return d.value;
          })
          .sort(function (a, b) {
            return b.height - a.height || b.value - a.value;
          });

        treemap(root);

        const parent_data = {};

        root.leaves().forEach((d) => {
          if (!parent_data[d.data.parent?.name]) {
            parent_data[d.data.parent?.name] = {
              x: d.x0,
              y: d.y0,
              w: 0,
              h: 0,
              children: [],
            };
          } else {
            // min x,y
            parent_data[d.data.parent?.name].x =
              parent_data[d.data.parent?.name].x < d.x0
                ? parent_data[d.data.parent?.name].x
                : d.x0;
            parent_data[d.data.parent?.name].y =
              parent_data[d.data.parent?.name].y < d.y0
                ? parent_data[d.data.parent?.name].y
                : d.y0;
          }
          parent_data[d.data.parent?.name].children.push(d);

          return d.data.parent?.name;
        });

        Object.keys(parent_data).forEach((k) => {
          const parent_info = parent_data[k];

          parent_info.children.forEach((child_d) => {
            if (parent_info.x == child_d.x0) {
              parent_info.h += child_d.y1 - child_d.y0;
            }
            if (parent_info.y == child_d.y0) {
              parent_info.w += child_d.x1 - child_d.x0;
            }
          });
        });

        const overview = d3.select(containerRef.value);

        Object.keys(parent_data).forEach((k) => {
          const parent_info = parent_data[k];
          const parent_div_original = overview
            .append("div")
            .style("left", function () {
              return parent_info.x + "%";
            })
            .style("top", function () {
              return parent_info.y + "%";
            })
            .style("width", function () {
              return parent_info.w + "%";
            })
            .style("height", function () {
              return "calc(" + parent_info.h + "% - 8px)";
            })
            .attr("class", "m-cluster");

          const parent_div = parent_div_original
            .append("div")
            .attr("class", "m-cluster__overflow")
            .append("div")
            .attr("class", "m-cluster__trans"); // <- write style in class
          //.style("z-index", "20")
          //.style("margin-top", "2.5px")

          const w_factor = parent_info.w / 100.0;
          const h_factor = parent_info.h / 100.0;

          let i_c = 0;
          parent_info.children.forEach((d) => {
            const cell_d_w = (d.x1 - d.x0) / w_factor;
            const cell_d_h = (d.y1 - d.y0) / h_factor;

            const cell_d = parent_div
              .append("div")
              .attr("parent-name", function () {
                return d.data.parent?.name;
              })
              .attr("class", function () {
                const dclass = "m-clustering__cell m-cell__" + d.data?.name;

                return dclass;
              })
              .style("left", function () {
                return (d.x0 - parent_info.x) / w_factor + "%";
              })
              .style("top", function () {
                return (d.y0 - parent_info.y) / h_factor + "%";
              })
              .style("width", function () {
                return "calc(" + cell_d_w + "%)";
              })
              .style("height", function () {
                return "calc(" + cell_d_h + "%)";
              })
              .on("mouseenter", function () {
                this.classList.add("m-clustering__cell--hover");
                const button = this.querySelector(".m-clustering__button");
                button.style.display = "flex";
              })
              .on("mouseleave", function () {
                this.classList.remove("m-clustering__cell--hover");
                const button = this.querySelector(".m-clustering__button");
                button.style.display = "none";
              })
              .on("click", function () {
                gotoClusterPage(d.data?.name);
                //scope.$emit("clustering-cell-clicked", d.data);
              });

            cell_d.append("div").attr("class", "m-clustering__image");

            const cell = cell_d._groups[0][0]; // instanceElement.find(".cell_" + f.data?.name);
            const text0 = cell_d.append("div").attr("class", function () {
              let color = d.data.color || null;
              let p = d;
              while (color == null && p) {
                if (p.data.color) color = p.data.color;
                p = p.parent;
              }
              return "m-clustering__text--" + color; // NOTE: to get async text resizing or other async call + " loading";
            });

            const svgTop =
              "m19.61,13.556l-2.048,2.218v1.957c0,.294-.238.532-.543.532h-.826c-.306,0-.543-.238-.543-.532v-1.957l-2.06-2.218c-.396-.452-.034-1.064.464-1.052h5.103c.498,0,.86.611.453,1.052Z";

            const svgBottom =
              "m10.915,16.335h-5.45c-1.467,0-2.774-1.228-2.77-2.78v-2.91c-.01-.09,0-.18.03-.27l1.53-4.79c.37-1.15,1.44-1.93,2.64-1.93h6.2c1.22,0,2.29.78,2.66,1.95l1.12,3.54c.359.973-1.211,1.553-1.57.58l-1.13-3.63c-.16-.46-.59-.77-1.08-.77h-6.21c-.48,0-.9.31-1.04.77l-1.2,3.72h2.83c.38,0,.71.25.81.61.407,1.628,2.843,1.911,3.41,0,.1-.36.43-.61.8-.61h.97c1.107,0,1.107,1.65,0,1.65h-.39c-1.462,2.803-5.053,2.362-6.16,0h-2.57v2.09c0,.61.5,1.12,1.12,1.12h5.46c1.1.006,1.089,1.666-.01,1.66Z";

            const g = cell_d
              .append("button")
              .attr("class", "m-clustering__button")
              .on("click", (event) => {
                createView(d.data);
                event.stopPropagation();
              })
              .append("div")
              .attr("id", "m_tiles_story_create_view")
              .attr("data-tooltip-content", `${t("views.cluster.createView")}`)
              .attr("data-tooltip-position", "bottom-left")
              .attr(
                "class",
                "m-icon m-icon--secondary m-icon--up m-icon--inactive m-icon--regular m-icon--small m-icon--elevate"
              )
              .append("svg")
              .style("width", "20px")
              .style("height", "20px")
              .attr("xmlns", "http://www.w3.org/2000/svg")
              .append("g");

            g.append("path").attr("d", svgTop);

            g.append("path").attr("d", svgBottom);

            const text = text0
              .append("div")
              .attr("class", "m-clustering__text")
              .attr("title", `${d.data.value} documents: ` + d.data.title)
              .append("span");

            const isArabic = /[\u0600-\u06FF]/.test(d.data.title); // Check if the title contains Arabic characters

            if (isArabic) {
              text.style("text-align", "right"); // Align the text to the right for Arabic title
            }

            text.text(d.data.title);
            // NOTE: sync text resizing
            resizeTextLite(cell);
            text.text(d.data.title);

            //NOTE: to get async text resizing:
            //setTimeout(() => {
            //  resizeTextLite(cell);
            //  let textCell = cell.getElementsByClassName("clustering-text")[0];
            //  removeClass(textCell, "loading");
            //}, 0);

            const box = cell.getBoundingClientRect();
            const clusteringTextDiv = text._groups[0][0];
            const fontSize = cell.getAttribute("font-size");

            if (fontSize < 8) {
              if (box.height < 29) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "1";
              } else if (box.height < 46) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "2";
              } else if (box.height < 56) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "3";
              } else if (box.height < 66) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "4";
              } else if (box.height < 76) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "5";
              } else if (box.height < 96) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "6";
              } else {
                clusteringTextDiv.style["-webkit-line-clamp"] = "7";
              }
            } else if (fontSize < 10) {
              if (box.height < 40) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "1";
              } else if (box.height < 62) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "2";
              } else if (box.height < 75) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "3";
              } else if (box.height < 89) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "4";
              } else if (box.height < 102) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "5";
              } else if (box.height < 130) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "6";
              } else {
                clusteringTextDiv.style["-webkit-line-clamp"] = "7";
              }
            } else {
              if (box.height < 70) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "1";
              } else if (box.height < 105) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "2";
              } else if (box.height < 125) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "3";
              } else if (box.height < 145) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "4";
              } else if (box.height < 165) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "5";
              } else if (box.height < 195) {
                clusteringTextDiv.style["-webkit-line-clamp"] = "6";
              } else {
                clusteringTextDiv.style["-webkit-line-clamp"] = "7";
              }
            }
            checkImage(
              d.data.image,
              ((cap_cell) => {
                return (img) => {
                  if (!img.complete || img.naturalWidth === 0) {
                    // false alert
                    //console.log(`Bad image (false alert): ${d.data.image}`);
                    addClass(cap_cell, "removed-image");
                    return;
                  }
                  if (img.naturalWidth < 50 || img.naturalHeight < 50) {
                    // false alert
                    //console.log(`image too small from: ${d.data.image} ; ${img}`);
                    addClass(cap_cell, "removed-image");
                    return;
                  }
                  const imageNode = cap_cell.getElementsByClassName(
                    "m-clustering__image"
                  )[0];

                  if (!imageNode) return; //Image node not found, probably because the requested image couldnt load
                  const parent = imageNode.parentNode;
                  const tempDiv = document.createElement("div");
                  tempDiv.innerHTML = `
                      <img class="m-clustering__image" src="${img.src}" style=" width: 100%; object-fit: cover; object-position: 0 33%; background-color: rgb(27, 37, 54);">
                    `;
                  // <img class="clustering-image-blured" src="${img.src}">

                  tempDiv.setAttribute("bg-img-url", d.data.image);
                  parent.replaceChild(tempDiv, imageNode);
                  addClass(cap_cell, "with-image");
                };
              })(cell),
              ((cap_cell) => {
                return () => {
                  //console.log(`Bad image: ${d.data.image}`);
                  addClass(cap_cell, "removed-image");
                };
              })(cell)
            );

            addClass(cell, "text-visible");

            if (i_c == 0) {
              if (parent_info.w > 6 && parent_info.h > 3) {
                // w and h are in %
                parent_div_original
                  .append("h5")
                  .attr("class", "m-cluster__name type--xsmall")
                  .attr("title", d.data.parent?.name || "")
                  .text(function () {
                    return d.data.parent?.name;
                  })
                  .on("click", function (event) {
                    gotoTopicClick(d.data.parent);

                    //scope.$emit("cluster-name-clicked", d.data);
                    event.preventDefault();
                    event.stopPropagation();
                  });
              }
            }
            i_c += 1;
          });
        });

        const animations = [
          "m-clustering__cell--up",
          "m-clustering__cell--down",
          "m-clustering__cell--left",
          "m-clustering__cell--right",
        ];
        const swapImages = function () {
          const hidden = document.getElementsByClassName(
            "with-image text-hidden"
          );
          const visible = document.getElementsByClassName(
            "with-image text-visible"
          );

          if (hidden.length) {
            const nElements = Math.floor(Math.random() * hidden.length);
            for (let i = 0; i < nElements; i++) {
              const idxElement = Math.floor(Math.random() * hidden.length);
              addClass(hidden[idxElement], "text-visible");
              animations.forEach(function (f) {
                removeClass(hidden[idxElement], f);
              });
              removeClass(hidden[idxElement], "text-hidden");
            }
          }

          if (visible.length) {
            const nElements = Math.random() > 0.5 ? 3 : 6;
            for (let i = 0; i < nElements; i++) {
              const idxElement = Math.floor(Math.random() * visible.length);
              addClass(visible[idxElement], "text-hidden");
              const anim =
                animations[Math.floor(Math.random() * animations.length)];
              addClass(visible[idxElement], anim);

              removeClass(visible[idxElement], "text-visible");
            }
          }
        };

        // Auto swap images
        if (changeImageClustersEvent) clearInterval(changeImageClustersEvent);

        setTimeout(() => {
          swapImages();
          changeImageClustersEvent = setInterval(swapImages, 3000);
        }, 0);
      }
    });
  }
);

const resize = () => {
  const ccells = document.getElementsByClassName("m-clustering__cell");
  for (let i = 0; i < ccells.length; i += 1) {
    resizeTextLite(ccells[i]);
  }
};
const gotoClusterPage = (clusterId) => {
  const query = { ...filtersStore.queryObject }; //Avoid structuredClone() here! It removes needed functions!
  query.contextualFilters = [
    {
      value: "crossClusterId",
      query: [
        {
          label: clustersStore.getClusterNameById(clusterId),
          value: clusterId,
          propertyType: "crossClusterId",
        },
      ],
    },
  ];
  navigateWithQueryObject(route, router, query, "tiles-cluster", {
    viewId: viewId.value,
    clusterId: clusterId,
  });
};
const gotoTopicClick = (value) => {
  /** @type {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]} */
  const filters = [
    {
      facets: [
        {
          value: filtersStore.aggregator,
          query: [
            {
              value: value.name,
              label: value.name,
            },
          ],
        },
      ],
    },
  ];
  filtersStore.updateViewFilters(filters, route, router);
};

const createView = (cluster) => {
  const filters = structuredClone(view.value?.details?.filters ?? []);
  const newFilter = {
    facets: [
      {
        value: "crossClusterId",
        query: [
          {
            label: cluster.title,
            thumbnail: cluster.image,
            value: cluster.name,
            propertyType: "crossClusterId",
          },
        ],
      },
    ],
  };
  filters.push(newFilter);
  viewsStore.setFilters(filters);
  openCreateViewModal("fromStory");
};

const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const view = computed(() => viewsStore.getViewById(viewId.value));
</script>

<style lang="scss">
@import "@stylesheets/scss/components/clustering";
</style>
