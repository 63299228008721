<template>
  <div
    :id="`m_selector_${id}`"
    ref="wrapperRef"
    class="m-selector"
    :class="[
      {
        'm-selector--disabled':
          disabled || (type != 'date' && !options?.length),
        'm-selector--fullwidth': fullwidth,
      },
      `m-selector--${variant}`,
      `m-selector--${size}`,
    ]"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="closeSelector"
    v-click-outside="closeSelector"
  >
    <div :id="`m_selected_${id}`">
      <div
        v-if="outsideLabel || info || required"
        class="m-selector__heading"
        :class="
          outsideLabel
            ? 'm-selector__heading--space-between'
            : 'm-selector__heading--flex-end'
        "
      >
        <label
          v-if="outsideLabel"
          :id="`m_selector_outside_label_${id}`"
          :for="id"
          :class="`type--${size}`"
        >
          {{ outsideLabel }}
        </label>
        <m-icon
          v-if="info"
          :tooltip="infoTooltip"
          icon="info"
          :size="size"
          status="active"
        />
        <m-icon
          v-if="required"
          :tooltip="requiredTooltip"
          icon="required"
          :size="size"
          status="active"
        />
      </div>
      <div
        ref="selectorRef"
        :id="`m_selector_content_${id}`"
        :data-test="`test_m_selector_${id}`"
        :data-autofocus="autoFocus"
        :data-tooltip-content="tooltip?.content ?? tooltip"
        data-tooltip-position="bottom-center"
        :tabindex="disabled || (type != 'date' && !options?.length) ? -1 : 0"
        :aria-controls="`m_selector_dropdown_${id}`"
        :aria-expanded="openDropdown"
        aria-haspopup="listbox"
        :aria-labelledby="ariaLabelledby"
        :aria-activedescendant="ariaActivedescendant"
        role="combobox"
        v-focus
        class="m-selector__content"
        :class="[
          openDropdown ? `m-selector__content--selected` : '',
          `m-selector__content--${size}`,
          {
            '--a11y': appStore.a11y,
          },
        ]"
        @click="openDropdown = true"
        @keyup.up="openDropdown = true"
        @keyup.down.exact="openDropdown = true"
        @keyup.alt.down="openDropdown = true"
        @keyup.home="openDropdown = true"
        @keyup.end="openDropdown = true"
        @keyup.space="openDropdown = true"
        @keyup.enter="openDropdown = true"
        @keyup.esc="closeSelector"
      >
        <!-- <div v-if="contentType">TODO</div> -->
        <label
          v-show="!insideLabel && !outsideLabel"
          :id="`m_selector_hidden_label_${id}`"
          :for="id"
          class="visually-hidden"
        >
          {{ tooltip?.content ?? tooltip }}
        </label>
        <label
          v-if="insideLabel"
          :id="`m_selector_inside_label_${id}`"
          :for="id"
          class="mr-2 m-selector__label--inside"
          :class="`type--${size}`"
        >
          {{ insideLabel }}
        </label>

        <span
          v-if="type == 'multiple'"
          :class="[
            `type--${size}`,
            { 'type--placeholder': setSelected.length == 0 },
            { p: variant == 'outlined' },
            { h5: variant != 'outlined' },
          ]"
        >
          {{ multipleSelectionLabel ?? placeholder }}
        </span>

        <span
          v-else
          class="mr-1"
          :class="[
            `type--${size}`,
            { 'type--placeholder': !setSelected },
            { p: variant == 'outlined' },
            { h5: variant != 'outlined' },
          ]"
        >
          {{ setSelected ? setSelected.label : placeholder }}
        </span>

        <m-icon
          v-if="type == 'date' || options?.length"
          icon="arrow"
          status="active"
          variant="terciary"
          :direction="openDropdown ? 'down' : 'up'"
          :size="iconSize"
          :tooltip="tooltip"
          class="m-selector__arrow"
          :class="{ '--placeholder': !setSelected }"
          @click="toggleDropdown"
        />
        <m-dropdown
          v-if="!disabled || (type != 'date' && options?.length)"
          ref="dropdownRef"
          :id="`m_selector_dropdown_${id}`"
          :labelledBy="`m_selector_${id}`"
          :selected="selected"
          :options="options"
          :canSelectAll="canSelectAll"
          :search="search"
          :type="type"
          :dateSpecs="dateSpecs"
          :size="size"
          v-model:visible="openDropdown"
          :position="position"
          :fullwidth="fullwidth"
          :floating="floating"
          :keyup="keyup"
          :keydown="keydown"
          :query="query"
          :parent="selectorRef"
          :disableSort="disableSort"
          v-slot="slotProps"
          class="m-selector__dropdown"
          @update:selected="select"
          @mouseenter="keepDropdownOpen"
        >
          <slot :option="slotProps.option">
            {{
              slotProps.option.value == "divider"
                ? ""
                : t(slotProps.option.label)
            }}
          </slot>
        </m-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Selector component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector
 */

import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";

import useDropdown from "@hooks/useDropdown";
import MDropdown from "@components/MDropdown.vue";
import MIcon from "@components/MIcon.vue";
import { useAppStore } from "@root/store/app";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `%cComponent id: ${id}`,
          "font-weight:bold",
          `\n Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  selected: { type: [Number, String, Array, Object, Boolean] },
  size: {
    type: String,
    default: "default",
  },
  outsideLabel: { type: String },
  insideLabel: { type: String },
  selectedLabel: { type: String },
  placeholder: { type: String },
  info: { type: String },
  required: { type: Boolean, default: false },
  options: {
    type: Array,
    default: () => [],
    required: false,
  },
  type: {
    type: String,
    default: "default",
  },
  dateSpecs: {
    type: Object,
    default: () => ({ mode: "date", timeframes: false, columns: 1 }),
  },
  variant: {
    type: String,
    default: "outlined",
  },
  position: {
    type: Array,
    default: () => ["right", "bottom"],
  },
  disableSort: { type: Boolean, default: false },
  tooltip: { type: [String, Object] },
  floating: { type: Boolean, default: false },
  selectedDetailed: { type: Boolean, default: false },
  canSelectAll: { type: Boolean, default: false },
  fullwidth: { type: Boolean, default: false },
  search: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  autoFocus: { type: Boolean, default: false },
});

const emit = defineEmits(["update:selected", "mouseenter", "close"]);

// Props validation
watch(
  () => props,
  () => {
    // size validator
    if (!["default", "small"].includes(props.size)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "size": expected string with value "default" or "small" and got "${props.size}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
      );
    }

    // options validator
    props.options?.forEach((opt) => {
      if (typeof opt != "object") {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "options": all array elements have to be objects. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
        );
      } else {
        if (opt.value === undefined || opt.value === null) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "value" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
          );
        }
        if (
          opt.value != "divider" &&
          (opt.label === undefined || opt.label === null)
        ) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "label" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
          );
        }
      }
    });

    // type validator
    if (!["default", "multiple", "date"].includes(props.type)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "type": expected string with value "default", "multiple" or "date" and got "${props.type}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
      );
    }

    // dateSpecs validator
    if (props.dateSpecs) {
      if (!["date", "range"].includes(props.dateSpecs?.mode)) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "dateSpecs": expected attribute "mode" with value "date" or "range" and got ${props.dateSpecs?.mode}. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
      if (typeof props.dateSpecs?.timeframes != "boolean") {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "dateSpecs": expected attribute "timeframes" to be a boolean. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
        );
      }
      if (![1, 2].includes(props.dateSpecs?.columns)) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "dateSpecs": expected attribute "columns" with value with value 1 or 2 and got ${props.dateSpecs.columns}. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
    }

    // variant validator
    if (!["outlined", "simple"].includes(props.variant)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "variant": expected string with value "outlined" or "simple" and got "${props.variant}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
      );
    }

    // position validator
    if (props.position?.length != 2) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "position": expected an array with two children defining the [ x, y] positioning. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
      );
    } else {
      if (!["left", "right"].includes(props.position[0])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the first element of the array to be string with value "left" or "right" and got "${props.position[0]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
      if (!["top", "bottom"].includes(props.position[1])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the second element of the array to be string with value "top" or "bottom" and got "${props.position[1]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
    }

    // tooltip validator
    if (typeof props.tooltip == "object") {
      if (
        props.tooltip?.content === undefined ||
        props.tooltip?.content === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "content" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
        );
      }
      if (
        props.tooltip?.position === undefined ||
        props.tooltip?.position === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "position" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
        );
      } else if (
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(props.tooltip?.position)
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "tooltip": expected attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${props.tooltip?.position}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
    }

    // label validator
    if (props.insideLabel && props.outsideLabel) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        '\n Trying to use mutually exclusive props: use "outsideLabel" or "insideLabel". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
      );
    }
  },
  { immediate: true }
);

const { t } = useI18n();
const appStore = useAppStore();

//const { dateFormat } = useDateFormat();
const { openDropdown, toggleDropdown, keepDropdownOpen, closeDropdown } =
  useDropdown();

const wrapperRef = ref(null);
const selectorRef = ref(null);
const dropdownRef = ref(null);

const requiredTooltip = computed(() => {
  return {
    content: t("general.forms_input_required"),
    position: "dynamic",
  };
});

const infoTooltip = computed(() => {
  return {
    content: props.info,
    position: "top-left",
  };
});

const iconSize = computed(() => {
  if (props.size == "max-width") return "default";
  else return props.size;
});

const setSelected = computed(() => {
  if (props.type == "date") {
    /** If the type is date the selected prop must be a of @type {Monitio.DateRestriction} */
    if (props.selected.isRelative) {
      /** Got to consired the timeFrame as a string only (instead of the TimeFrame type) for reasons related to the parsing of the URL object */
      const timeFrame =
        props.selected.timeFrame.value || props.selected.timeFrame;
      return { label: t(`general.time.${timeFrame}`) };
    } else {
      let label = props.selected.start.toISODate();
      if (props.selected.end?.isValid) {
        // Also check to see if the start and end days are the same
        if (props.selected.end.diff(props.selected.start, "days").days > 1)
          label += ` - ${props.selected.end.toISODate()}`;
      }
      return { label: label };
    }
  } else if (props.type == "multiple") {
    if (
      props.selected?.length == props.options?.length ||
      props.selected?.find((f) => f == "all")
    ) {
      return (
        props.options?.find((f) => f.value == "all") ?? {
          value: "all",
          label: t("components.selector.allSelected"),
        }
      );
    } else {
      const s = [];
      props.selected?.forEach((fe) => {
        const f = props.options?.find((f) => f.value == fe);
        s.push(f);
      });
      return s;
    }
  } else return props.options?.find((f) => f.value == props.selected);
});

const multipleSelectionLabel = computed(() => {
  if (props.selectedLabel) return props.selectedLabel;
  else if (props.selectedDetailed) {
    if (setSelected.value?.length) {
      return setSelected.value.map((m) => m.label).join(", ");
    } else return setSelected.value?.label || null;
  } else if (setSelected.value?.value == "all") {
    return t("components.selector.allSelected");
  } else {
    return t("components.selector.nSelected", {
      n: setSelected.value?.length,
    });
  }
});

const select = (val) => {
  emit("update:selected", val);
  if (props.type == "date" && !val.isRelative) {
    closeDropdown();
    return;
  }
  if (props.type != "multiple") closeDropdown();
};

const closeSelector = () => {
  closeDropdown();
};

const query = ref("");
const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  if (evt?.keyCode == 38 || evt?.keyCode == 40) {
    evt.preventDefault();
  }
  keyup.value = evt;

  if (evt.code.startsWith("Key")) {
    if (!openDropdown.value) openDropdown.value = true;
    query.value += evt.key;
    setTimeout(() => {
      query.value = "";
    }, 500);
  }
};

const keyDownMovement = (evt) => {
  if (evt?.keyCode == 38 || evt?.keyCode == 40) {
    evt.preventDefault();
  }
  keydown.value = evt;
};

onMounted(() => {
  selectorRef.value.addEventListener("keyup", keyUpMovement);
  selectorRef.value.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  selectorRef.value?.removeEventListener("keyup", keyUpMovement);
  selectorRef.value?.removeEventListener("keydown", keyDownMovement);
});

const ariaLabelledby = computed(() => {
  let pos;
  if (props.outsideLabel) {
    pos = "outside";
  } else if (props.insideLabel) {
    pos = "inside";
  } else {
    pos = "hidden";
  }

  return `m_selector_${pos}_label_${props.id}`;
});

const ariaActivedescendant = computed(() => {
  if (openDropdown.value) {
    return `m_selector_dropdown_${props.id}_option_${setSelected.value?.value}`;
  } else return "";
});

const focusSelector = () => {
  selectorRef.value.focus();
};

defineExpose({
  focus: focusSelector,
});
</script>
