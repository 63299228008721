<template>
  <div
    class="m-report"
    :class="[`m-report--${viewsStore.nav}`, { 'm-report--empty': !report }]"
  >
    <div id="scrollable" class="m-container">
      <div
        v-if="route.name == 'reports-empty'"
        class="m-container__wrapper m-container__wrapper--not-found"
      >
        <h2 class="type--small type--empty">
          {{ t("reports.empty_title") }}
        </h2>
        <h6
          v-html="t('reports.empty_description')"
          class="type--small type--empty type--center"
        ></h6>
        <m-button
          id="m_report_create"
          :label="t('reports.empty_action')"
          type="contained"
          variant="primary"
          size="small"
          class="mt-3"
          @click="router.push({ name: 'reports' })"
        />
      </div>
      <div
        v-else-if="report"
        class="m-container__wrapper"
        :class="`m-container__wrapper--${visualization}`"
      >
        <m-report :report="template" @update-module-data="updateModuleData" />
      </div>
      <div v-else class="m-report__not-found">
        <h2 class="type--small type--empty">
          {{ t("reports.notFound") }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useReportsStore } from "@root/store/modules/reports";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";

import MButton from "@components/MButton.vue";
import MReport from "@components/report/MReport.vue";

import { eventBus } from "@root/utils/eventBus";
import useSendReportModal from "@root/components/modals/MSendReport/useSendReportModal";
import { useFiltersStore } from "@root/store/modules/filters";

const { t } = useI18n();
const reportsStore = useReportsStore();
const alertsStore = useAlertsStore();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const filtersStore = useFiltersStore();
const route = useRoute();
const router = useRouter();
const { open: openSendReport } = useSendReportModal();

const viewId = computed(() => route.params.viewId);
const reportId = computed(() => route.params.reportId);

const visualization = computed(() => {
  if (route.path.includes("builder")) return "editor";
  else return "preview";
});

/** @type {import("vue").ComputedRef<import("@root/types.api.local").MonitioAPI.ReportDTO | null>} */
const report = ref(null);
const template = ref(null);
/** the data for each module is within each module but its also here, so we can create the snapshots */
const moduleData = ref([]);
const isDraft = computed(() => false);

const hasReports = computed(() => reportsStore?.getAll?.length);

watch(
  () => reportId.value,
  (val) => {
    if (!val) return;
    const tempReport = reportsStore.getById(val);
    if (tempReport.recurrencyInfo?.dateRestriction)
      filtersStore.updateDateRestriction(
        tempReport.recurrencyInfo?.dateRestriction,
        route,
        router
      );
    report.value = reportsStore.getById(val);
    template.value = reportsStore.getTemplateById(report.value.template.id);
  },
  { immediate: true }
);

const sendReport = () => {
  openSendReport(report.value, template.value, moduleData.value);
};
eventBus.on("send-report", sendReport);

const exportCSV = () => undefined;
eventBus.on("export-csv", exportCSV);

const updateModuleData = (data, id) => {
  const existingData = moduleData.value.find((x) => x.id == id);
  if (existingData) {
    existingData.data = data;
  } else {
    moduleData.value.push({ id, data });
  }
};

onUnmounted(() => {
  eventBus.off("send-report", sendReport);
});
</script>

<style scoped lang="scss">
.m-report {
  width: 100%;
  max-width: 100% !important;

  &--empty {
    @include flex(center, center, column);
  }

  :deep(.m-header-filters) {
    align-self: flex-end;
  }

  .m-container {
    width: 100%;
    padding: $spacing-0;
    margin: $spacing-0;

    &__wrapper {
      width: 100%;
      padding: $spacing-0;

      &--editor {
        display: grid;
        grid-template-columns: 1fr calc($toolbox-width + $spacing-2);
        grid-template-rows: auto;
        grid-template-areas: "report toolbox";
        grid-column-gap: $spacing-2;
      }

      &--not-found {
        @include flex(center, center, column);
        text-align: center;
        white-space: pre-line;
      }
    }
  }

  &--html {
    height: calc(100% - $spacing-6);
  }

  :deep(.m-report) {
    grid-area: report;
  }
  :deep(.m-toolbox) {
    grid-area: toolbox;
  }
}
</style>
