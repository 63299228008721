<template>
  <div
    class="m-saved m-wrapper"
    :class="`m-saved--${layout} m-wrapper--${viewsStore.nav}`"
  >
    <div ref="containerRef" id="scrollable" class="m-container">
      <div class="m-container__wrapper">
        <m-image :src="not_found_illustration" class="m-not-found__image" />
        <h2 class="type--small type--empty">
          {{ title }}
        </h2>
        <h6 class="mb-2 type--small type--empty type--center">
          {{ t(`notFound.${breadcrumbs[2]}_description`) }}
        </h6>
        <m-button
          id="m_not_found"
          :label="t(`notFound.${breadcrumbs[2]}_add`)"
          type="contained"
          variant="primary"
          size="xsmall"
          @click="add"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useViewsStore } from "@root/store/modules/views";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useReportsStore } from "@root/store/modules/reports";

import structuredClone from "@utils/structuredClone";

import MImage from "@components/MImage.vue";
import MButton from "@components/MButton.vue";

import not_found_illustration from "@assets/illustrations/not_found.svg";
import { useFiltersStore } from "@root/store/modules/filters";

const { t } = useI18n();
const { currentRoute, push } = useRouter();
const route = useRoute();
const router = useRouter();
const viewsStore = useViewsStore();
const filtersStore = useFiltersStore();
const dashboardsStore = useDashboardsStore();
const reportsStore = useReportsStore();

const layout = computed(() => viewsStore.layout);

const breadcrumbs = computed(() => currentRoute.value.meta.breadcrumbs);
const viewId = computed(() => currentRoute.value?.params?.viewId);
const dashboard = computed(() =>
  dashboardsStore.getById(currentRoute.value?.params?.dashboardId)
);
const dashboardName = computed(() => dashboard.value?.name);
const report = computed(() =>
  reportsStore.getById(currentRoute.value?.params?.templateId)
);
const reportName = computed(() =>
  reportsStore.getNameById(currentRoute.value?.params?.templateId)
);

const title = computed(() => {
  let name;
  if (dashboardName.value) {
    name = dashboardName.value;
  } else if (reportName.value) {
    name = reportName.value;
  }

  return t(`notFound.${breadcrumbs.value[2]}_title`, {
    name,
  });
});

const updateDashboard = async () => {
  const clone = structuredClone(dashboard.value);
  clone.showedIn.push(viewId.value);
  const result = await dashboardsStore.save(clone, viewId.value);

  if (result == null) {
    alert("dashboard couldnt be added");
  } else {
    push({
      name: "dashboard",
      params: {
        ...currentRoute.value.params,
        viewId: viewId.value,
      },
    });
  }
};

const updateReport = async () => {
  const clone = structuredClone(report.value);
  // missing adding to list
  const result = await reportsStore.saveTemplate(
    clone,
    viewId.value,
    filtersStore.dateRestriction
  );

  if (result == null) {
    alert("report couldnt be added");
  } else {
    push({
      name: "report",
      params: {
        ...currentRoute.value.params,
        viewId: viewId.value,
      },
    });
  }
};

const add = () => {
  switch (breadcrumbs.value[2]) {
    case "dashboards":
      updateDashboard();
      break;
    case "reports":
      updateReport();
      break;
    default:
      break;
  }
};
</script>

<style scoped lang="scss">
.m-container {
  width: 100%;
  height: calc(100vh - $header-height - $spacing-4);

  &__wrapper {
    height: calc(100vh - $header-height);
    padding-bottom: $spacing-14;
    @include flex(center, center, column);

    .m-not-found__image {
      width: $column-5;
    }
  }
}
</style>
