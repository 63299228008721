import { defineStore } from "pinia";
import type { MonitioAPI } from "@root/types.api.local";
import type { Monitio } from "@root/types";

import DateTimeUtils from "@utils/dateTime";
import TimeFrame from "@utils/enums/timeFrames";
import {
  type RouteLocationNormalizedLoadedGeneric,
  type RouteLocationRaw,
  type RouteParamsRawGeneric,
  type Router,
} from "vue-router";
import { DateTime, Interval } from "luxon";
import { useUserStore } from "@root/store/modules/user";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useReportsStore } from "@root/store/modules/reports";
import { cloneDeep } from "lodash-es";

interface FilterStoreState {
  moduleHasInit: boolean;
  latestApiCallParams: Monitio.FilterQueryParams | null;
  latestResult: MonitioAPI.FilterNavigator | null;
  queryObjectRaw?: string;
  currentViewId?: string;
}

//#region DEFAULT VALUES
/**
 * @description Default value for the components that use relative/absolutes dates,
 * like for example the date selector in the subheader, and all the pages that need to query
 * the backend
 * @description This default must match with the one in the searchmodule in the API
 */
const DEFAULT_DATERESTRICTION: MonitioAPI.DateRestriction = {
  isRelative: true,
  timeFrame: TimeFrame.Today.value,
};

//@ts-ignore
const DEFAULT_AGGREGATOR = window._env_.VUE_APP_DEFAULT_AGGREGATOR;
//@ts-ignore
const DEFAULT_GRANULARITY = window._env_.VUE_APP_DEFAULT_GRANULARITY;
//@ts-ignore
const DEFAULT_PREFERENCE = window._env_.VUE_APP_DEFAULT_PREFERENCE;
//@ts-ignore
const DEFAULT_GRAPHTYPE = window._env_.VUE_APP_DEFAULT_GRAPHTYPE;
//@ts-ignore
const DEFAULT_REFINEBY = window._env_.VUE_APP_DEFAULT_REFINEBY;
//@ts-ignore
const DEFAULT_SORT = window._env_.VUE_APP_DEFAULT_SORT;
//#endregion

export const useFiltersStore = defineStore("filters", {
  state: (): FilterStoreState => ({
    moduleHasInit: false,
    /** This is the most recent filter data, its retrieved directly from search */
    latestApiCallParams: null,
    latestResult: null,
    /** Query that the user sees in the URL if any. 🚨🚩 This gets updated on navigation by the router. @see router.js */
    queryObjectRaw: undefined,
    currentViewId: undefined,
  }),
  getters: {
    queryObject(state): Monitio.URLQueryObject {
      return state.queryObjectRaw
        ? decodeQueryObject(state.queryObjectRaw)
        : ({} as Monitio.URLQueryObject);
    },
    dateRestriction(): MonitioAPI.DateRestriction {
      const userStore = useUserStore();
      const workspacesStore = useWorkspacesStore();
      const workspace = workspacesStore.currentWorkspaceConfig!;

      /** Check the query for the date parameter */
      const { dateRestriction } = this.queryObject as Monitio.URLQueryObject;
      if (dateRestriction) return dateRestriction;

      /** If there's not a currently selected restrcition, output the defaults: */
      const newwestDocumentDate =
        DateTimeUtils.parseFromISO(
          workspace.documentDateRange?.newest as unknown as string
        ) ?? DateTime.utc();
      const oldestDocumentDate =
        DateTimeUtils.parseFromISO(
          workspace.documentDateRange?.oldest as unknown as string
        ) ?? DateTime.utc().minus({ week: 1 });
      const workspaceDateRestriction =
        workspace?.metadata?.defaultDateRestriction ?? DEFAULT_DATERESTRICTION;

      const { start, end } = DateTimeUtils.getTimeFrame(
        workspaceDateRestriction.timeFrame as MonitioAPI.TimeFrame,
        userStore.timeZone
      );
      const interval = Interval.fromDateTimes(
        oldestDocumentDate,
        newwestDocumentDate.endOf("day").plus({ day: 1 })
      );

      if (!interval.contains(start) || !interval.contains(end)) {
        //This means this workspace either is an archive with old articles, or its not update to date, either way we must not return the today relative dateRestriction
        return {
          isRelative: true,
          timeFrame: TimeFrame.Latest.value,
        };
        /* if (route.name == "trending-entities") {
          return {
            isRelative: false,
            start: newwestDocumentDate.startOf("day").minus({ days: 7 }),
            end: newwestDocumentDate.endOf("day"),
          };
        } else {
          return {
            isRelative: false,
            start: newwestDocumentDate.startOf("day"),
            end: newwestDocumentDate.endOf("day"),
          };
        } */
      } else {
        return workspaceDateRestriction;
      }
    },
    sortBy(): MonitioAPI.SearchSortBy {
      const cache = getViewFiltersCache<MonitioAPI.SearchSortBy>(
        "sortBy",
        this.currentViewId
      );
      if (cache) return cache;
      const { sortBy } = this.queryObject;
      if (sortBy) return sortBy;
      return DEFAULT_SORT ?? "relevance";
    },
    aggregateDuplicates(): boolean {
      const cache = getViewFiltersCache<boolean>(
        "aggregateDuplicates",
        this.currentViewId
      );
      if (cache) return cache;
      const { aggregateDuplicates } = this
        .queryObject as Monitio.URLQueryObject;
      if (aggregateDuplicates == null || aggregateDuplicates == undefined)
        return true;
      return aggregateDuplicates;
    },
    aggregator(): string {
      const workspacesStore = useWorkspacesStore();
      const userStore = useUserStore();
      const cache = getViewFiltersCache<string>(
        "aggregator",
        this.currentViewId
      );
      if (cache) return cache;
      const { aggregator } = this.queryObject as Monitio.URLQueryObject;
      if (aggregator) return aggregator;

      //Check the backend for defaults
      const workspaceId = workspacesStore.id;
      const values = userStore.config.propertyTypeSettings?.storylines?.[
        workspaceId!
      ]
        .filter((x) => x.active && x.selected)
        ?.sort((a, b) => a.rank! - b.rank!);

      if (!values)
        //If everthring goes wrong return the default aggregator
        return DEFAULT_AGGREGATOR;

      if (
        values?.length > 0 &&
        values.map((x) => x.searchKey).includes(DEFAULT_AGGREGATOR)
      ) {
        return DEFAULT_AGGREGATOR;
      } else {
        return values[0]!.searchKey!;
      }
    },
    refineBy() {
      const userStore = useUserStore();
      const workspacesStore = useWorkspacesStore();
      const workspace = workspacesStore.currentWorkspaceConfig;
      return (route: RouteLocationNormalizedLoadedGeneric): string[] => {
        const cache = getViewFiltersCache<string[]>(
          "refineBy",
          this.currentViewId
        );
        if (cache) return cache;
        const { refineBy } = this.queryObject as Monitio.URLQueryObject;
        if (refineBy) return refineBy;

        // DEFAULT_REFINEBY
        let result;
        if (route.name == "trending-entities") {
          result =
            userStore.config?.propertyTypeSettings?.trending?.[workspace.id!];
        } else if (route.name == "entity-network") {
          result =
            userStore.config?.propertyTypeSettings?.mindmap?.[workspace.id!];
        }

        result =
          result
            ?.filter((x) => x.active && x.selected)
            .map((m) => m.searchKey) ?? [];

        if (result?.length > 0) return result as string[];
        return DEFAULT_REFINEBY;
      };
    },
    preference(): MonitioAPI.EntityWalkSortMethod {
      const cache = getViewFiltersCache<MonitioAPI.EntityWalkSortMethod>(
        "preference",
        this.currentViewId
      );
      if (cache) return cache;
      const { preference } = this.queryObject as Monitio.URLQueryObject;
      if (preference) return preference;
      return DEFAULT_PREFERENCE;
    },
    graphType(): MonitioAPI.EntityWalkGraphType {
      const cache = getViewFiltersCache<MonitioAPI.EntityWalkGraphType>(
        "graphType",
        this.currentViewId
      );
      if (cache) return cache;
      const { graphType } = this.queryObject as Monitio.URLQueryObject;
      if (graphType) return graphType;
      return DEFAULT_GRAPHTYPE;
    },
    granularity(): Monitio.Granularity {
      const { granularity } = this.queryObject as Monitio.URLQueryObject;
      if (granularity) return granularity;
      return DEFAULT_GRANULARITY;
    },
    viewId(): string | null {
      const { viewId } = this.queryObject as Monitio.URLQueryObject;
      if (viewId) return viewId;
      return null;
    },
    refineByTrendingOpts() {
      const userStore = useUserStore();
      const workspacesStore = useWorkspacesStore();
      const workspaceId = workspacesStore.id!;

      const userWorkspaceDetails =
        userStore.config?.propertyTypeSettings?.trending?.[workspaceId].filter(
          (x) => x.active
        );

      if (userWorkspaceDetails) {
        return userWorkspaceDetails
          .map((x) => ({
            value: x.searchKey,
            label: `general.facets.${x.uiKey}`,
            selected: x.selected,
          }))
          .sort((a, b) => a.label!.localeCompare(b.label!));
      }
      return [{ value: "iptc", label: "general.facets.IPTC" }];
    },
    refineByMindmapOpts() {
      const userStore = useUserStore();
      const workspacesStore = useWorkspacesStore();
      const workspaceId = workspacesStore.id!;
      const userWorkspaceDetails =
        userStore.config?.propertyTypeSettings?.mindmap?.[workspaceId].filter(
          (x) => x.active
        );

      if (userWorkspaceDetails) {
        return userWorkspaceDetails
          .map((x) => ({
            value: x.searchKey,
            label: `general.facets.${x.uiKey}`,
            selected: x.selected,
          }))
          .sort((a, b) => a.label!.localeCompare(b.label!));
      }
      return [{ value: "iptc", label: "general.facets.IPTC" }];
    },
    granularityOpts() {
      const options = getAvailableGranularityOpts(this.dateRestriction);

      return (
        options?.map((option) => ({
          value: option,
          label: `navigation.header.filters.granularity_${option}`,
        })) || []
      );
    },
    aggregatorOpts() {
      const userStore = useUserStore();
      const workspacesStore = useWorkspacesStore();
      const workspaceId = workspacesStore.id!;
      return (
        userStore.config.propertyTypeSettings?.storylines?.[workspaceId]
          ?.filter(
            (x) =>
              x.active &&
              x.appearsOnSection?.includes(
                "storylines" as MonitioAPI.WorkspaceUserSettingsSection.Storylines
              )
          )
          .map((x) => ({
            value: x.searchKey,
            label: x.uiKey,
          })) ?? []
      );
    },
  },
  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Filter module already initialized");
        return;
      }
      this.moduleHasInit = true;
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
    async updateLatestApiCallParams(params: Monitio.FilterQueryParams) {
      this.latestApiCallParams = params;
    },
    async updateLatestResult(result: MonitioAPI.FilterNavigator) {
      this.latestResult = result;
    },
    async updateQueryObject(
      viewQuery: Monitio.URLQueryObject,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      const params: RouteLocationRaw = {
        name: route.name,
        params: route.params,
      };
      if (areFiltersEmpty(viewQuery.filters)) delete viewQuery.filters;

      const base64 = encodeQueryObject(viewQuery);
      if (Object.keys(viewQuery).length > 0)
        params.query = { ...route.query, q: base64 };

      this.queryObjectRaw = base64;
      router.push(params);
    },
    async clearQueryObjectProps(
      keys: Array<keyof Monitio.URLQueryObject>,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      const params: RouteLocationRaw = {
        name: route.name,
        params: route.params,
      };

      const qObj = this.queryObject;
      for (const key of keys) {
        if (qObj[key]) delete qObj[key];
      }
      this.queryObjectRaw = encodeQueryObject(qObj);
      qObj.clear = true; // This will indicate to router.js that it can clear the filters in case they are empty
      const base64 = encodeQueryObject(qObj);
      params.query = { ...route.query, q: base64 };
      router.push(params);
    },
    /**
     * @description ATTENTION: This function REPLACES all the filters.
     * To get the current filters use queryObject.filters.
     * Do your thing and then update using updateQueryObject mehtod
     */
    async updateViewFilters(
      filters: MonitioAPI.FrontendFiltersGroup[],
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      const query = { ...this.queryObject };
      query.filters = filters;

      if (!query.filters || query.filters?.length == 0) delete query.filters;
      this.updateQueryObject(query, route, router);
    },
    async updateSortBy(
      val: MonitioAPI.SearchSortBy,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      setViewFiltersCache<MonitioAPI.SearchSortBy>(
        "sortBy",
        val,
        this.currentViewId
      );
      const query = { ...this.queryObject };
      if (!val) {
        delete query.sortBy;
      } else {
        query.sortBy = val;
      }
      this.updateQueryObject(query, route, router);
    },
    async updateAggregateDuplicates(
      val: boolean,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      setViewFiltersCache<boolean>(
        "aggregateDuplicates",
        val,
        this.currentViewId
      );
      const query = { ...this.queryObject };
      if (val === null || val === undefined) {
        delete query.aggregateDuplicates;
      } else {
        query.aggregateDuplicates = val;
      }
      this.updateQueryObject(query, route, router);
    },
    async updateAggregator(
      val: string,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      setViewFiltersCache<string>("aggregator", val, this.currentViewId);
      const query = { ...this.queryObject };
      if (!val) {
        delete query.aggregator;
      } else {
        query.aggregator = val;
      }
      this.updateQueryObject(query, route, router);
      // viewsStore.setAggregator(val); Need to verifify if we want this in the store
    },
    async updateRefineBy(
      val: string[],
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      setViewFiltersCache<string[]>("refineBy", val, this.currentViewId);
      const query = { ...this.queryObject };
      if (!val) {
        delete query.refineBy;
      } else {
        query.refineBy = val;
      }
      this.updateQueryObject(query, route, router);
    },
    async updatePreference(
      preference: MonitioAPI.EntityWalkSortMethod,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      setViewFiltersCache<MonitioAPI.EntityWalkSortMethod>(
        "preference",
        preference,
        this.currentViewId
      );
      const query = { ...this.queryObject };
      if (!preference) {
        delete query.preference;
      } else {
        query.preference = preference;
      }
      this.updateQueryObject(query, route, router);
    },
    async updateGraphType(
      graphType: MonitioAPI.EntityWalkGraphType,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      setViewFiltersCache<MonitioAPI.EntityWalkGraphType>(
        "graphType",
        graphType,
        this.currentViewId
      );
      const query = { ...this.queryObject };
      if (!graphType) {
        delete query.graphType;
      } else {
        query.graphType = graphType;
      }
      this.updateQueryObject(query, route, router);
    },
    async updateGranularity(
      granularity: Monitio.Granularity,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      const query = { ...this.queryObject };
      if (!granularity) {
        delete query.granularity;
      } else {
        query.granularity = granularity;
      }
      this.updateQueryObject(query, route, router);
    },
    async updateDateRestriction(
      value: TimeFrame | MonitioAPI.DateRestriction,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      const query = { ...this.queryObject };
      if (value instanceof TimeFrame) {
        query.dateRestriction = {
          isRelative: true,
          timeFrame: value.value,
        };
      } else if (value instanceof Object) {
        if (value.isRelative && value.timeFrame) {
          query.dateRestriction = { ...value };
        }
        if (!value.isRelative && value.start && value.end) {
          query.dateRestriction = { ...value };
        }
      } else {
        throw new Error(
          "The date format is not recognized as relative or absolute."
        );
      }

      query.dateRestriction ??= {};
      query.dateRestriction.reportId = route.params.reportId as string;
      const options = getAvailableGranularityOpts(query.dateRestriction);
      //Override the granularity else monitio will crash the browser because its to much data
      query.granularity = options.pop();

      this.updateQueryObject(query, route, router);
    },
    updateViewId(
      val: string | null,
      route: RouteLocationNormalizedLoadedGeneric,
      router: Router
    ) {
      const query = { ...this.queryObject };
      if (!val) {
        delete query.viewId;
      } else {
        query.viewId = val;
      }
      this.updateQueryObject(query, route, router);
    },
  },
});

//#region Utils functions to help the store
/**
 * @description returns the granularity options that are available for the
 * timeframe given. This has a limit beucase of the shear amount of data if
 * we have a huge timeframe with a low granularity
 */
const getAvailableGranularityOpts = (
  dateresctrition: MonitioAPI.DateRestriction
): Monitio.Granularity[] => {
  const workspacesStore = useWorkspacesStore();
  const workspace = workspacesStore.currentWorkspaceConfig;
  const userStore = useUserStore();
  const timeZone = userStore.timeZone;
  let absoluteDate = dateresctrition;

  if (dateresctrition.isRelative) {
    const reportId = dateresctrition.reportId;
    const reportStore = useReportsStore();

    const report = reportStore.getById(reportId);
    absoluteDate = DateTimeUtils.getTimeFrame(
      dateresctrition.timeFrame!,
      timeZone,
      workspace.documentDateRange,
      //@ts-ignore
      report
    );
  }
  const options: Monitio.Granularity[] = [];

  const dE: DateTime =
    absoluteDate.end instanceof DateTime
      ? absoluteDate.end
      : DateTime.fromJSDate(absoluteDate.end as unknown as Date);
  const dS: DateTime =
    absoluteDate.start instanceof DateTime
      ? absoluteDate.start
      : DateTime.fromJSDate(absoluteDate.start as unknown as Date);

  options.push("day");
  if (Math.abs(dE.diff(dS, "weeks").weeks) > 1) options.push("week");
  if (Math.abs(dE.diff(dS, "months").months) > 1) options.push("month");
  /*     if (Math.abs(dE.diff(dS, "months").months) > 3)
      options = options.filter((x) => x != "day"); */
  if (Math.abs(dE.diff(dS, "years").years) > 1) {
    //options = options.filter((x) => x != "week");
    options.push("year");
  }
  /*if (Math.abs(dE.diff(dS, "years").years) > 3)
      options = options.filter((x) => x != "month"); */

  return options;
};

/**
 * @param {string} q A base64 string that lives no the browser url query
 * @returns the decoded and parsed object
 */
export const decodeQueryObject = (q: string): Monitio.URLQueryObject => {
  if (!q) return {};
  const query: Monitio.URLQueryObject = JSON.parse(
    decodeURIComponent(escape(window.atob(q)))
  );
  /** Before returning we need to convert the datetimes back into luxon Datetime
   *  Because they get conefrted into a specific string format */
  if (query.dateRestriction && !query.dateRestriction.isRelative) {
    query.dateRestriction.start = DateTimeUtils.parseFromISO(
      query.dateRestriction.start as unknown as string
    );
    query.dateRestriction.end = DateTimeUtils.parseFromISO(
      query.dateRestriction.end as unknown as string
    );
  }
  return query;
};

/**
 * @description Provide a route and an encoded or decoded URLQueryObject and
 * it navigates to that route with the queryObject attached
 */
export const navigateWithQueryObject = (
  route: RouteLocationNormalizedLoadedGeneric,
  router: Router,
  q: Monitio.URLQueryObject,
  routeName: string,
  params: RouteParamsRawGeneric,
  meta = null
) => {
  const query = cloneDeep(q);
  if (typeof query != "object")
    throw new Error("Filter object must be an object!");
  if (query.dateRestriction) {
    //make sure the dateResctrition is in JSDate format to avoid issues
    if (DateTime.isDateTime(query.dateRestriction.start))
      query.dateRestriction.start =
        query.dateRestriction.start.toJSDate() as unknown as DateTime;
    if (DateTime.isDateTime(query.dateRestriction.end))
      query.dateRestriction.end =
        query.dateRestriction.end.toJSDate() as unknown as DateTime;
  }
  router.push({
    name: routeName,
    params: params,
    query: { ...route.query, q: encodeQueryObject(query) },
    //meta,
  });
};

/** @description Function that checks if the filter objet is empty */
const areFiltersEmpty = (filters?: MonitioAPI.FrontendFiltersGroup[]) => {
  if (!filters) return true;
  if (filters.every((x) => !x.facets?.length)) return true;
  return false;
};

export const encodeQueryObject = (viewQuery: Monitio.URLQueryObject) => {
  const b64 = btoa(unescape(encodeURIComponent(JSON.stringify(viewQuery))));
  return b64;
};

/**
 * @description Retrieve localStorage cache if any
 * This localStorage item will be deleted at the end of a monitio session!!! @store.js
 */
const getViewFiltersCache = <T>(key: string, viewId?: string): T | null => {
  const currentViewId = viewId ?? "";
  const cache = JSON.parse(localStorage.getItem("viewFiltersCache") ?? "{}");
  return (cache[currentViewId]?.[key] as T) ?? null;
};

/**
 * @description Update or create entry in localStorage cache.
 * This localStorage item will be deleted at the end of a monitio session!!! @store.js
 */
const setViewFiltersCache = <T>(key: string, value: T, viewId?: string) => {
  const currentViewId = viewId ?? "";
  const cache = JSON.parse(localStorage.getItem("viewFiltersCache") ?? "{}");
  cache[currentViewId] ??= {};
  cache[currentViewId][key] = value;
  localStorage.setItem("viewFiltersCache", JSON.stringify(cache));
};

/**
 * @description Function that returns the same filter array but withtout any malformatting errors that might exists
 */
export const rectifyViewFilters = (
  filterGroups: MonitioAPI.FrontendFiltersGroup[]
): MonitioAPI.FrontendFiltersGroup[] => {
  for (const filterGroup of filterGroups) {
    if (!filterGroup.facets) continue;
    for (const facet of filterGroup.facets) {
      if (!facet.query) continue;
      //The last item should not ever be an operator.Remove it if so
      if (facet.query.at(-1)?.operator) facet.query.pop();
    }
  }
  return filterGroups;
};

//#endregion

export class ViewFiltersUtils {
  static async selectFilter(f: MonitioAPI.FrontendFiltersFacet[], value: any) {
    if (!value) return;
    const filters = f ? [...f] : [];

    const selectedFilterGroup = filters.find(
      (group) => group.value == value.filter
    );

    if (selectedFilterGroup) {
      const facet = selectedFilterGroup.query!.find(
        (f) => f.value == value.value
      );
      if (facet) {
        const groupIdx = filters.findIndex(
          (group) => group.value == value.filter
        );
        const idx = selectedFilterGroup.query!.findIndex(
          (f) => f.value == value.value
        );

        if (value.pane && !value.selected && !value.negated) {
          this.removeFilter(filters, groupIdx, idx);
        } else if (
          (!value.negated && !facet.negated) ||
          value.negated == facet.negated
        ) {
          // Do nothing
        } else {
          this.toggleNegated(filters, value.value, groupIdx, idx);
        }
      } else {
        const rowOperator = this.checkQueryOperator(
          selectedFilterGroup.query ?? []
        );
        if (value.negated) {
          selectedFilterGroup.query!.unshift(
            {
              label: value.label,
              value: value.value,
              propertyType: value.filter,
              negated: value.negated ?? false,
            },
            {
              operator: "and" as MonitioAPI.QueryOperator,
            }
          );
        } else {
          if (!selectedFilterGroup.query!.slice(-1)?.[0].operator) {
            /** Safe measure here to avoid bugs. Check if the last item of the array is an operator. If it isn't add it. The last item of the aray must always be an operator */
            selectedFilterGroup.query!.push({
              operator: rowOperator as MonitioAPI.QueryOperator,
            });
          }
          selectedFilterGroup.query!.push({
            label: value.label,
            value: value.value,
            propertyType: value.filter,
            negated: value.negated ?? false,
          });
        }
        /* selectedFilterGroup.query.push({
        operator: rowOperator,
      }); */
      }
    } else {
      filters.push({
        value: value.filter,
        label: value.filterUiKey ?? value.filter,
        query: [
          {
            label: value.label,
            value: value.value,
            propertyType: value.filter,
            negated: value.negated ?? false,
          },
          {
            // If it is a collection of custom queries by default we put an AND
            operator: (value.filter == "customQuery"
              ? "and"
              : "or") as MonitioAPI.QueryOperator,
          },
        ],
      });
    }

    /** This section handles hacks the object because of some edge cases with the negation */
    if (this.areAllNegated(filters, value.filter)) {
      filters
        ?.find((group) => group.value == value.filter)
        ?.query?.forEach((item) => {
          if (item.operator) item.operator = "and" as MonitioAPI.QueryOperator;
        });
    }
    return filters;
  }

  static removeFilter(
    filters: MonitioAPI.FrontendFiltersFacet[],
    groupIdx: number,
    idx: number
  ) {
    if ((filters[groupIdx]?.query?.length ?? 0) <= 2) {
      this.removeFilterGroup(filters, groupIdx);
    } else {
      filters[groupIdx]?.query?.splice(idx, 2);
    }
    return filters;
  }

  static removeFilterGroup(
    filters: MonitioAPI.FrontendFiltersFacet[],
    groupIdx: number
  ) {
    filters.splice(groupIdx, 1);
    return filters;
  }

  static removeAllFilters(filters: MonitioAPI.FrontendFiltersFacet[]) {
    filters.length = 0;
    return filters;
  }

  /**
   * @description Checks the filter array for a certain group type, and retuns whether if the value are all engated or not
   */
  static areAllNegated(
    facets: MonitioAPI.FrontendFiltersFacet[],
    type: string
  ) {
    return (
      facets
        ?.find((group) => group.value == type)
        ?.query?.filter((x) => x.operator == undefined || x.operator == null)
        ?.every((x) => x.negated) ?? false
    );
  }

  static toggleNegated(
    filters: MonitioAPI.FrontendFiltersFacet[],
    value: any,
    groupIdx: number,
    entryIdx: number
  ): MonitioAPI.FrontendFiltersFacet[] {
    if (value == "customQuery") return filters;
    const selectedFilterGroup = filters[groupIdx];
    // remove the item 1st so we can work with it
    const items = selectedFilterGroup.query?.splice(entryIdx, 2);
    if (!items?.length) return [];
    items[0].negated = !items[0].negated;
    if (items[0].negated || !selectedFilterGroup.query!.length) {
      selectedFilterGroup.query!.unshift(items[0], {
        operator: "and" as MonitioAPI.QueryOperator,
      });
    } else {
      const rowOperator = this.checkQueryOperator(selectedFilterGroup.query);
      if (!selectedFilterGroup.query!.slice(-1)[0].operator)
        selectedFilterGroup.query!.push({
          operator: rowOperator as MonitioAPI.QueryOperator,
        });
      selectedFilterGroup.query!.push(items[0]);
    }
    return filters;
  }

  /**
   * @description Given a given [] (query) it computes what the correct operator is
   */
  static checkQueryOperator(
    q: MonitioAPI.FrontendFiltersFacetQuery[]
  ): MonitioAPI.QueryOperator {
    let rowOperator = "or";
    // 1st check. If there's no operators still return imediatly a "or"
    if (!(q.filter((x) => x.operator)?.length > 0))
      return rowOperator as MonitioAPI.QueryOperator;
    if (
      // 2nd check. If every operator till now is == "and" we also add an "and"
      q.filter((x) => x.operator).every((x) => x.operator == "and")
    )
      rowOperator = "and";
    if (
      // 3rd check. If any value is negated we default to "or" again
      q.filter((x) => x.value).some((x) => x.negated)
    )
      rowOperator = "or";
    if (
      //4th if the last value is nageted we need to add a mandatory "add" after always
      q.filter((x) => x.value).slice(-1)?.[0].negated
    )
      rowOperator = "and";

    return rowOperator as MonitioAPI.QueryOperator;
  }
}
