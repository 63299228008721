<template>
  <div ref="htmlElement" class="m-ent-rel">
    <div v-if="data" class="m-ent-rel__row">
      <svg
        ref="svgElement"
        :id="`trending-row__${snakeCase(entity.key ?? '')}`"
      ></svg>
    </div>

    <div v-else-if="!requestCompleted" class="m-ent-rel">
      <skeleton-loader
        type="image"
        width="100%"
        height="32px"
        corner="0"
        class="m-ent-rel__values"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computePMI, computePMI2 } from "@utils/utils";
import { map } from "@utils/map";
import DateTimeUtils from "@utils/dateTime";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { computed, ref, onMounted, unref, type PropType, type Ref } from "vue";
import { useApi } from "@api/api";
import { useI18n } from "vue-i18n";
//@ts-ignore: Module doesnt support typescript types yet....
import SkeletonLoader from "@priberam/skeleton-loader";
import type { MonitioAPI } from "@root/types.api.local";
import type { Monitio } from "@root/types";
import { useTooltip } from "@tooltips/useTooltip";
import { gradient } from "@utils/trendingColors";
import { debounce, delay } from "lodash-es";

import * as d3 from "d3";
import * as d3Scale from "d3-scale";
import { snakeCase } from "lodash-es";
import { init } from "@sentry/vue";
import {
  navigateWithQueryObject,
  useFiltersStore,
} from "@root/store/modules/filters";

//#region Component props and setup
interface Data {
  date: string;
  count: number;
  pmi: {
    corpora_frequency?: number;
    cap_corpora_frequency?: number;
    selection_frequency?: number;
    corpora_total?: number;
    selection_total?: number;
    p_selection?: number;
    key_posterior?: number;
    key_prior?: number;
    pmi_norm?: number;
    pmi_cap: number;
    pmi_cap_pure?: number;
  };
  value: number;
}

const props = defineProps({
  entity: {
    type: Object as PropType<MonitioAPI.SearchResponseProperties>,
    required: true,
  },
  granularCorporaFrequency: {
    type: Array as PropType<{ date: string; corporaFrequency: number }[]>,
    required: true,
  },
  labelColWidth: {
    type: Number,
    required: true,
  },
  parentScrollableElement: {
    type: Object as PropType<HTMLElement>,
    required: false,
  },
  corporaCount: {
    type: Number,
    required: true,
  },
  selectionCount: {
    type: Number,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  nRows: {
    type: Number,
    required: true,
  },
});

const { t } = useI18n();
const route = useRoute();
const { api } = useApi();
const router = useRouter();
const filtersStore = useFiltersStore();
const { updateTooltipContent } = useTooltip();

const htmlElement: Ref<HTMLElement | null> = ref(null);
const isOnScreen = ref(false);
const data: Ref<Data[] | null> = ref(null);
const viewId = computed(() => route.params.viewId);
const requestCompleted = ref(false);

//#endregion

//#region IntersectionObserver code
/**
 * IntersectionObserver code
 * It decides if the element is showing on screen or not.
 * If it is we do an API call to get the needed data, if not stay hidden and do nothing
 */

let observerWasInitiated = false;
const handleIntersection = async (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  const info = entries[0];
  isOnScreen.value = info.isIntersecting;

  if (info.isIntersecting) {
    if (!data.value) {
      /** If the user is scrolling to fast, and we dont want to render unecesarily*/
      await new Promise((resolve) => setTimeout(resolve, 800));
      const elBounds = info.target.getBoundingClientRect();

      if (elBounds.top > window.innerHeight || elBounds.bottom < 0) return; // Recheck if the element is still in the viewport
      await getSearchData();
    }

    /**Give a little time for CSS to kick in so we get correct with values for the elements */
    await new Promise((resolve) => setTimeout(resolve, 100));
    drawSVG();
  } else if (!info.isIntersecting) {
    d3.select(`#trending-row__${snakeCase(props.entity.key ?? "")}`)
      .selectAll("*")
      .remove();
  }
};
const observer = new IntersectionObserver(handleIntersection, {
  rootMargin: "80px 0px",
});
//#endregion

//#region d3 Code to draw "chart"
const svgElement: Ref<HTMLElement | null> = ref(null);
const drawSVG = () => {
  if (!data.value || !svgElement.value) return;
  if (typeof data.value == "boolean") return;

  const height = 32;
  const width = svgElement.value.getBoundingClientRect()?.width;

  if (!width) return;
  const xScale = d3
    .scaleBand()
    .domain(data.value.map((x) => x.date))
    .range([0, 100])
    .padding(0);
  const indexes = d3.local();

  d3.select(`#trending-row__${snakeCase(props.entity.key ?? "")}`)
    //.attr("preserveAspectRatio", "xMinYMin meet")
    //.attr("viewBox", `0 0 ${x?.width} ${x?.height}`)
    .selectAll<SVGSVGElement, Data>("rect")
    .data<Data>(data.value, (x) => x.date)
    .enter()
    .append("rect")
    .attr("data-tooltip-clickable", "true")
    .attr("data-tooltip-template", function (data) {
      if (getInitialTooltipContent(data)?.assistant) return "articlesAssistant";
      else return "articlesAssistantLoading";
    })
    .attr("data-tooltip-content", function (data) {
      return JSON.stringify(getInitialTooltipContent(data));
    })
    .attr("data-tooltip-position", "bottom-center")
    .attr("width", `${xScale.bandwidth()}%`)
    .attr("height", height)
    .attr("x", (x) => `${xScale(x.date) as number}%`)
    .attr("y", 0)
    .attr("id", (data) => `${props.entity.key}-${data.date}`)
    .attr("fill", (x) => `hsl(${getColor(data.value, x)})`)
    .attr("pmi", (x) => x.value)
    .each(function (_d, i) {
      indexes.set(this, i);
    })
    .on("mouseenter", function (event, data) {
      const elementId = `${props.entity.key}-${data.date}`;
      hoveringOnElementId = elementId;
      getAssistantExplanation(data, elementId);
    })
    .on("mouseleave", function (_evt, data) {
      const elementId = `${props.entity.key}-${data.date}`;
      if (elementId == hoveringOnElementId) hoveringOnElementId = null;
    })
    .on("click", function (event, data) {
      searchArticles(indexes.get(this) as number);
    });
};

let hoveringOnElementId: string | null = null; // eslint-disable-line
const tooltipContent: Ref<{
  [key: string]: { articles: string; assistant: string | null };
}> = ref({});
const getAssistantExplanation = async (entry: Data, elementId: string) => {
  // do stuff
  if (tooltipContent.value[elementId].assistant) return;

  const contextDate = DateTimeUtils.parseFromISO(entry.date);
  const dateRestriction: MonitioAPI.DateRestriction = {
    isRelative: false,
    start: null,
    end: null,
  };
  switch (filtersStore.granularity) {
    case "hour":
      dateRestriction.start = contextDate?.minus({ hour: 1 });
      dateRestriction.end = contextDate;
      break;
    case "month":
    case "week":
    case "year":
    case "day":
    default:
      dateRestriction.start = contextDate?.startOf(filtersStore.granularity);
      dateRestriction.end = contextDate?.endOf(filtersStore.granularity);
      break;
  }

  /** @type {import("@root/types.api.local").MonitioAPI.ExplainTrendingDTO} */
  const params = {
    viewId: viewId.value,
    dateRestriction: dateRestriction,
    filters: filtersStore.queryObject?.filters,
    entityKey: props.entity.key,
    entityLabel: props.entity.label,
    entitySearchPropertyType: props.entity.type,
    granularity: filtersStore.granularity,
  };

  delay(async () => {
    if (hoveringOnElementId != elementId) return;
    tooltipContent.value[elementId].assistant = "loading";
    try {
      const { data } = await api.search.getTrendingExplanation(params);
      if (!data) throw Error("No value was returned");

      const { response, sources } = data;

      tooltipContent.value[elementId].assistant = response;
      const element = document.getElementById(elementId);

      element?.setAttribute("data-tooltip-template", "articlesAssistant");
      element?.setAttribute(
        "data-tooltip-content",
        JSON.stringify(tooltipContent.value[elementId])
      );
      if (element) updateTooltipContent(element);
    } catch (error) {
      tooltipContent.value[elementId].assistant = null;
    }
  }, 500);
};

const getInitialTooltipContent = (entry: any) => {
  return {
    articles: t("views.trending-entities.articleCount", {
      count: entry?.count,
    }),
    assistant: null, // find assistant quote on assistantExplanation
  };
};
//#endregion

/**
 * This will retrieve the data from the api for this row
 * and make the necessary calculations
 * @returns void
 */
const getSearchData = async () => {
  const resp = await api.search.trendingEntitiesTimeline(
    unref(viewId),
    props.entity.key,
    props.entity.type,
    filtersStore.dateRestriction,
    filtersStore.granularity,
    filtersStore.queryObject?.filters
  );
  if (!resp.data) return;
  requestCompleted.value = true;

  /** This will output an array with the data tha the frontend uses.
   But this only includes the days in wich this entity has values*/
  const formattedData = Object.keys(resp.data).map((key) => {
    const granularSelectionFrequency = resp.data[key];

    const pmi = computePMI2(
      {
        selection_frequency: granularSelectionFrequency,
        corpora_frequency: props.entity.corpora_frequency,
      },
      props.corporaCount,
      props.selectionCount
    );

    return {
      date: key,
      count: granularSelectionFrequency,
      pmi: pmi,
      value: pmi ? pmi.pmi_cap : 0,
    };
  });

  for (const item of formattedData) {
    if (item.pmi.pmi_norm < min) min = item.pmi.pmi_norm;
    if (item.pmi.pmi_norm > max) max = item.pmi.pmi_norm;
  }

  if (min == -Infinity) min = 0;
  else min = min - (max - min) / formattedData.length;

  /** Need to create a new array keeping in mind the scope of the parent
   * Parent could be in the 30day range and the formattedData array can
   * only have 7 items for example*/
  const granularCorporaFrequency = [...props.granularCorporaFrequency];

  data.value = granularCorporaFrequency.map((x) => {
    const match = formattedData.find((item) => item.date == x.date);
    if (match) return match;
    return {
      date: x.date,
      count: 0,
      pmi: {
        pmi_cap: 0,
      },
      value: 0,
    };
  });

  // Setup the data for the tooltips
  for (const item of data.value) {
    tooltipContent.value[`${props.entity.key}-${item.date}`] = {
      articles: t("views.trending-entities.articleCount", {
        count: item?.count,
      }),
      assistant: null, // find assistant quote on assistantExplanation
    };
  }
};

/** Build the necessary query and redirect the user to the articles page */
const searchArticles = (idx: number) => {
  const date = DateTimeUtils.parseFromISO(
    props.granularCorporaFrequency[idx].date
  );

  const dateRestriction: MonitioAPI.DateRestriction = {
    isRelative: false,
  };

  switch (filtersStore.granularity) {
    case "day":
      dateRestriction.start = date?.startOf("day");
      dateRestriction.end = date?.endOf("day");
      break;
    case "week":
      dateRestriction.start = date?.startOf("week");
      dateRestriction.end = date?.endOf("week");
      break;
    case "month":
      dateRestriction.start = date?.startOf("month");
      dateRestriction.end = date?.endOf("month");
      break;
    case "year":
      dateRestriction.start = date?.startOf("year");
      dateRestriction.end = date?.endOf("year");
      break;
  }

  const filters: Monitio.URLQueryObject = {
    filters: [
      {
        facets: [
          {
            value: props.entity.type ?? "",
            query: [
              {
                label: props.entity.label ?? "",
                value: props.entity.key ?? "",
                negated: false,
              },
            ],
          },
        ],
      },
    ],
    dateRestriction,
  };
  navigateWithQueryObject(route, router, filters, "articles", {
    viewId: viewId.value,
  });
};

//let max = 0.4871398751361482;
let max = 0;
let min = 10000000;
const getColor = (entries: Data[] | null, entry: Data) => {
  const trendExponentialDecay = (time: number, decayRate: number) => {
    const tendTo = 0.5;
    return tendTo + (1 - tendTo) * decayRate ** time;
  };
  const diminishingMultiply = (a: number, factor: number, strength = 120) => {
    const str = props.nRows / strength;
    const diference = Math.abs(a - 100);

    const rawAddition = diference * factor;
    const invertAdd = (rawAddition - rawAddition * factor) / str;

    return a + invertAdd;
  };
  /**
   * In this component we lose the context of the other rows, therefore
   * we cant know the max and min of every entry,
   * so atm this is hardcoded, to keep color coherence between rows
   */
  //const max = 1;
  //const min = 0;

  /*   const hue = 219;
  const saturation =
    entry.value == +Infinity
      ? 100
      : entry.value == -Infinity
      ? 0
      : (entry.value * 100).toFixed(2);
  const lightness =
    entry.value == +Infinity
      ? 0
      : entry.value == -Infinity
      ? 100
      : ((1 - entry.value) * 100).toFixed(2); */

  //return `${hue}, ${saturation}%, ${lightness}%`;

  /** Map the value from the max, min range the the size of the gradient array */
  let idx = Math.floor(map(entry.value, min, max, 0, gradient.length - 1));

  if (entry.value == +Infinity) idx = gradient.length - 1;
  if (entry.value == -Infinity) idx = 0;

  if (idx < 0) idx = 0;
  else if (idx >= gradient.length) idx = gradient.length - 1;

  const [hue, saturation, lightness] = gradient[idx];

  const factor = trendExponentialDecay(props.index, 0.95);

  const original = `${hue}, ${saturation}%, ${lightness}%`;

  const computedSaturation = saturation * factor;
  const computedLightness = diminishingMultiply(lightness, factor);
  const manip = `${hue}, ${computedSaturation}%, ${computedLightness}%`;

  //return original;
  return manip;
};

onMounted(() => {
  /** Initialize an intersectionObserver for each row to fetch the data on demand */
  if (!observerWasInitiated && htmlElement.value != null) {
    observer.observe(htmlElement.value);
    observerWasInitiated = true;
  }
});

/**
 * Clear the observers on route leave only.
 * We can't clear on unMount because these components get
 * mounted and unmounted several times in this view
 */
onBeforeRouteLeave(() => {
  observer.disconnect();
});
</script>

<style scoped lang="scss">
svg {
  width: 100%;
}

svg rect {
}

.m-ent-rel {
  width: 100%;
  height: inherit;

  &__row {
    height: inherit;
    display: flex;
    justify-content: stretch;

    span {
      display: flex;
      align-items: center;
    }
  }

  &__loading {
    background: repeating-linear-gradient(
      to right,
      color($white) 0%,
      color($pri-light) 20%,
      color($pri-light) 80%,
      color($white) 100%
    );
    width: 100%;
    height: inherit;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: loading 1.2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  @keyframes loading {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}
</style>

<style lang="scss">
.m-popover__trending {
  position: absolute;
  top: 0;
  left: 0;

  div {
    margin-top: $spacing-2;
    @include flex(flex-start, center, row);
  }
}
</style>
@root/utils/
