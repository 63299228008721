<template>
  <div class="m-header" :class="{ '--fullscreen': fullscreen }">
    <m-logo
      v-if="fullscreen"
      type="ico"
      height="28"
      class="ml-3 m-header__logo"
    />
    <m-header-breadcrumbs v-if="!fullscreen" />
    <div
      v-if="!fullscreen"
      class="mr-3 m-header__right"
      data-tour="m_header_right"
    >
      <div
        v-if="showDate"
        class="m-header--date"
        @mouseenter="openDropdown = 'date'"
      >
        <m-selector
          id="subheader_date"
          :tooltip="showDateTooltip"
          type="date"
          :dateSpecs="dateSpecs"
          :selected="filtersStore.dateRestriction"
          @update:selected="
            (v) => filtersStore.updateDateRestriction(v, route, router)
          "
          size="small"
          variant="simple"
          :class="{
            'm-clickable': !clusterPage,
          }"
          :disabled="clusterPage"
        />
      </div>
      <m-header-filters
        :class="windowWidth < 1366 ? 'ml-2' : 'ml-3'"
        @mouseenter="openDropdown = 'filters'"
      />
      <template v-if="!settings && canAccessAssistant">
        <div
          class="m-header__divider"
          :class="windowWidth < 1366 ? 'mx-2' : 'mx-3'"
        ></div>
        <m-header-assistant
          :closeOthers="openDropdown"
          @mouseenter="openDropdown = 'assistant'"
        />
      </template>
      <div
        v-if="!settings"
        class="m-header__divider"
        :class="windowWidth < 1366 ? 'mx-2' : 'mx-3'"
      ></div>
      <m-header-workspace
        :closeOthers="openDropdown"
        @mouseenter="openDropdown = 'workspace'"
      />
      <div
        class="m-header__divider"
        :class="windowWidth < 1366 ? 'mx-2' : 'mx-3'"
      ></div>
      <!--<m-header-notification />-->
      <m-header-account
        :closeOthers="openDropdown"
        :class="windowWidth < 1366 ? 'ml-2' : 'ml-3'"
        @mouseenter="openDropdown = 'account'"
      />
    </div>

    <div v-else class="mr-3 m-header__right" data-tour="m_header_right">
      <m-button
        id="m_header_exit_fullscreen"
        :label="t('navigation.header.views_exitFullscreen')"
        leadingIcon="collapse-screen"
        type="text"
        variant="terciary"
        size="xsmall"
        class="m-tiles__button--fullscreen"
        @click="exitFullscreen"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import useWindowSize from "@hooks/useWindowSize";

import MLogo from "@components/MLogo.vue";
import MSelector from "@components/MSelector.vue";
import MButton from "@components/MButton.vue";
import MHeaderBreadcrumbs from "@components/navigation/MHeaderBreadcrumbs.vue";
import MHeaderFilters from "@components/navigation/MHeaderFilters.vue";
import MHeaderAssistant from "@components/navigation/MHeaderAssistant.vue";
import MHeaderWorkspace from "@components/navigation/MHeaderWorkspace.vue";
import MHeaderAccount from "@components/navigation/MHeaderAccount.vue";
import MHeaderNotification from "@components/navigation/MHeaderNotification.vue";
import { useViewsStore } from "@root/store/modules/views";
import { useAppStore } from "@root/store/app";
import { useUserStore } from "@root/store/modules/user";
import { useFiltersStore } from "@root/store/modules/filters";

const emit = defineEmits(["action"]);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const appStore = useAppStore();
const userStore = useUserStore();
const viewsStore = useViewsStore();
const filtersStore = useFiltersStore();

const openDropdown = ref(undefined);

const { windowWidth } = useWindowSize();
const navStatus = computed(() => viewsStore.nav);
const fullscreen = computed(() => appStore.fullscreen);

const canAccessAssistant = computed(
  () => userStore.details.featureAccess?.["accessAIAssistent"] ?? false
);

const dateSpecs = ref({ mode: "range", timeframes: true, columns: 2 });

const settings = computed(() => {
  switch (router.currentRoute.value?.name) {
    case "account":
    case "profile":
    case "languages":
    case "teams":
    case "filters":
    case "tags":
    case "entities":
    case "article":
      return true;
    default:
      return false;
  }
});

const showDate = computed(() => {
  const current = router.currentRoute.value;
  return (
    !settings.value &&
    ![
      "home",
      "reports",
      "reports-empty",
      "reportsTemplates",
      "reportBuilder",
      "dashboards",
      "dashboards-empty",
      "dashboardsTemplates",
      "dashboardBuilder",
    ].includes(current.name)
  );
});

const clusterPage = computed(() => {
  return false;
  // eslint-disable-next-line no-unreachable
  return router.currentRoute.value?.name.includes("-cluster");
});

const showDateTooltip = computed(() => {
  const tooltip = {
    content: "",
    position: "bottom-center",
  };
  if (clusterPage.value) {
    tooltip.content = t("navigation.header.filters.date.clusterInactive");
  } else {
    tooltip.content = t("navigation.header.filters.date.select");
  }
  return tooltip;
});

const exitFullscreen = () => {
  appStore.setFullscreen(false);
};
</script>

<style scoped lang="scss">
.p-relative {
  z-index: $z-popover;
}

.m-header {
  width: 100%;
  height: $header-height;
  margin-top: $spacing-2;
  padding: $spacing-2 $spacing-0 $spacing-0 $spacing-3;
  position: relative;
  @include flex(space-between, center, row);

  &:not(.fullscreen) {
    margin-top: $spacing-0;
    padding: $spacing-0;
    z-index: $z-popover;
  }

  &__right {
    @include flex(flex-start, center, row);
  }

  &__divider {
    width: 1px;
    height: $spacing-2;
    @include round-corners($spacing-1);
    background-color: color($sec-300);
  }

  &__logo {
    animation: logo 0.5s ease-in-out;
  }

  @keyframes logo {
    0% {
      transform: translate(7px, 9px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}
</style>
