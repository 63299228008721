<template>
  <div :id="`m_module_${id}`" data-tour="tour_report_m_module" @click="select">
    <div v-if="editor" class="m-report__actions">
      <m-icon
        id="m_report_edit"
        :tooltip="t('general.buttons.edit')"
        icon="edit"
        variant="secondary"
        size="small"
        hover="weight"
        @click="select"
      />
      <m-icon
        id="m_report_remove"
        :tooltip="t('general.buttons.remove')"
        icon="delete"
        variant="secondary"
        size="small"
        hover="weight"
        @click="remove"
      />
    </div>
    <div class="m-report__heading">
      <h2 class="m-report__dashboard type--small">
        {{ dashboard.name }}
      </h2>
      <h6 class="m-report__dashboard type--small">
        {{ dashboard.description }}
      </h6>
      <h6 class="type--small">
        {{ view?.name }}
        <span class="mx-3">|</span>
        {{ date }}
      </h6>
    </div>

    <m-dashboard
      :key="key"
      :type="editor ? 'report-editor' : 'report'"
      :columns="1"
      :reportViewId="viewId"
      :dashboard="dashboard"
      :date-restriction-override="computedDateRestriction"
      @chart-data-changed="elementChartDataChanged"
      @click="select"
    />
  </div>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import MDashboard from "@components/dashboard/MDashboard.vue";
import MIcon from "@components/MIcon.vue";
import useDashboards from "@hooks/useDashboards";
import structuredClone from "@utils/structuredClone";
import { useViewsStore } from "@root/store/modules/views";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import useDateFormat from "@hooks/useDateFormat";
import { useFiltersStore } from "@root/store/modules/filters";

const { deepSearchChart } = useDashboards();
const props = defineProps({
  id: { type: [String, Number] },
  preferredLanguage: String,
  previewViewId: String,
  settings: {
    type: Object,
  },
});

const emit = defineEmits([
  "select-element",
  "remove-element",
  "update-module-data",
]);

const { t } = useI18n();
const dashboardsStore = useDashboardsStore();
const viewsStore = useViewsStore();
const filtersStore = useFiltersStore();
const route = useRoute();
const router = useRouter();
const editor = computed(() => route.name.toLowerCase().includes("builder"));
const { dateFormat } = useDateFormat();

const viewId = computed(
  () => props.settings?.customSettings?.viewId ?? props.previewViewId
);
const view = computed(() => viewsStore?.getViewById(viewId.value));

const computedDateRestriction = computed(
  () =>
    props.settings.customSettings?.dateRestriction ??
    filtersStore.dateRestriction
);

const select = () => {
  emit("select-element", props.id);
};

const remove = () => {
  emit("remove-element", props.id);
};

const dashboard = ref(
  structuredClone(dashboardsStore.getById(props.settings?.dashboard?.id)) ??
    structuredClone(props.settings?.dashboard)
);

const key = ref(0);
watch(
  () => props.settings?.dashboard,
  (dash, oldDash) => {
    if (dash.id == oldDash.id) return;
    dashboard.value = dash;
    key.value++;
  }
);

watch(
  () => props.settings.viewId,
  (viewId, o) => {
    if (viewId == o) return;
    const clone = structuredClone(dashboard.value);
    clone.viewId = viewId;
    dashboard.value = clone;
  }
);

const dashboardData = ref(null);
const elementChartDataChanged = (data, elementId, parentId) => {
  const indexesPath = deepSearchChart(dashboard.value?.elements, elementId);
  let element;
  indexesPath.forEach((index, i) => {
    if (i == 0) element = dashboard.value?.elements?.[index];
    else element = element.children?.[index];
  });
  element.data = data;

  // The follwing code is needed to maintain the same behaviour betweeen report components where all the data
  // for the module is in the parent component, ready do be sent with the snapshot
  if (!dashboardData.value) dashboardData.value = {};
  dashboardData.value[elementId] = data;
  emit("update-module-data", dashboardData.value);
  //dashboardsStore.update(dashboard.value);
};

const date = computed(() => {
  if (computedDateRestriction.value.isRelative) {
    return t(`general.time.${computedDateRestriction.value.timeFrame}`);
  } else {
    return `${dateFormat(computedDateRestriction.value.start)} - ${dateFormat(
      computedDateRestriction.value.end
    )}`;
  }
});
</script>

<style scoped lang="scss">
:deep(.m-dashboard) {
  width: 100%;
}
.m-report {
  &__heading {
    margin-bottom: $spacing-2;
    align-self: flex-start;
    text-align: left;
  }

  &__dashboard {
    text-align: left;
    align-self: flex-start;
  }

  :deep(.vue-grid-layout) {
    width: calc(100% + $spacing-4);
    transform: translate(-($spacing-2));
  }
}
</style>
