<template>
  <div
    :id="`m_toolbox_${id}`"
    data-tour="tour_m_toolbox_element"
    class="m-toolbox__module"
  >
    <h6 class="m-toolbox__title">
      {{ t("views.reports.toolbox.newsCluster") }}
    </h6>
    <div class="m-toolbox__tool">
      <m-input
        id="m_toolbox_number_clusters"
        :label="$t('views.reports.toolbox.number_clusters')"
        v-model="numberClusters"
        type="number"
        size="small"
      />
    </div>
    <div class="mb-3 m-toolbox__tool">
      <m-checkbox
        id="m_toolbox_element_custom_settings"
        v-model="elementCustomSettings"
        :label="t('views.dashboards.toolbox.elementCustomSettings')"
        size="small"
      />
    </div>
    <div v-if="elementCustomSettings" class="m-toolbox__tool">
      <m-selector
        id="m_toolbox_articles"
        :outsideLabel="$t('views.reports.toolbox.view')"
        v-model:selected="viewId"
        :options="viewsList"
        size="small"
      />
    </div>
    <div v-if="elementCustomSettings" class="mb-6 m-toolbox__tool">
      <m-selector
        v-if="settings.customSettings?.dateRestriction?.isRelative"
        id="m_toolbox_since"
        :outsideLabel="$t('views.reports.toolbox.since')"
        :selected="restriction.timeFrame"
        @update:selected="
          (val) =>
            (restriction = {
              isRelative: true,
              timeFrame: val.value,
            })
        "
        :options="restrictionOpts"
        size="small"
      />
      <m-simple-date-picker
        v-else
        v-model="restriction"
        size="small"
        orientation="vertical"
        hideButton
      />
      <m-button
        id="switch"
        type="text"
        variant="primary"
        size="xsmall"
        :label="
          restriction?.isRelative
            ? t('views.dashboards.toolbox.switchToCustomDate')
            : t('views.dashboards.toolbox.switchToDatePresets')
        "
        class="mt-1"
        @click="
          () =>
            (restriction = {
              ...restriction,
              isRelative: !restriction.isRelative,
            })
        "
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import TimeFrame from "@utils/enums/timeFrames";
import MSimpleDatePicker from "@components/MSimpleDatePicker.vue";
import MSelector from "@components/MSelector.vue";
import MInput from "@components/MInput.vue";
import MButton from "@components/MButton.vue";
import structuredClone from "@utils/structuredClone";
import MCheckbox from "@components/MCheckbox.vue";
import { DateTime } from "luxon";
import DateTimeUtils from "@utils/dateTime";
import { useViewsStore } from "@root/store/modules/views";
import { useReportsStore } from "@root/store/modules/reports";
import { useUserStore } from "@root/store/modules/user";
import { useFiltersStore } from "@root/store/modules/filters";

const props = defineProps({
  id: { type: [String, Number] },
  previewViewId: String,
  settings: {
    type: Object,
    default: () => ({
      numberOfClusters: 2,
      numberOfNewsPerCluster: 8,
      customSettings: {
        viewId: undefined,
        dateRestriction: {
          isRelative: true,
          timeFrame: "sinceSnapshot",
        },
      },
    }),
  },
});

const emit = defineEmits(["update:settings"]);

const { t } = useI18n();
const viewsStore = useViewsStore();
const reportsStore = useReportsStore();
const userStore = useUserStore();
const filtersStore = useFiltersStore();
const route = useRoute();
const router = useRouter();
const reportId = computed(() => route.params.reportId);

const viewId = computed({
  get() {
    return props.settings.customSettings?.viewId ?? props.previewViewId;
  },
  set(val) {
    if (!val) return;
    if (val == props.settings?.customSettings?.viewId) return;
    const clone = structuredClone(props.settings);
    clone.customSettings ??= {};
    clone.customSettings.viewId = val.value;
    emit("update:settings", clone, props.id);
  },
});

const viewsList = computed(() => {
  return viewsStore.getAllViews.map((m) => ({ value: m.id, label: m.name }));
});

const restriction = computed({
  get() {
    if (!props.settings?.customSettings.dateRestriction)
      return filtersStore.dateRestriction;
    return props.settings.customSettings.dateRestriction;
  },
  set(val) {
    if (val.isRelative) {
      if (!val.timeFrame) val.timeFrame = "last7days";
    } else {
      if (val.timeFrame) {
        const report = reportsStore.getById(reportId.value);
        val = {
          ...val,
          ...DateTimeUtils.getTimeFrame(
            val.timeFrame,
            userStore.timeZone,
            report
          ),
        };
      }
    }

    const clone = structuredClone(props.settings);
    clone.customSettings.dateRestriction = val;
    emit("update:settings", clone, props.id);
  },
});

const restrictionOpts = computed(() => {
  const timeframes = TimeFrame.ToArray().map((m) => ({
    label: t(`general.time.${m.value}`),
    isRelative: true,
    ...m,
  }));
  timeframes.shift(); // removes "All"
  return timeframes;
});

const defaultTimeFrame = {
  timeFrame: "today",
  isRelative: false,
  end: DateTime.now().endOf("day"),
  start: DateTime.now().minus({ weeks: 1 }).startOf("day"),
};

const numberClusters = computed({
  get() {
    return props.settings?.numberOfClusters;
  },
  set(val) {
    if (!val) return;
    if (val == props.settings?.numberOfClusters) return;
    const clone = structuredClone(props.settings);
    clone.numberOfClusters = val;
    emit("update:settings", clone, props.id);
  },
});

const elementCustomSettings = computed({
  get() {
    return props.settings?.customSettings || false;
  },
  set(val) {
    const s = { ...props.settings };
    if (!val) {
      s.customSettings = null;
    } else {
      s.customSettings ??= {
        dateRestriction: {
          ...filtersStore.dateRestriction,
          reportId: reportId.value,
        },
      };
      s.customSettings.viewId ??= viewId.value;
    }
    emit("update:settings", s, props.id);
  },
});
</script>
