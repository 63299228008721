import { DateTime, Duration } from "luxon";
import TimeFrame from "@utils/enums/timeFrames";
/**
 * File that holds all the utilities to handle dates in monitio project.
 * By convention ALL the dates should be a luxon DateTime object always.
 * When parsing dates from string be it on the frontend, or dates from the backend
 * we parse it a function from this class called TimeDateUtils.parseFromISO().
 *
 * All the dates in their string form should ALWAYS be parsed from, and be formatted as the following:
 * *YYYY-MM-DD[T]HHmmss[.]SSSSSS* 2022-02-06T000000
 * For this always use this TimeDateUtils utility
 *
 * To parse use TimeDateUtils.parseFromISO(dateString)
 * To format dates to strings use TimeDateUtils.toISOString(date)
 */

export default class DateTimeUtils {
  constructor() {
    return undefined;
  }

  /**
   * Function tha handles the parsing from the default formatted string in Monitio
   * @param {string|import("luxon").DateTime} date
   * @param {import("luxon").DateTimeOptions} dateTimeOptions
   * @returns {DateTime|undefined};
   */
  static parseFromISO(date, dateTimeOptions = null) {
    if (date instanceof DateTime) return date;
    if (!date) return undefined;
    if (typeof date !== "string") return undefined;
    /**
     * At the moment this seems redundant because luxon by default can parse
     * our ISO strings. But if things change we can code our custom parsing here if needed
     */
    if (!dateTimeOptions) return DateTime.fromISO(date);
    else return DateTime.fromISO(date, dateTimeOptions);
  }

  /**
   * Function that handles the parsing from of a C# TimeSpan ( https://learn.microsoft.com/en-us/dotnet/api/system.timespan?view=net-8.0 ) string to Duration and DateTime object corresponding to current or specified date minus the parsed duration
   * @param {string} timespanString - C# TimeSpan string in format D.H:M:S.TICKS(Milliseconds * 10000)
   * @param {DateTime} dateBase - Current or specific date to subtract the duration
   * @param {import("luxon").DurationOptions} durationOptions
   * @return {{date: DateTime, duration:  Duration}}
   */
  static parseFromTimeSpan(
    timeSpanString,
    dateBase = DateTime.now(),
    durationOptions = null
  ) {
    const timeSpanStringSplit = timeSpanString.split(":");
    let days, hours, minutes, seconds, millis;
    if (timeSpanStringSplit[0].indexOf(".") != -1) {
      const dh = timeSpanStringSplit[0].split(".");
      days = dh[0] != 0 ? dh[0] : null;
      hours = dh[1] != 0 ? dh[1] : null;
    }
    if (timeSpanStringSplit[1] != 0) minutes = timeSpanStringSplit[1];
    if (timeSpanStringSplit[2].indexOf(".") != -1) {
      const sms = timeSpanStringSplit[2].split(".");
      seconds = sms[0] != 0 ? sms[0] : null;
      millis = sms[1] != 0 ? sms[1] / 10000 : null;
    }
    const duration = Duration.fromObject(
      {
        days,
        hours,
        minutes,
        seconds,
        milliseconds: millis,
      },
      durationOptions
    );
    const date = new Date(dateBase - duration.as("milliseconds"));
    return { date, duration };
  }

  /**
   * Function tha handles the formatting DateTime objects into properly formatted string in Monitio
   * @param {DateTime} date
   * @returns {string};
   */
  static toISOString(date) {
    /** To a standard date in ISO format YYYY-MM-DD */
    const isoDate = date.toISODate({
      includeOffset: false,
    });

    //** To a standard time in ISO format (basic mode, prefixed) [T]HHMMSS.mmmZ */
    let isoTime = date.toISOTime({
      includePrefix: true,
      includeOffset: false,
      format: "basic",
    });
    /** Because the default ISO time includes miliseconds we remove them */
    isoTime = isoTime.split(".")[0];
    return isoDate + isoTime;
  }

  /**
   * Function that returns a date restriction with a specified format. All times must be in UTC
   * @param {DateTime} from The from date value
   * @param {DateTime} to The to date value
   * @param {*} timezone
   * @param {bool} getRaw If this is enabled the return value is ex: "DOCDATE$VF OP_BI "
   * @returns {string} Ex: "DOCDATE$VF OP_BI "
   */
  static createDateRestriction = (from, to, getRaw = false) => {
    if (!from?.isLuxonDateTime)
      throw new TypeError(
        "The 'from' argument must be a valid (luxon) DateTime object."
      );
    if (!to?.isLuxonDateTime)
      throw new TypeError(
        "The 'to' argument must be a valid (luxon) DateTime object."
      );

    const fromStr = this.toISOString(from);
    const toStr = this.toISOString(to);
    const restriction = `${fromStr}_${toStr}`;

    if (getRaw) return restriction;
    return "DOCDATE$VF OP_BI " + restriction;
  };

  /**
   * Function that translates the relative date @param {string} crg to
   * a absolute value calculated at runtime of the function.
   * @param {import("@root/types.api.local").MonitioAPI.TimeFrame} relativeTimeFrame
   * @param {import("@root/types.api.local").MonitioAPI.DocumentDateRangeDTO?} documentDateRange
   * @param {import("@root/types.api.local").MonitioAPI.ReportDTO | import("@root/types").Monitio.Report | null} report
   * @returns {{start: DateTime, end: DateTime}}
   */
  static getTimeFrame = (
    relativeTimeFrame,
    timeZone,
    documentDateRange = null,
    report = null
  ) => {
    if (!(relativeTimeFrame instanceof TimeFrame))
      relativeTimeFrame = TimeFrame.TryParseFromString(relativeTimeFrame);
    if (!relativeTimeFrame)
      throw new TypeError(
        "The first argument of the functions was not a property of TimeFrame class, and it failed to parse has one. Refer to utils/enums/timeFrames.js"
      );

    const now = DateTime.utc().setZone(timeZone);

    switch (relativeTimeFrame.value) {
      case TimeFrame.Latest.value:
        return {
          end: documentDateRange.newest,
          start: documentDateRange.newest.startOf("day"),
        };
      case TimeFrame.LastHour.value:
        return {
          start: now.minus({ hours: 1 }),
          end: now,
        };
      case TimeFrame.Today.value:
        return {
          start: now.startOf("day"),
          end: now.endOf("day"),
        };
      case TimeFrame.Last24Hours.value:
        return {
          start: now.minus({ hours: 24 }),
          end: now,
        };
      case TimeFrame.Yesterday.value:
        return {
          start: now.minus({ days: 1 }).startOf("day"),
          end: now.minus({ days: 1 }).endOf("day"),
        };
      case TimeFrame.Last3days.value:
        return {
          start: now.minus({ days: 2 }).startOf("day"),
          end: now.endOf("day"),
        };
      case TimeFrame.ThisWeek.value:
        return {
          start: now.startOf("week"),
          end: now.endOf("day"),
        };
      case TimeFrame.Last7Days.value:
        return {
          start: now.minus({ days: 6 }).startOf("day"),
          end: now.endOf("day"),
        };
      case TimeFrame.LastWeek.value:
        return {
          start: now.minus({ weeks: 1 }).startOf("week"),
          end: now.minus({ weeks: 1 }).endOf("week"),
        };
      case TimeFrame.ThisMonth.value:
        return {
          start: now.startOf("month"),
          end: now.endOf("day"),
        };

      case TimeFrame.Last30Days.value:
        return {
          start: now.minus({ days: 30 }).startOf("day"),
          end: now.endOf("day"),
        };
      case TimeFrame.LastMonth.value:
        return {
          start: now.minus({ months: 1 }).startOf("month"),
          end: now.minus({ months: 1 }).endOf("month"),
        };
      case TimeFrame.Last6Months.value:
        return {
          start: now.minus({ months: 6 }),
          end: now.endOf("day"),
        };
      case TimeFrame.Last12Months.value:
        return {
          start: now.minus({ months: 12 }),
          end: now,
        };

      case TimeFrame.ThisYear.value:
        return {
          start: now.startOf("year"),
          end: now,
        };
      case TimeFrame.SinceSnapshot.value: {
        //This only happens  when viewing the dashboard in reports
        // should this be here?
        const reportId = report?.id;
        if (!reportId)
          throw new Error(
            "getTimeFrame function was called off context. Are you in a report page?"
          );
        if (report && report.snapshots && report.snapshots.length) {
          return {
            start: DateTime.fromISO(report.snapshots[0].created),
            end: now,
          };
        } else {
          return {
            start: now.minus({ days: 7 }).startOf("day"),
            end: now,
          };
        }
      }
      case TimeFrame.All.value:
        if (documentDateRange?.oldest) {
          return {
            start: documentDateRange.oldest.startOf("day"),
            end: now,
          };
        } else {
          console.log(
            console.warn(
              "Calculating AllTime without knowing the document date range of the workspace! Defaulting to 2 years..."
            )
          );
          return {
            start: now.minus({ years: 2 }).startOf("day"),
            end: now,
          };
        }
    }
  };

  static checkIfDateIsInRange = (range, newDate, timeZone) => {
    let daterestriction = range;
    if (daterestriction.crg)
      daterestriction = this.getTimeFrame(daterestriction.crg, timeZone);

    const start = DateTime(newDate, "DD/MM/YYYY").startOf("day").valueOf();
    const end = DateTime(newDate, "DD/MM/YYYY").endOf("day").valueOf();

    daterestriction.start = daterestriction.start.valueOf();
    daterestriction.end = daterestriction.end.valueOf();
    if (start >= daterestriction.start && end <= daterestriction.end)
      return {
        start: start,
        end: end,
      };

    return null;
  };
}

const granularityRestrictions = {
  Day: 0,
  Week: 7,
  Month: 30,
};
